import {useNavigate, useParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../shared/components/status-card/StatusCardComponent";
import CardComponent from "../../../shared/components/card/CardComponent";
// import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc,} from "../../../constants";
import {useDispatch} from "react-redux";
import {setCurrentNavParams} from "../../../store/actions/navigation.action";
import "./AgencyDetailsComponent.scss";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import DataLabelValueComponent from "../../../shared/components/data-label-value/DataLabelValueComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../shared/components/tabs/TabsComponent";
import EditAgencyBasicDetailsComponent from "../edit-agency/EditAgencyDetails";
import EditHcpRateOfAgencyComponent from "./edit-agency-details/hcp-rate-edit/EditHcpRateOfAgency";
import EditAgencyAdditionalRateComponent
    from "./edit-agency-details/additional-rate-edit/EditAgencyAdditionalRateComponent";
import EditAgencyAdminDetailsComponent from "./edit-agency-details/edit-admin-details/EditAdminDetailsComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import DetailsHeaderComponent from "../../../shared/components/details-header-component/DetailsHeaderComponent";
import {AGENCY_LIST} from "../../../constants/RoutesConfig";
import EditPreferenceComponent from "./edit-agency-details/preference-edit/EditPreferenceComponent";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import ErrorComponent from "../../../shared/components/error/ErrorComponent";
import _ from "lodash";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import * as Yup from "yup";
import FormikDatePickerComponent
    from "../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import FilePickerComponent from "../../../shared/components/file-picker/FilePickerComponent";

const contractValidationSchema = Yup.object({
        contract: Yup.mixed().required("Contract File is required"),
        contract_expiry_date: Yup.mixed().required("Contract Date is required"),
    }
);

interface contractFormInitialValuesFormSchema {
    contract: any;
    contract_expiry_date: any;
}

const contractFormInitialValues: contractFormInitialValuesFormSchema = {
    contract: "",
    contract_expiry_date: "",
};

const AgencyDetailsScreen = () => {
    const {agencyId} = useParams();
    // const location = useLocation();
    // const path = location.pathname;
    const [agencyDetails, setAgencyDetails] = useState<any>(null);
    const [isAgencyDetailsLoaded, setIsAgencyDetailsLoaded] = useState<boolean>(false);
    const [isAgencyDetailsLoading, setIsAgencyDetailsLoading] = useState<boolean>(false);
    const [isAgencyDetailsLoadingFailed, setIsAgencyDetailsLoadingFailed] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isEditPreferenceOpen, setIsEditPreferenceOpen] = useState<any>(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<any>(false);
    const [isAgencyHcpRateEditDrawerOpen, setIsAgencyEditDrawerOpen] = useState<any>(false);
    const [isAgencyAdditionalRateEditDrawerOpen, setIsAgencyAdditionalEditDrawerOpen] = useState<any>(false);
    const [activeTab, setActiveTab] = useState<"basic-details" | "agency-hcp-rate" | "agency-additional-rate" | "agency-preference">("basic-details");
    const [isHcpRatesAgencyDetailsLoading, setIsHcpRatesAgencyDetailsLoading] = useState<boolean>(false);
    const [isHcpRatesAgencyDetailsLoaded, setIsHcpRatesAgencyDetailsLoaded] = useState<boolean>(false);
    const [HcpRatesAgencyDetails, setHcpRatesAgencyDetails] = useState<any>(null);
    const [isEditAdminDrawerOpen, setIsEditAdminDrawerOpen] = useState<any>(false);
    const [isEditContractOpen, setIsEditContarctOpen] = useState<any>(false);
    const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
    const [initialValuesContract] = useState<any>(contractFormInitialValues);
    const onFileUploadRef = React.useRef<any>(null);
    const [isUploadContractLoading, setIsUploadContractLoading] = useState(false);

    const getSelectedAgencyHcpRates = useCallback(() => {
        setIsHcpRatesAgencyDetailsLoading(true);
        const payload = {};
        CommonService._agency.AgencyDetailsHCPRatesAPICall(agencyId, payload)
            .then((response: IAPIResponseType<any>) => {
                console.log(response.data);
                setIsHcpRatesAgencyDetailsLoaded(true);
                // setSelectedAgencyDetails(response?.data);
                setHcpRatesAgencyDetails(response?.data);
            }).catch((error: any) => {
            setIsHcpRatesAgencyDetailsLoaded(false);
            setIsHcpRatesAgencyDetailsLoading(true);
        }).finally(() => {
            setIsHcpRatesAgencyDetailsLoading(false);
        });

    }, [agencyId]);

    useEffect(() => {
        getSelectedAgencyHcpRates();
    }, [getSelectedAgencyHcpRates]);

    const openEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(true);
    }, []);

    const openAgencyHcpRateEditDrawer = useCallback(() => {
        setIsAgencyEditDrawerOpen(true);
    }, []);

    const openAgencyAdditionalRateEditDrawer = useCallback(() => {
        setIsAgencyAdditionalEditDrawerOpen(true);
    }, []);

    const closeEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(false);
    }, []);

    const closeAgencyHcpRateEditDrawer = useCallback(() => {
        setIsAgencyEditDrawerOpen(false);
    }, []);

    const closeAgencyAdditionalRateEditDrawer = useCallback(() => {
        setIsAgencyAdditionalEditDrawerOpen(false);
    }, []);

    const getAgencyDetails = useCallback(() => {
        setIsAgencyDetailsLoading(true);
        const payload = {};
        if (agencyId) {
            CommonService._agency.AgencyDetailsAPICall(agencyId, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setAgencyDetails(response.data);
                    setIsAgencyDetailsLoaded(true);
                    setIsAgencyDetailsLoadingFailed(false);
                }).catch((error: any) => {
                setIsAgencyDetailsLoaded(false);
                setIsAgencyDetailsLoadingFailed(true);
            }).finally(() => {
                setIsAgencyDetailsLoading(false);
            });
        }
    }, [agencyId]);


    useEffect((): void => {
        getAgencyDetails();
    }, [getAgencyDetails]);


    useEffect(() => {
        dispatch(setCurrentNavParams('Agency Details', null, () => {
            navigate(CommonService._routeConfig.AgencyRoute());
        }));
    }, [dispatch, navigate]);


    const closeAdminEditDrawer = useCallback(() => {
        setIsEditAdminDrawerOpen(false);
    }, []);

    const openAdminEditDrawer = useCallback(() => {
        setIsEditAdminDrawerOpen(true);
    }, []);

    const onAgencyActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.admin_details?.is_active ? 'Deactivate' : 'Activate'}  Agency`,
                image: item?.is_active ? ImageConfig.AgencyDeactivateIcon : ImageConfig.AgencyActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.admin_details?.is_active ? 'deactivate' : 'activate'}  the
    Agency?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: item?.is_active,
                yes: {
                    color: "error",
                    text: `Yes, ${item?.admin_details?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._agency.ActivateDeactivateAgencyFromList(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    getAgencyDetails();
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, [getAgencyDetails]);

    const downloadHcpDocument = useCallback((hcpDocumentFileKey: string) => {
        const payload: any = {};
        payload.file_key = hcpDocumentFileKey;
        CommonService._meta.DownloadDocumentAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                const link = document.createElement('a');
                link?.setAttribute('href', response?.data?.data);
                document.body.appendChild(link);
                link.click();
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error || "Failed To download Document", "error");
        });
    }, []);

    const openEditPreferenceDrawer = useCallback(() => {
        setIsEditPreferenceOpen(true);
    }, []);

    const closeEditPreferenceDrawer = useCallback(() => {
        setIsEditPreferenceOpen(false);
    }, []);

    const openContractEditDrawer = useCallback(() => {
        setIsEditContarctOpen(true);
    }, []);

    const closeContractEditDrawer = useCallback(() => {
        setIsEditContarctOpen(false);
    }, []);

    const deleteHcpDocument = useCallback((hcpDocumentFileKey: string, fileType?: string) => {
        const payload: any = {};
        payload.file_type = "agency_contract";
        payload.file_key = hcpDocumentFileKey;
        CommonService._meta.DeleteDocumentAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getSelectedAgencyHcpRates();
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error || "Failed To delete Document", "error");

        });
    }, [getSelectedAgencyHcpRates]);

    const onHandleDeleteDocument = useCallback((document: string, key?: string) => {
        console.log({document, key});
        CommonService.onConfirm(
            {
                confirmationTitle: `Delete ${document}`,
                image: ImageConfig.DeleteIcon,
                confirmationDescription: `Are you sure you want to delete the
    ${document}?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: true,
                yes: {
                    color: "error",
                    text: "Yes, Delete",
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            if (key) {
                deleteHcpDocument(key);
            } else {
                deleteHcpDocument(HcpRatesAgencyDetails?.contract?.key, "contract");
            }
        });
    }, [HcpRatesAgencyDetails, deleteHcpDocument]);

    const onFileUpload = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        setIsUploadContractLoading(true);
        const formData = CommonService.getFormDataFromJSON(values);
        formData.append("contract_expiry_date", CommonService.convertDateFormat(values?.contract_expiry_date, "YYYY-MM-DD"));
        CommonService._agency.AgencyFacilityContractUploadAPICall(agencyId, formData)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getSelectedAgencyHcpRates();
                closeContractEditDrawer();
                setIsUploadContractLoading(false);
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setIsUploadContractLoading(false);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [agencyId, closeContractEditDrawer, getSelectedAgencyHcpRates]);

    return (
        <>
            <div className={'agency-details-component-screen'}>

                <DrawerComponent isOpen={isEditDrawerOpen} direction={"right"} onClose={closeEditDrawer}>
                    <EditAgencyBasicDetailsComponent AdminDetails={agencyDetails} closeEditDrawer={closeEditDrawer}
                                                     getAgencyDetails={getAgencyDetails}/>
                </DrawerComponent>
                <DrawerComponent isOpen={isEditPreferenceOpen} direction={"right"} onClose={closeEditPreferenceDrawer}>
                    <EditPreferenceComponent HcpRatesAgencyDetails={HcpRatesAgencyDetails}
                                             closeEditDrawer={closeEditPreferenceDrawer}
                                             getAgencyDetails={getSelectedAgencyHcpRates}
                                             agencyId={agencyId}
                    />
                </DrawerComponent>
                <DrawerComponent isOpen={isEditAdminDrawerOpen} direction={"right"} onClose={closeAdminEditDrawer}>
                    <EditAgencyAdminDetailsComponent facilityDetails={agencyDetails}
                                                     closeEditDrawer={closeAdminEditDrawer}
                                                     getAgencyDetails={getAgencyDetails}/>
                </DrawerComponent>
                <DrawerComponent isOpen={isAgencyHcpRateEditDrawerOpen} direction={"right"}
                                 onClose={closeAgencyHcpRateEditDrawer}>
                    <EditHcpRateOfAgencyComponent selectedAgencyDetails={HcpRatesAgencyDetails} agencyId={agencyId}
                                                  getAgencyDetails={getSelectedAgencyHcpRates}
                                                  closeAgencyHcpRateEditDrawer={closeAgencyHcpRateEditDrawer}/>
                </DrawerComponent>
                <DrawerComponent isOpen={isAgencyAdditionalRateEditDrawerOpen} direction={"right"}
                                 onClose={closeAgencyAdditionalRateEditDrawer}>
                    <EditAgencyAdditionalRateComponent selectedAgencyDetails={HcpRatesAgencyDetails} agencyId={agencyId}
                                                       getAgencyDetails={getSelectedAgencyHcpRates}
                                                       closeAgencyAdditionalRateEditDrawer={closeAgencyAdditionalRateEditDrawer}/>
                </DrawerComponent>
                <DrawerComponent
                    isOpen={isEditContractOpen}
                    onClose={closeContractEditDrawer}
                    className={"edit-drawer"}>
                    <>
                        <div className={'d-flex justify-content-space-between pdd-top-15 pdd-bottom-15 pdd-right-25 pdd-left-25'}>
                            <div className={'form-header-text'}>Edit Contract Details</div>
                            <div className={'icon-button'} onClick={closeContractEditDrawer}><ImageConfig.CrossIcon/>
                            </div>
                        </div>
                        <HorizontalLineComponent/>
                        <div className="edit-contract-wrapper pdd-10">
                            <div className="edit-content">
                                <Formik
                                    validationSchema={contractValidationSchema}
                                    initialValues={initialValuesContract}
                                    validateOnChange={false}
                                    validateOnBlur={true}
                                    enableReinitialize={true}
                                    validateOnMount={true}
                                    onSubmit={onFileUpload}
                                    innerRef={onFileUploadRef}
                                >
                                    {({
                                          values,
                                          errors,
                                          validateForm,
                                          touched,
                                          isValid,
                                          setFieldValue,
                                          isSubmitting
                                      }) => {
                                        // eslint-disable-next-line react-hooks/rules-of-hooks
                                        useEffect(() => {
                                            validateForm();
                                            if (values.contract) {
                                                setIsFileSelected(true);
                                            } else {
                                                setIsFileSelected(false);
                                            }
                                        }, [validateForm, values]);
                                        return (
                                            <Form className="t-form" noValidate={true}>
                                                <div
                                                    className="add-component-content">
                                                    {
                                                        (!values.contract) && <>
                                                            <FilePickerComponent maxFileCount={1}
                                                                                 height={'470px'}
                                                                                 id={"sc_upload_btn"}
                                                                                 onFilesDrop={(acceptedFiles:any, rejectedFiles:any) => {
                                                                                     if (acceptedFiles && acceptedFiles.length > 0) {
                                                                                         const file = acceptedFiles[0];
                                                                                         setFieldValue('contract', file);
                                                                                     }
                                                                                 }}
                                                                                 acceptedFileTypes={["pdf"]}
                                                                              //   uploadDescription={"(upload only one file)"}
                                                                              //   acceptedFilesText={"Only .pdf files are supported"}
                                                            />
                                                            {
                                                                (_.get(touched, "contract") && !!(_.get(errors, "contract"))) &&
                                                                <ErrorComponent
                                                                    errorText={(_.get(errors, "contract"))}/>
                                                            }
                                                        </>
                                                    }
                                                    <>
                                                        {
                                                            (values.contract) && <>
                                                                <div className="file-preview-wrapper mrg-bottom-20 mrg-top-15">
                                                                    <div className="file-name">
                                                                        {values.contract.name}
                                                                    </div>
                                                                    <div className="action-button">
                                                                        <div
                                                                            className={'center-element mrg-left-25 contract-action-text icon-button delete-button'}
                                                                            onClick={() => {
                                                                                setFieldValue('contract', '');
                                                                            }}
                                                                        >
                                                                            <ImageConfig.DeleteOutlineIcon/><span
                                                                            className={'mrg-left-10'}>Delete</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <HorizontalLineComponent/>
                                                                <div className={'d-flex mrg-top-20'}>
                                                                    <div className={'flex-1'}>
                                                                        <LabelComponent title={'Contract Expiry Date'}/>
                                                                        <Field name={'contract_expiry_date'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikDatePickerComponent
                                                                                        // minDate={new Date()}
                                                                                        placeholder={'Select Date'}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                            <div className="action-button contract-save-button mrg-top-100">
                                {
                                    isFileSelected && (
                                        <>
                                            {/* eslint-disable-next-line react/jsx-no-undef */}
                                            <ButtonComponent
                                                isLoading={isUploadContractLoading}
                                                className={"save-btn"}
                                                color={'download-button'}
                                                onClick={() => {
                                                    if (onFileUploadRef.current) {
                                                        onFileUploadRef.current.submitForm();
                                                    }
                                                }}
                                            >
                                                Save Details
                                            </ButtonComponent>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </>
                </DrawerComponent>
                {
                    isAgencyDetailsLoading && <div className="h-v-center">
                        <LoaderComponent type={'spinner'}/>
                    </div>
                }
                {
                    isAgencyDetailsLoadingFailed &&
                    <div className="h-v-center">
                        <StatusCardComponent title={'Failed to Load'}/>
                    </div>
                }
                {
                    isAgencyDetailsLoaded && <>
                        <DetailsHeaderComponent navigate={navigate} details={agencyDetails} backAddress={AGENCY_LIST}
                                                detailsModule={'Agency'}/>
                        <TabsWrapperComponent className={'mrg-top-25'}>
                            <div className={'d-flex justify-content-space-between'}>
                            <TabsComponent
                                value={activeTab}
                                allowScrollButtonsMobile={false}
                                onUpdate={(e: any, v: any) => {
                                    setActiveTab(v);
                                }}
                            >
                                <TabComponent label="Basic Details" value={"basic-details"}/>
                                <TabComponent label="Agency Staff Rates" value={"agency-hcp-rate"}/>
                                <TabComponent label={"Additional Rates"} value={"agency-additional-rate"}/>
                                <TabComponent label={"Preference"} value={"agency-preference"}/>
                                <TabComponent label={"Contract"} value={"contract"}/>
                            </TabsComponent>
                                <div className={'mrg-right-100'}>
                                    <ButtonComponent
                                        onClick={() => {
                                            onAgencyActivateDeactivate(agencyDetails);
                                        }}
                                        style={{
                                            backgroundColor: "#FFF0F0",
                                            color: "#E01E1E",
                                            boxShadow: "none",
                                            border: "1px solid #E01E1E",
                                        }} className={'cancel-shift-button'}
                                    >
                                        {`${agencyDetails?.is_active ? 'Deactivate' : 'Activate'}  Agency`}
                                    </ButtonComponent>
                                </div>
                            </div>

                            <TabContentComponent selectedTab={activeTab} value={"basic-details"}>
                                <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>
                                    <div className={'details-screen'}>
                                        <div className="details-screen-title-with-image-wrapper pdd-10">
                                            <div
                                                className={'details-screen-title-wrapper center-right-element mrg-left-30 mrg-right-30'}>
                                                <div className={'details-screen-title'}>Agency
                                                    Details
                                                </div>
                                                <div onClick={openEditDrawer} className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Agency Details</span>
                                                </div>
                                            </div>
                                        </div>
                                        <HorizontalLineComponent/>
                                        <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Agency Name'}>
                                                        {agencyDetails?.name || 'N/A'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Phone Number'}>
                                                        {CommonService.formatPhoneNumber(agencyDetails?.contact_number?.replace(/^\+1/, '')) || 'N/A'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Email ID'}>
                                                        {agencyDetails?.email || 'N/A'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Address'}>
                                                        {`${agencyDetails?.address?.street}, ${agencyDetails?.address?.city}, ${agencyDetails?.address?.state}, ${agencyDetails?.address?.zip_code}, ${agencyDetails?.address?.country}` || 'N/A'}
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Timezone'}>
                                                        {CommonService.getTimezoneTitleByCode(agencyDetails?.timezone) || 'N/A'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                {/*<div className={'ts-col-lg-3'}>*/}
                                                {/*    <DataLabelValueComponent label={'Maps Location Link'}>*/}
                                                {/*        <div onClick={handleRedirect} style={{cursor: "pointer"}}*/}
                                                {/*             className={'underline-text'}*/}
                                                {/*        >*/}
                                                {/*            View Location*/}
                                                {/*        </div>*/}
                                                {/*    </DataLabelValueComponent>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div className={"ts-row"}>
                                                <div className={'ts-col-lg-6'}>
                                                    <DataLabelValueComponent label={'About the Agency'}>
                                                        {agencyDetails?.about || 'N/A'}
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardComponent>

                                <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>
                                    <div className={'details-screen'}>
                                        <div className="details-screen-title-with-image-wrapper pdd-10 ">
                                            <div
                                                className={'details-screen-title-wrapper center-right-element mrg-left-30'}>
                                                <div className={'details-screen-title'}>Admin Details
                                                </div>
                                                <div onClick={openAdminEditDrawer} className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text mrg-right-30'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Admin Details</span>
                                                </div>
                                            </div>
                                        </div>
                                        <HorizontalLineComponent/>
                                        <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Admin Name'}>
                                                        {agencyDetails?.admin_details?.first_name + " " + agencyDetails?.admin_details?.last_name || 'N/A'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Phone Number'}>
                                                        {CommonService.formatPhoneNumber(agencyDetails?.admin_details?.contact_number?.replace(/^\+1/, '')) || 'N/A'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Email ID'}>
                                                        {agencyDetails?.admin_details?.email || 'N/A'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Notifications Preference'}>
                                                        {(agencyDetails?.admin_details?.notification_preferences?.isSmsRequired ? "SMS, " : "") + (agencyDetails?.admin_details?.notification_preferences?.isEmailRequired ? "Email, " : "") + (agencyDetails?.admin_details?.notification_preferences?.isPush_notificationRequired ? "In App " : "") || 'N/A'}
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardComponent>


                            </TabContentComponent>
                            <TabContentComponent value={activeTab} selectedTab={"agency-hcp-rate"}>
                                <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>
                                    <div className={'details-screen'}>
                                        <div className="details-screen-title-with-image-wrapper pdd-10">
                                            <div
                                                className={'details-screen-title-wrapper center-right-element'}>
                                                <div className={'details-screen-title'} onClick={() => {
                                                    console.log(agencyDetails);
                                                }
                                                }><span className={"mrg-right-10 icon-button"} onClick={() => {
                                                    setHcpRatesAgencyDetails(null);
                                                }}></span>Agency Staff Rates
                                                </div>
                                                <div onClick={openAgencyHcpRateEditDrawer}
                                                     className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Agency Staff Rates</span>
                                                </div>
                                            </div>
                                        </div>
                                        <HorizontalLineComponent/>
                                        <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                            {(isHcpRatesAgencyDetailsLoaded && HcpRatesAgencyDetails?.hcp_type_rates?.length > 0) && <>
                                                <div className={'ts-row'}>
                                                    {HcpRatesAgencyDetails?.hcp_type_rates?.map((hcpRate: any, index: any) => {
                                                        return (
                                                            <>
                                                                <div className={'ts-col-lg-3'}>
                                                                    <DataLabelValueComponent
                                                                        label={`${hcpRate?.hcp_type_details?.title}`}>
                                                                        $ {hcpRate?.rate || '-'}
                                                                    </DataLabelValueComponent>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            </>}
                                            {isHcpRatesAgencyDetailsLoading && <>
                                                <div className={'center-element'}>
                                                    <LoaderComponent type={"spinner"}/>
                                                </div>
                                            </>}
                                            {(!HcpRatesAgencyDetails?.hcp_type_rates || HcpRatesAgencyDetails?.hcp_type_rates?.length === 0) && <>
                                                <StatusCardComponent title={"No Staff Rates Found"}/>
                                            </>}
                                        </div>
                                    </div>
                                </CardComponent>
                            </TabContentComponent>
                            <TabContentComponent value={activeTab} selectedTab={"agency-additional-rate"}>
                                <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>
                                    <div className={'details-screen'}>
                                        <div className="details-screen-title-with-image-wrapper pdd-10">
                                            <div
                                                className={'details-screen-title-wrapper center-right-element'}>
                                                <div className={'details-screen-title'} onClick={() => {
                                                    console.log(agencyDetails);
                                                }
                                                }>Additional Rates
                                                </div>
                                                <div onClick={openAgencyAdditionalRateEditDrawer}
                                                     className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Agency Additional Rates</span>
                                                </div>
                                            </div>
                                        </div>
                                        <HorizontalLineComponent/>
                                        <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                            {HcpRatesAgencyDetails?.addl_rates?.length > 0 && <>
                                                <div className={'ts-row'}>
                                                    {HcpRatesAgencyDetails?.addl_rates?.map((hcpRate: any, index: any) => {
                                                        return (
                                                            <>
                                                                <div className={'ts-col-lg-3'}>
                                                                    <DataLabelValueComponent
                                                                        label={`${hcpRate?.addl_rate_details?.title}`}>
                                                                        $ {hcpRate?.rate || '-'}
                                                                    </DataLabelValueComponent>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            </>}
                                            {isHcpRatesAgencyDetailsLoading && <>
                                                <LoaderComponent type={"spinner"}/>
                                            </>}
                                            {(!HcpRatesAgencyDetails?.addl_rates || HcpRatesAgencyDetails?.addl_rates?.length === 0) && <>
                                                <StatusCardComponent title={"No Additional Rates Found"}/>
                                            </>}
                                        </div>
                                    </div>
                                </CardComponent>
                            </TabContentComponent>
                            <TabContentComponent selectedTab={activeTab} value={"agency-preference"}>
                                <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>
                                    <div className={'details-screen'}>
                                        <div className="details-screen-title-with-image-wrapper pdd-10">
                                            <div
                                                className={'details-screen-title-wrapper center-right-element'}>
                                                <div className={'details-screen-title'} onClick={() => {
                                                    console.log(agencyDetails);
                                                }
                                                }>Preferences
                                                </div>
                                                <div onClick={openEditPreferenceDrawer}
                                                     className={'icon-button'}>
                                                            <span
                                                                className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                                className={'mrg-right-10'}/>Edit Preference</span>
                                                </div>
                                            </div>
                                        </div>
                                        <HorizontalLineComponent/>
                                        <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent
                                                        label={'Preferred Mode of Contact'}>
                                                        {HcpRatesAgencyDetails?.preferences?.mode_of_contact === "both" ? "Both SMS And Email" : (HcpRatesAgencyDetails?.preferences?.mode_of_contact || "N/A")}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-6'}>
                                                    <DataLabelValueComponent
                                                        label={'Billing Every (Weekly, Bi-weekly, Etc)'}>
                                                        {HcpRatesAgencyDetails?.preferences?.bill_payment_frequency || 'N/A'}
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardComponent>
                            </TabContentComponent>
                            <TabContentComponent value={"contract"} selectedTab={activeTab}>
                                <CardComponent size={"sm"} className={'mrg-right-100 mrg-left-100'}>
                                    <div className={'details-screen'}>
                                        <div className="details-screen-title-with-image-wrapper pdd-10">
                                            <div
                                                className={'details-screen-title-wrapper center-right-element'}>
                                                <div className={'details-screen-title'}>
                                                    <div className={'d-flex'}>
                                                        Contract
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <HorizontalLineComponent/>
                                        {/*<div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>*/}
                                        {/*    <div className={'ts-row'}>*/}
                                        {/*        <div className={'ts-col-lg-6'}>*/}
                                        {/*            <DataLabelValueComponent*/}
                                        {/*                label={'Expiry Date'}>*/}
                                        {/*                {HcpRatesAgencyDetails?.contract_expiry_date ? CommonService.convertDateFormat(*/}
                                        {/*                    HcpRatesAgencyDetails?.contract_expiry_date,*/}
                                        {/*                    'MM-DD-YYYY',*/}
                                        {/*                ) : "-"}*/}
                                        {/*            </DataLabelValueComponent>*/}
                                        {/*        </div>*/}
                                        {/*        <div className={'ts-col-lg-6'}>*/}
                                        {/*            <DataLabelValueComponent*/}
                                        {/*                label={'Contract'}>*/}
                                        {/*                {HcpRatesAgencyDetails?.contract ?*/}
                                        {/*                    <div*/}
                                        {/*                        className={'action-button-trigger-wrapper d-flex'}>*/}
                                        {/*                        <div*/}
                                        {/*                            className={'center-element contract-action-text icon-button'}*/}
                                        {/*                            onClick={() => {*/}
                                        {/*                                CommonService.previewPDF(HcpRatesAgencyDetails?.contract?.url);*/}
                                        {/*                            }}>*/}
                                        {/*                            <ImageConfig.ViewContractIcon/><span*/}
                                        {/*                            className={'mrg-left-10'}>View</span></div>*/}
                                        {/*                        <div*/}
                                        {/*                            className={'center-element mrg-left-25 contract-action-text icon-button'}*/}
                                        {/*                            onClick={() => {*/}
                                        {/*                                downloadHcpDocument(HcpRatesAgencyDetails?.contract?.key);*/}
                                        {/*                            }}>*/}
                                        {/*                            <ImageConfig.DownloadIconPreview/><span*/}
                                        {/*                            className={'mrg-left-10'}>Download</span>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div> : "_"}*/}
                                        {/*            </DataLabelValueComponent>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {HcpRatesAgencyDetails?.contract && (
                                            <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-6'}>
                                                        <DataLabelValueComponent
                                                            label={'Expiry Date'}>
                                                            {HcpRatesAgencyDetails?.contract_expiry_date ? CommonService.convertDateFormat(
                                                                HcpRatesAgencyDetails?.contract_expiry_date,
                                                                'MM-DD-YYYY',
                                                            ) : "-"}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-6'}>
                                                        <DataLabelValueComponent
                                                            label={'Contract'}>
                                                            <div
                                                                className={'action-button-trigger-wrapper d-flex'}>
                                                                {HcpRatesAgencyDetails?.contract?.url && <div
                                                                    className={'center-element contract-action-text icon-button'}
                                                                    onClick={() => {
                                                                        CommonService.previewPDF(HcpRatesAgencyDetails?.contract?.url);
                                                                    }}>
                                                                    <ImageConfig.ViewContractIcon/><span
                                                                    className={'mrg-left-10'}>View</span></div>}
                                                                {HcpRatesAgencyDetails?.contract?.key && <div
                                                                    className={'center-element mrg-left-25 contract-action-text icon-button'}
                                                                    onClick={() => {
                                                                        downloadHcpDocument(HcpRatesAgencyDetails?.contract?.key);
                                                                    }}>
                                                                    <ImageConfig.DownloadIcon/><span
                                                                    className={'mrg-left-10'}>Download</span>
                                                                </div>}
                                                                {
                                                                    HcpRatesAgencyDetails?.contract?.key && <div
                                                                        className={'center-element mrg-left-25 contract-action-text icon-button delete-button'}
                                                                        onClick={() => {
                                                                            // deleteHcpDocument(hcpDetails?.contract?.key);
                                                                            onHandleDeleteDocument("Contract");
                                                                        }}
                                                                    >
                                                                        <ImageConfig.DeleteOutlineIcon/><span
                                                                        className={'mrg-left-10'}>Delete</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {
                                            !HcpRatesAgencyDetails?.contract && (
                                                <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                    <div className="no-content">
                                                        <div className="action-button">
                                                            <div className="description-text mrg-left-15 mrg-bottom-10">
                                                                No Contract Available
                                                            </div>
                                                            <ButtonComponent
                                                                color={'download-button'}
                                                                onClick={() => {
                                                                    openContractEditDrawer();
                                                                }}
                                                                prefixIcon={<ImageConfig.UploadIcon/>}>Upload
                                                                Contract</ButtonComponent>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </CardComponent>
                            </TabContentComponent>
                        </TabsWrapperComponent>
                    </>
                }
            </div>
        </>
    );
};
export default AgencyDetailsScreen;
