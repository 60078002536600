import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {useNavigate} from "react-router-dom";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {ImageConfig, Misc} from "../../../constants";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import "./AddUserDetailsComponent.scss";
import {addUserSteps, designationNames} from "../../../data";
import {USER_MANAGEMENT} from "../../../constants/RoutesConfig";
// import FormikCheckBoxComponent from "../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";
import StepperComponent from "../../../shared/components/stepper-component/StepperComponent";
import FormikPhoneInputComponent
    from "../../../shared/components/form-controls/formik-phone-input/FormikPhoneInputComponent";
import FormikSelectDropdownComponent
    from "../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";

interface UserDetailsComponentProps {
    prev: () => void;
}

const UserValidationSchema = Yup.object().shape({
        admin_first_name: Yup.string()
            .min(2, 'First Name should be at least 2 characters')
            .max(50, 'First Name should not exceed 50 characters')
            .required('First Name is required'),
        admin_last_name: Yup.string()
            .min(2, 'Last Name should be at least 2 characters')
            .max(50, 'Last Name should not exceed 50 characters')
            .required('Last Name is required'),
        admin_contact_number: Yup.string().min(14, "Phone number must be 10 digits").required("Phone Number is Required"),
        admin_email: Yup.string().email('Invalid email address').required('Email Address is required'),
        role:Yup.string().required('Role is required'),
    }
);

interface formInitialValuesFormSchema {
    admin_contact_number: any;
    admin_email: string;
    admin_first_name: any;
    admin_last_name: any;
    role:any;
    // notification_preferences: {
    //     isSmsRequired: any;
    //     isEmailRequired: any;
    //     isPush_notificationRequired: any;
    // };
}

const formInitialValues: formInitialValuesFormSchema = {
    admin_contact_number: "",
    admin_email: "",
    admin_first_name: "",
    admin_last_name: "",
    role:"",
    // notification_preferences: {
    //     isSmsRequired: true,
    //     isEmailRequired: true,
    //     isPush_notificationRequired: true,
    // }
};


const UserDetailsComponent = (props: UserDetailsComponentProps) => {
    const [initialValues] = useState<formInitialValuesFormSchema>(formInitialValues);
    const navigate = useNavigate();
    const [userAddActiveStep] = React.useState(0);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
            admin_contact_number:"+1"+values?.admin_contact_number.replace(/\D/g,'')
        };
        let apiCall: any = {};
        apiCall = CommonService._facility.AddFacilityAdminApiCall(payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            navigate(CommonService._routeConfig.UserManagementRoute());
        })
            .catch((error: any) => {
                CommonService._alert.showToast(error?.error, "error");
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
        // eslint-disable-next-line
    }, [navigate]);

    return (
        <div className={'add-user-details-component'}>
            <div className={'add-user-sidebar-steper'}>
                <div className={'add-user-sidebar-steper-wrapper pdd-20'}>
                    <div className={'flex-1'}>
                        <div className={'adding-module-branding-wrapper'}>
                            <div className="menu-item-icon">
                                <ImageConfig.VarsShortLogoIcon/>
                            </div>

                            <div className={'vars-health-logo-text'}>
                                Work OS
                            </div>
                        </div>
                        <div className={'form-top-header-text mrg-top-40'}>
                            User Onboarding
                        </div>
                        <StepperComponent activeStep={userAddActiveStep} steps={addUserSteps}/>
                    </div>
                    <div>
                        <ButtonComponent
                            id={"exit_btn"}
                            suffixIcon={<ImageConfig.ExitIcon/>}
                            style={{
                                backgroundColor: "#F96868",
                                color: "white",
                                border: "1px solid #F96868"
                            }}
                            onClick={() => {
                                // navigate(FACILITY_lIST);
                                CommonService.onExitFlowHandling(navigate, USER_MANAGEMENT);
                            }}
                        >
                            Exit User Onboarding
                        </ButtonComponent>
                    </div>
                </div>
            </div>
            <div className={'add-user-formik-wrapper mrg-top-20'}>
                <Formik
                    validationSchema={UserValidationSchema}
                    initialValues={initialValues}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            validateForm();
                        }, [validateForm, values]);
                        return (
                            <Form noValidate={true}>
                                <div className="add-component-content">
                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                                    <div className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                        <div className={'form-top-header-text'}>User Details</div>
                                        <>
                                            <div className="ts-row">
                                                <div className="ts-col-6">
                                                    <Field name={'admin_first_name'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'First Name'}
                                                                    placeholder={'Ex : John'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-6">
                                                    <Field name={'admin_last_name'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Last Name'}
                                                                    placeholder={'Ex : Doe'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="ts-row">
                                                <div className="ts-col-6">
                                                    <LabelComponent title={'Phone Number'} required={true}/>
                                                    <Field name={'admin_contact_number'}>
                                                        {/*{(field: FieldProps) => {*/}
                                                        {/*    return <PhoneInputComponent field={field}*/}
                                                        {/*                                placeholder={"Enter Phone number"}/>;*/}
                                                        {/*}}*/}
                                                        {(field: FieldProps) => (
                                                            <FormikPhoneInputComponent
                                                                formikField={field}
                                                                required={true}
                                                                placeholder={'Enter Phone Number'}
                                                                fullWidth={true}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="ts-col-6">
                                                    <Field name={'admin_email'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Email Address'}
                                                                    placeholder={'example@xyz.com'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-12'}>
                                                    <Field name={'role'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={designationNames}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.value}
                                                                    label={'Role'}
                                                                    placeholder={'Select Role'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    searchable={true}
                                                                    noDataMessage={"No role available"}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            {/*<div className={'form-top-header-text'}>Notification Preferences</div>*/}
                                            {/*<div className="ts-row">*/}
                                            {/*    <div className="ts-col-4">*/}
                                            {/*        <Field name={'notification_preferences.isSmsRequired'}>*/}
                                            {/*            {*/}
                                            {/*                (field: FieldProps) => (*/}
                                            {/*                    <FormikCheckBoxComponent*/}
                                            {/*                        formikField={field}*/}
                                            {/*                        label={"SMS"}*/}
                                            {/*                    />*/}
                                            {/*                )*/}
                                            {/*            }*/}
                                            {/*        </Field>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="ts-col-4">*/}
                                            {/*        <Field name={'notification_preferences.isEmailRequired'}>*/}
                                            {/*            {*/}
                                            {/*                (field: FieldProps) => (*/}
                                            {/*                    <FormikCheckBoxComponent*/}
                                            {/*                        formikField={field}*/}
                                            {/*                        label={"Email"}*/}
                                            {/*                    />*/}
                                            {/*                )*/}
                                            {/*            }*/}
                                            {/*        </Field>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="ts-col-4">*/}
                                            {/*        <Field*/}
                                            {/*            name={'notification_preferences.isPush_notificationRequired'}>*/}
                                            {/*            {*/}
                                            {/*                (field: FieldProps) => (*/}
                                            {/*                    <FormikCheckBoxComponent*/}
                                            {/*                        formikField={field}*/}
                                            {/*                        label={"In App"}*/}
                                            {/*                    />*/}
                                            {/*                )*/}
                                            {/*            }*/}
                                            {/*        </Field>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </>
                                    </div>
                                    <div className={'add-user-form-action-element'}>
                                        <div className={"mrg-left-20"}>
                                            {/*<ButtonComponent*/}
                                            {/*    className={'back-button'}*/}
                                            {/*    suffixIcon={<ImageConfig.BackArrow/>}*/}
                                            {/*    onClick={() => {*/}
                                            {/*        navigate(USER_MANAGEMENT);*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    Back*/}
                                            {/*</ButtonComponent>*/}
                                        </div>
                                        <ButtonComponent
                                            disabled={isSubmitting || !isValid}
                                            isLoading={isSubmitting}
                                            type={'submit'}
                                        >
                                            Save Details
                                        </ButtonComponent>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );

};

export default UserDetailsComponent;
