import "./AgencyListComponent.scss";
import {APIConfig, ImageConfig, Misc} from "../../../constants";
import {CommonService} from "../../../shared/services";
import {useCallback, useEffect, useState} from "react";
import SearchComponent from "../../../shared/components/search/SearchComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {useDispatch} from "react-redux";
import {setCurrentNavParams} from "../../../store/actions/navigation.action";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {useAgencyListColumn} from "./TableColumnns";
import AntTableComponent from "../../../shared/components/antd-table-component/AntTableComponent";

interface AgencyListComponentProps {

}

const AgencyListComponent = (props: AgencyListComponentProps) => {
    const [filterData, setFilterData] = useState<any>({
        search: '',
        sort: {},
    });
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setCurrentNavParams('Agency') as any);
    }, [dispatch]);

    const onAgencyActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  Agency`,
                image: item?.is_active ? ImageConfig.AgencyDeactivateIcon : ImageConfig.AgencyActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'deactivate' : 'activate'}  the
    Agency?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: item?.is_active,
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._agency.ActivateDeactivateAgencyFromList(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setFilterData({});
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, []);
    const AgencyListColumn = useAgencyListColumn(onAgencyActivateDeactivate);
    const handleDownloadAgency = useCallback(() => {
        setIsDownloading(true);
        const payload: any = {};
        const {status} = filterData;
        payload.status = [status];
        let apiCall: any = {};
        apiCall = CommonService._agency.downloadAgencyDataCSVAPICall(filterData);
        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsDownloading(false);
            }

        })
            .catch((error: any) => {
                CommonService._alert.showToast(error?.error || error?.message || 'Something is wrong!', "error");
                setIsDownloading(false);
            }).finally(() => {

        });
    }, [filterData]);

    return (
        <>
            <div className={'agency-list-component header-screen mrg-top-25 mrg-right-30 mrg-left-25'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.AgencyModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title">Agency</div>
                        </div>
                    </div>
                    <div className="list-options">
                        <div className={"mrg-top-20"}>
                            <SearchComponent
                                placeholder="Search Agency Name"
                                value={filterData.search}
                                onSearchChange={(value) => {
                                    setFilterData({...filterData, search: value, page: 1});
                                }}
                            />
                        </div>
                        <ButtonComponent color={'download-button'} variant={"contained"}
                                         isLoading={isDownloading}
                                         onClick={handleDownloadAgency}>
                            Download CSV
                        </ButtonComponent>
                        <LinkComponent route={CommonService._routeConfig.AddAgencyRoute()}>
                            <ButtonComponent color={'primary'} prefixIcon={<ImageConfig.AddOutlinedIcon/>}>
                                Add/Link Agency
                            </ButtonComponent>
                        </LinkComponent>

                    </div>
                </div>
            </div>
            <div className={'agency-list-component mrg-top-10 mrg-left-25 mrg-right-30'}>
                <div className={'agency-table-component'}>
                    {/*<TableWrapperComponent url={APIConfig.AGENCY_LIST.URL}*/}
                    {/*                       method={APIConfig.AGENCY_LIST.METHOD}*/}
                    {/*                       isPaginated={true}*/}
                    {/*                       extraPayload={filterData}*/}
                    {/*                       noDataText={filterData.search ? 'Oops! It seems like there are no Agency available for the name you have searched.' : 'No Agency Data found.'}*/}
                    {/*                       columns={AgencyListColumn}*/}
                    {/*/>*/}
                    <AntTableComponent
                        url={APIConfig.AGENCY_LIST.URL}
                        method={APIConfig.AGENCY_LIST.METHOD}
                        isRowSelection={false}
                        extraPayload={filterData}
                        noDataText={filterData.search ? 'Oops! It seems like there are no Agency available for the name you have searched.' : 'No Agency Data found.'}
                        columns={AgencyListColumn}
                    />
                </div>
            </div>
        </>
    );

};

export default AgencyListComponent;
