import React, {useMemo} from 'react';
import {CommonService} from "../../../shared/services";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {ImageConfig} from '../../../constants';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import {TableColumnsType} from "antd";

export const useUserListColumn = (onUserActivateDeactivate: any, setIsEdituserDetailsDrawerOpen: any, setUserDetails: any): TableColumnsType => {
    return useMemo(() => {
        return [
            {
                title: "User Name",
                width: 250,
                sorter: (a: any, b: any) =>
                    (a.first_name + " " + a.last_name).localeCompare(b.first_name + " " + b.last_name),
                render: (item: any) => {
                    return <div className={'user-management-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'user-management-name-wrapper'}>
                            {
                                (item?.first_name + " " + item?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.first_name + " " + item?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.first_name + " " + item?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.first_name + " " + item?.last_name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Email ID",
                align: 'left',
                width: 200,
                sorter: (a: any, b: any) => a.email?.localeCompare(b.email || '') || 0,
                render: (item: any) => {
                    return <>
                        <div className={'facility-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'facility-name-tower-wrapper'}>
                                {
                                    (item?.email)?.length > 20 ?
                                        <ToolTipComponent
                                            tooltip={item?.email}
                                            showArrow={true}
                                            position={"top"}
                                        >
                                            <div className={"ellipses-for-table-data property-name"}>
                                                {item?.email}
                                            </div>
                                        </ToolTipComponent> :
                                        <div className="facility-name">
                                            {item?.email || 'N/A'}
                                        </div>
                                }
                            </div>
                        </div>
                    </>;
                }
            },
            {
                title: "Phone Number",
                align: 'center',
                width: 120,
                // sorter: (a: any, b: any) =>
                //     a.contact_number?.localeCompare(b.contact_number || '') || 0,
                render: (item: any) => {
                    return <>
                        <div>
                            {CommonService.formatPhoneNumber(item?.contact_number?.replace(/^\+1/, '')) || "N/A"}
                        </div>
                    </>;
                }
            },
            {
                title:'Role',
                key:'role',
                dataIndex:'role',
                width:100,
                sorter: (a: any, b: any) => a.role?.localeCompare(b.role || ''),
                render:(item:any)=>{
                    return (
                        <div>
                            {item || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Active/ Inactive',
                width: 120,
                render: (item: any) => {
                    return <div className={'display-center'}>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onUserActivateDeactivate(item);
                        }}/>
                    </div>;
                }
            },
            {
                title: "Edit Details",
                align: 'center',
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent onClick={() => {
                            setIsEdituserDetailsDrawerOpen(true);
                            setUserDetails(item);
                        }}>
                            <ImageConfig.PencilEditIcon/>
                        </LinkComponent>;
                    }
                }
            }
        ];
    }, [onUserActivateDeactivate, setIsEdituserDetailsDrawerOpen, setUserDetails]);
};