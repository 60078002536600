import {Field, FieldArray, FieldProps, Form, Formik, FormikHelpers} from "formik";
import React, {useCallback, useEffect, useState} from "react";
import LabelComponent from "../../../../../shared/components/label/LabelComponent";
import FormikInputComponent from "../../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc} from "../../../../../constants";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import "./PreApprovedContractTravelComponent.scss";
import {CommonService} from "../../../../../shared/services";
import {IAPIResponseType} from "../../../../../shared/models/api.model";
import FormikTextAreaComponent
    from "../../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
import FormikSelectDropdownComponent
    from "../../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import ErrorComponent from "../../../../../shared/components/error/ErrorComponent";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePickers from "react-multi-date-picker";
import FormikTimePickerComponent
    from "../../../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent";
import RadioButtonGroupComponent
    from "../../../../../shared/components/form-controls/radio-button-group/RadioButtonGroupComponent";
import moment from "moment/moment";
import {contactTypeList} from "../../../../../data";


interface PreApprovedContractTravelComponentProps {
    setShiftTypeHeader?: any;
    getFacilityDetails?: any;
    handleInternalHCPListSubmit?: any;
    handleFloatHcpListSubmit?: any;
    setSelectedFacilityId?: any;
    selectedFacilityId?: any;
    selectedHcpType?: any;
    setSelectedHcpType?: any;
    isShiftCreationForSingleDate?: any;
    selectedDateFromCalender?: any;
    onCalender?: any;
    sixMonthsFromNow?: any;
    modifiedInternalHcpListRequirementBased?:any;
    modifiedFloatHcpListRequirementBased?:any;
    selectedStaff?:any;
    handleAgencyStaffListSubmit?:(value:any,setFieldValue:any)=>void;
    setSelectedStaff?:any;
    schedulingListFilterState?: any;
    setOpenDialog?: any;
    setSchedulingListFilterState?: any
}

interface PerDiem {
    type: string;
    rate: string;
    week_rate: string;
}

const shiftDetailsValidationSchema = Yup.object().shape({
    start_time:Yup.string().required('Start time is required'),
    end_time:Yup.string().required('End time is required'),
    shift_type: Yup.string().required('Shift Type is required'),
    additional_confirmations: Yup.string(),
    cancel_policy: Yup.string().required("Cancel Policy is required"),
    staff_requirement: Yup.string(),
    job_details: Yup.string(),
    job_benefits: Yup.string(),
});

interface formInitialValuesFormSchema {
    //required fields for contract travel form
    facility_id: string;
    shift_rate: string
    start_time: string;
    end_time:string;
    shift_type:string;
    cancel_policy: string;
    staff_requirement: string;
    job_benefits: string;
    job_details: string;
    travel_per_diems: PerDiem[];
    shift_dates:any;
    hcp_ids:any;
}


const PreApprovedContractTravelComponent = (props: PreApprovedContractTravelComponentProps) => {
    const navigate = useNavigate();
    const {
        selectedFacilityId,
        handleInternalHCPListSubmit,
        handleFloatHcpListSubmit,
        getFacilityDetails,
        modifiedInternalHcpListRequirementBased,
        onCalender,
        sixMonthsFromNow,
        modifiedFloatHcpListRequirementBased,
        selectedStaff,
        setSelectedStaff,
        selectedDateFromCalender,
        schedulingListFilterState,
        setOpenDialog,
        setSchedulingListFilterState,
    } = props;
    const [value, setValue] = useState<any>(
        onCalender ? [selectedDateFromCalender] : null,
    );

    const formInitialValues: formInitialValuesFormSchema = {
        facility_id: "",
        shift_dates: selectedDateFromCalender ? selectedDateFromCalender : null,
        shift_rate: "",
        start_time: "",
        end_time:"",
        shift_type:"",
        cancel_policy: "",
        staff_requirement: "",
        job_details: "",
        job_benefits: "",
        hcp_ids:"",
        travel_per_diems: [
            {
                type: 'Shift Rate',
                rate: '',
                week_rate: '',
            },
            {
                type: 'Housing',
                rate: '',
                week_rate: '',
            },
            {
                type: 'Meals',
                rate: '',
                week_rate: '',
            },
        ],
    };
    const [initialValues] = useState<formInitialValuesFormSchema>(formInitialValues);

    function handleDatePicker(value: any) {
        setValue(value);
    }

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        let payload: any = {
            ...values
        }
        let shift_dates;
        shift_dates = value.map((item: any) => {
            let mm = item?.month?.number;
            let dd = item?.day;
            let yyyy = item?.year;
            return moment(`${yyyy}-${mm}-${dd}`).format('YYYY-MM-DD');
        });
        payload.shift_dates=shift_dates;
        payload.start_time = CommonService.convertToAdjustedMinutes(values.start_time);
        payload.end_time = CommonService.convertToAdjustedMinutes(values.end_time);
        payload.requirementType = "Contract Travel";
        payload.hcp_ids=[values?.hcp_ids];
        let apiCall: any = {};
        apiCall = CommonService._shift_management.preApprovedContractShiftAPICall(payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            if (onCalender) {
                setOpenDialog(false)
                setSchedulingListFilterState({...schedulingListFilterState});
            } else {
                navigate(CommonService._routeConfig.ShiftManagementRoute());
            }
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                // CommonService._alert.showToast(error?.error, "error");
            }).finally(() => {
            setSubmitting(false);
        });
        // eslint-disable-next-line
    }, [navigate, selectedDateFromCalender,value,onCalender,setOpenDialog,setSchedulingListFilterState,schedulingListFilterState]);

    useEffect(() => {
        if (selectedFacilityId) {
            handleInternalHCPListSubmit();
            handleFloatHcpListSubmit();
        }
    }, [handleFloatHcpListSubmit, handleInternalHCPListSubmit, selectedFacilityId]);

    useEffect(() => {
        getFacilityDetails();
    }, [getFacilityDetails]);

    const handleStaffSelection=useCallback((value:string,setFieldValue:any)=>{
        setSelectedStaff(value)
        setFieldValue('hcp_ids',"")
    },[setSelectedStaff])


    return (
        <>
            <Formik
                validationSchema={shiftDetailsValidationSchema}
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                onSubmit={onSubmit}
            >
                {({
                      touched,
                      values,
                      errors,
                      validateForm,
                      isValid,
                      setFieldValue,
                      isSubmitting,
                      handleBlur
                  }) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form className="t-form scroll-content" noValidate={true}>
                            {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}

                            <div className="add-component-content">
                                <>
                                    <div className={'body-min-height'}>
                                            <div className={'requirement-form-body-wrapper'}>
                                                <LabelComponent title={'Job Details'} isBold={true}/>
                                                <div className={'ts-row mrg-top-10'}>
                                                    <div className={'ts-col-3'}>
                                                        <Field name={'shift_type'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikSelectDropdownComponent
                                                                        options={contactTypeList}
                                                                        displayWith={(option: any) => option.title}
                                                                        valueExtractor={(option: any) => option.code}
                                                                        label={'Shift Type*'}
                                                                        placeholder={'Select'}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-3'>
                                                        <LabelComponent title={'Visit Date(s)*'}/>
                                                        <div className='shift-calender mrg-bottom-20'>
                                                            <Field
                                                                required={true}
                                                                onUpdate={(e: any) => {
                                                                    setFieldValue('shift_dates', []);
                                                                }}
                                                                inputClass='custom-input'
                                                                className='rmdp-mobile'
                                                                plugins={[
                                                                    <DatePanel eachDaysInRange/>,
                                                                ]}
                                                                format='MM/DD/YYYY'
                                                                //  range={mode === "range" ? true : false}
                                                                multiple={true}
                                                                onChange={handleDatePicker}
                                                                value={value}
                                                                variant='inline'
                                                                inputVariant='outlined'
                                                                placeholder={'Select'}
                                                                id='input_shift_requirement_shift_datepicker'
                                                                name='shift_dates'
                                                                InputLabelProps={{shrink: true}}
                                                                component={DatePickers}
                                                                minDate={new Date()}
                                                                maxDate={sixMonthsFromNow}
                                                            />
                                                            <div
                                                                className={
                                                                    'd-flex justify-content-right'
                                                                }
                                                            >
                                                                {touched.shift_dates &&
                                                                (!value ||
                                                                    (value && value?.length === 0)) ? (
                                                                    // <div>{errors.hcp_type_id}</div>
                                                                    <ErrorComponent
                                                                        errorText={
                                                                            'Shift Dates is required'
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='ts-col-3'>
                                                        <LabelComponent title={'Shift Start Time*'}/>
                                                        <Field name={'start_time'}>
                                                            {(field: FieldProps) => (
                                                                <FormikTimePickerComponent
                                                                    placeholder={'Select Time'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-3'>
                                                        <LabelComponent title={'Shift End Time*'}/>
                                                        <Field name={'end_time'}>
                                                            {(field: FieldProps) => (
                                                                <FormikTimePickerComponent
                                                                    placeholder={'Select Time'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>

                                                <div className={'shift-requirement-divider-line mrg-bottom-20'}/>
                                                <LabelComponent title={'Rates'} isBold={true}/>
                                                <FieldArray name="travel_per_diems">
                                                    {({push, remove}) => (
                                                        <div className={'mrg-top-20'}>
                                                            {values?.travel_per_diems.map((perDiem, index) => (
                                                                <div className="ts-row">
                                                                    <div className="ts-col-4">
                                                                        <Field
                                                                            name={`travel_per_diems.${index}.type`}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikInputComponent
                                                                                        //  label={'Travel Per Diems Type*'}
                                                                                        placeholder={'Enter value'}
                                                                                        disabled={index < 3}
                                                                                        type={"text"}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                    {index === 0 &&
                                                                        <div className="ts-col-4">
                                                                            <Field
                                                                                name={`travel_per_diems.${index}.rate`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikInputComponent
                                                                                            //  label={'Travel Per Diem Rate*'}
                                                                                            placeholder={"$/hr"}
                                                                                            suffix={<>$</>}
                                                                                            type={"number"}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                            validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}
                                                                                            onChange={(value:any)=>{
                                                                                                setFieldValue(`travel_per_diems.${index}.rate`, value ? parseInt(value) : "")
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>}
                                                                    {index > 0 &&
                                                                        <div className="ts-col-4">
                                                                            <Field
                                                                                name={`travel_per_diems.${index}.rate`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikInputComponent
                                                                                            //  label={'Travel Per Diem Rate*'}
                                                                                            placeholder={"$/day"}
                                                                                            type={"number"}
                                                                                            suffix={<>$</>}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                            validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}
                                                                                            onChange={(value:any)=>{
                                                                                                setFieldValue(`travel_per_diems.${index}.rate`, value ? parseInt(value) : "")
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>}
                                                                    <div
                                                                        className={"ts-col-4 d-flex"}
                                                                        // className={`ts-col-${index > 2 ? 3 : 4}`}
                                                                    >
                                                                        <Field
                                                                            name={`travel_per_diems.${index}.week_rate`}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikInputComponent
                                                                                        //   label={'Weekly Earnings*'}
                                                                                        placeholder={'$/week'}
                                                                                        type={"number"}
                                                                                        suffix={<>$</>}
                                                                                        //  disabled={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                        validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}
                                                                                        onChange={(value:any)=>{
                                                                                            setFieldValue(`travel_per_diems.${index}.week_rate`, value ? parseInt(value) : "")
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                        {(values?.travel_per_diems?.length > 3 && index > 2) &&
                                                                            <div
                                                                                className="mrg-left-10 mrg-top-3">
                                                                                <div onClick={() => {
                                                                                    remove(index)
                                                                                }} className={'icon-button'}>
                                                                                    <ImageConfig.CrossIcon/>
                                                                                </div>
                                                                            </div>}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className={'mrg-bottom-10'}>
                                                                <div className={'d-flex justify-content-space-between'}>
                                                                    <div
                                                                        className={'d-flex ts-justify-content-center align-items-center'}>
                                                                        <ButtonComponent onClick={() =>
                                                                            push({type: '', rate: 0, total: 0})
                                                                        } color={'primary'} prefixIcon={
                                                                            <ImageConfig.AddOutlinedIcon/>}>
                                                                            Add More
                                                                        </ButtonComponent>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )}
                                                </FieldArray>

                                                <div
                                                    className={'shift-requirement-divider-line mrg-bottom-20 mrg-top-10'}/>

                                                <LabelComponent
                                                    title={'Add Staff to the Shift from'}
                                                    isBold={true}
                                                />
                                                <div className={'ts-row mrg-top-20 pdd-left-20'}>
                                                    <RadioButtonGroupComponent
                                                        options={
                                                            [
                                                                {
                                                                    title: "Internal Staff*",
                                                                    code: "internal-staff"
                                                                },
                                                                {
                                                                    title: 'Floating Staff*',
                                                                    code: 'floating-staff'
                                                                }
                                                            ]
                                                        }
                                                        value={selectedStaff}
                                                        valueExtractor={(option: any) => option.code}
                                                        onChange={(value: any) => {
                                                            handleStaffSelection(value, setFieldValue)
                                                        }}
                                                    />
                                                </div>
                                                {(selectedStaff === 'internal-staff') &&
                                                    <div className={'ts-row pdd-10 mrg-top-10'}>
                                                        <>
                                                            <Field name={'hcp_ids'}>
                                                                {(field: FieldProps) => (
                                                                    <FormikSelectDropdownComponent
                                                                        options={modifiedInternalHcpListRequirementBased}
                                                                        displayWith={(option: any) =>
                                                                            option.title
                                                                        }
                                                                        valueExtractor={(option: any) =>
                                                                            option.code
                                                                        }
                                                                        placeholder={'Select'}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                        onUpdate={(value)=>{
                                                                            setFieldValue('hcp_ids',value ? value : "")
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </>
                                                    </div>
                                                }
                                                {
                                                    selectedStaff === 'floating-staff' &&
                                                    <div className={'ts-row pdd-10 mrg-top-10'}>
                                                        <>
                                                            <Field name={'hcp_ids'}>
                                                                {(field: FieldProps) => (
                                                                    <FormikSelectDropdownComponent
                                                                        options={modifiedFloatHcpListRequirementBased}
                                                                        displayWith={(option: any) =>
                                                                            option.title
                                                                        }
                                                                        valueExtractor={(option: any) =>
                                                                            option.code
                                                                        }
                                                                        placeholder={'Select'}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                        onUpdate={(value)=>{
                                                                            setFieldValue('hcp_ids',value ? value : "")
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </>
                                                    </div>

                                                }

                                                <div
                                                    className={'shift-requirement-divider-line mrg-bottom-20 mrg-top-10'}/>


                                                <div className="ts-row">
                                                    <div className="ts-col-12">
                                                        <Field name={'cancel_policy'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikTextAreaComponent
                                                                        label={"Cancel Policy*"}
                                                                        placeholder={'Enter Cancel Policy'}
                                                                        rows={6}
                                                                        //required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-12">
                                                        <Field name={'staff_requirement'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikTextAreaComponent
                                                                        label={"Staff Requirements"}
                                                                        placeholder={'• References, Skills, certifications, or required years of experience'}
                                                                        rows={6}
                                                                        //required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-12">
                                                        <Field name={'job_details'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikTextAreaComponent
                                                                        label={"Job Details"}
                                                                        placeholder={'• Enter any relevant details about the job'}
                                                                        rows={6}
                                                                        //required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-12">
                                                        <Field name={'job_benefits'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikTextAreaComponent
                                                                        label={"Job Benefits"}
                                                                        placeholder={'• Allied travel assignments are typically for 13 weeks and offer ​ generous packages that include: \n' +
                                                                            '• Competitive pay rates \n' +
                                                                            '• Medical, Dental, Vision \n' +
                                                                            '• 401(k) and Flex Spending \n' +
                                                                            '• Life Insurance \n' +
                                                                            '• Accident and Short-term Disability Coverage \n' +
                                                                            '• Free Continuing Education \n' +
                                                                            '• Competitive Housing Deal \n' +
                                                                            '• Refer a friend and earn extra cash!'}
                                                                        rows={9}
                                                                        //required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </>
                                    <div className={"ts-row mrg-top-15"} style={{alignSelf: "center"}}>
                                        <ButtonComponent
                                            disabled={isSubmitting || !isValid || values?.hcp_ids?.length === 0}
                                            isLoading={isSubmitting}
                                            type={'submit'}
                                            style={{backgroundColor: "#192938", color: "white"}}
                                        >
                                            Create Pre Approved Shift
                                        </ButtonComponent>
                                    </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default PreApprovedContractTravelComponent;
