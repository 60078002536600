import "./FacilityHcpRateComponent.scss";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import React, {useCallback, useEffect, useState} from "react";
import {ImageConfig, Misc, Patterns} from "../../../constants";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {getHcpTypesList} from "../../../store/actions/meta.action";
import FormikSelectDropdownComponent
    from "../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import DialogComponent from "../../../shared/components/dialog-component/DialogComponent";
import AddHcpTypeComponent from "./add-hcp-type/AddHcpTypeComponent";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import AddAdditionalTypeComponent from "./add-additional-type/AddAdditionalTypeComponent";
import ToggleComponent from "../../../shared/components/toggle-box/ToggleComponent";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import ErrorComponent from "../../../shared/components/error/ErrorComponent";
import _ from "lodash";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import ContactPreviewFileComponent
    from "../../hcp-management/hcp-management-add/add-hcp-details/file-preview-for-contract/ContactPreviewFileComponent";
import FormikDatePickerComponent
    from "../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import * as Yup from "yup";
import FilePickerComponent from "../../../shared/components/file-picker/FilePickerComponent";

interface FacilityHCPRatesProps {
    selectedAgencyDetails: any;
    HcpRatesAgencyDetails: any;
    filterData: any;
    getSelectedAgencyDetails: () => void;
    setFormScreenNavigation: any;
    setFormHeader?: any;
    ratesInnerNavigation: any;
    setRateInnerNavigation: any;
    setAgencyAddActiveStep?: any;
    handleNext:any;
}

// const validationSchema = Yup.object({
//         contract: Yup.mixed().required("Contract File is required"),
//         contract_expiry_date: Yup.mixed().required("Contract Date is required"),
//     }
// );

// will take this once we are going with contract
// interface formInitialValuesFormSchema {
//     contract: any;
//     contract_expiry_date: any;
// }

// const formInitialValues: formInitialValuesFormSchema = {
//     contract: "",
//     contract_expiry_date: "",
// };

const validationSchema = Yup.object({
    // contract: Yup.mixed().required("Contract File is required"),
    // contract_expiry_date: Yup.mixed().required("Contract Date is required"),
    }
);

interface formInitialValuesFormSchema {
    contract: any;
    contract_expiry_date: any;
}

const formInitialValues: formInitialValuesFormSchema = {
    contract: "",
    contract_expiry_date: "",
};

const selectModeOfContactOption = [
    {
        name: "E-Mail",
        _id: "email"
    },
    {
        name: "SMS",
        _id: "sms"
    },
    {
        name: "Both SMS And Email",
        _id: "both"
    }
];

const SelectBillPaymentFrequency = [
    {
        name: "Weekly",
        _id: "weekly"
    },
    {
        name: "Bi-Weekly",
        _id: "bi-weekly"
    },
];

const preferenceFormInitialValue = {
    mode_of_contact: "",
    bill_payment_frequency: ""
};


const FacilityHcpRateComponent = (props: FacilityHCPRatesProps) => {
    const {
        isHcpTypesListLoaded,
        isHcpTypesListLoading,
        isAdditionalRatesListLoading,
        isAdditionalRatesListLoaded,
        HcpTypesList,
        AdditionalRatesList
    } = useSelector((state: IRootReducerState) => state.meta);
    const [preferenceInitialValues, setPreferenceInitialValues] = useState<any>(preferenceFormInitialValue);
    const {
        handleNext,
        filterData,
        HcpRatesAgencyDetails,
        setRateInnerNavigation, ratesInnerNavigation, setFormScreenNavigation, getSelectedAgencyDetails
    } = props;
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openAdditionalDialog, setOpenAdditionalDialog] = useState<boolean>(false);
    const [initialValuesContract, setInitialValuesContract] = useState<any>(formInitialValues);
    useEffect(() => {
        const preferenceDetails = {
            mode_of_contact: HcpRatesAgencyDetails?.preferences?.mode_of_contact,
            bill_payment_frequency: HcpRatesAgencyDetails?.preferences?.bill_payment_frequency,
        };
        setPreferenceInitialValues(preferenceDetails);
    }, [HcpRatesAgencyDetails]);
    //@ts-ignore
    // const classes = useStyles();

    useEffect(() => {
        if (HcpRatesAgencyDetails?.contract?.key) {
            (async () => {
                    const contract = await CommonService.generateBlobFileFromUrl(HcpRatesAgencyDetails?.contract?.url, HcpRatesAgencyDetails?.contract?.name, HcpRatesAgencyDetails?.contract?.type);
                    const expiry_date = new Date(HcpRatesAgencyDetails?.contract_expiry_date);
                    const contractDetails = {
                        contract: contract,
                        contract_expiry_date: expiry_date
                    };
                    setInitialValuesContract(contractDetails);
                    console.log(contractDetails);
                }
            )();
        }
    }, [HcpRatesAgencyDetails]);


    const mergedTabList = useCallback(() => {
        return HcpTypesList.map((type: any) => {
            const matchingRate = HcpRatesAgencyDetails?.hcp_type_rates?.find((rate: any) => rate.hcp_type_id === type.code);
            return {
                ...type,
                enabled: !!matchingRate,
                value: matchingRate ? matchingRate.rate : 0
            };
        });
    }, [HcpTypesList, HcpRatesAgencyDetails]);

    const mergedAdditonalRateList = useCallback(() => {
        return AdditionalRatesList.map((type: any) => {
            const matchingRate = HcpRatesAgencyDetails?.addl_rates?.find((rate: any) => rate.addl_rate_id === type._id);
            return {
                ...type,
                enabled: !!matchingRate,
                value: matchingRate ? matchingRate.rate : 0
            };
        });
    }, [AdditionalRatesList, HcpRatesAgencyDetails]);


    // const mergedAdditonalRateList = AdditionalRatesList.map((type: any) => {
    //     const matchingPreference = selectedAgencyDetails?.agency_details?.addl_rates?.find((rate: any) => rate.addl_rate_id === type._id);
    //     return {
    //         ...type,
    //         enabled: !!matchingPreference,
    //         value: matchingPreference ? matchingPreference?.rate : 0
    //     };
    // });

    const handleHcpFacilityRateSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const modifiedData = values?.rates?.filter((item: any) => item.enabled).map((item: any) => ({
            hcp_type_id: item.code,
            rate: Number(item.value),
        }));
        const payload: any = {};

        payload.hcp_type_rates = modifiedData;

        CommonService._agency.EditHcpTypesRateAPICall(filterData?.selectedAgency, payload)
            .then((response: IAPIResponseType<any>) => {
                dispatch(getHcpTypesList());
                getSelectedAgencyDetails();
                setRateInnerNavigation("additioinal-rate-form");
                handleNext();
            }).catch((error: any) => {
        }).finally(() => {
            setSubmitting(false);
        });

    }, [filterData?.selectedAgency, dispatch, getSelectedAgencyDetails, setRateInnerNavigation, handleNext]);


    const handleAdditionalRateSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const modifiedData = values?.rates?.filter((item: any) => item.enabled).map((item: any) => ({
            addl_rate_id: item._id,
            rate: Number(item.value)
        }));
        const payload: any = {};

        payload.addl_rates = modifiedData;

        CommonService._agency.EditHcpTypesRateAPICall(filterData?.selectedAgency, payload)
            .then((response: IAPIResponseType<any>) => {
                setRateInnerNavigation("preference-form");
                getSelectedAgencyDetails();
                handleNext();
            }).catch((error: any) => {
        }).finally(() => {
            setSubmitting(false);
        });

    }, [filterData?.selectedAgency, setRateInnerNavigation, getSelectedAgencyDetails, handleNext]);

    const handlePreferenceRateSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload: any = {
            preferences: values
        };
        CommonService._agency.EditHcpTypesRateAPICall(filterData?.selectedAgency, payload)
            .then((response: IAPIResponseType<any>) => {
                setRateInnerNavigation("upload-contract");
                //  setFormScreenNavigation("upload-contract");
                getSelectedAgencyDetails();
                handleNext();
            }).catch((error: any) => {
        }).finally(() => {
            setSubmitting(false);
        });
    }, [filterData?.selectedAgency, setRateInnerNavigation, getSelectedAgencyDetails, handleNext]);

    const onFileUpload = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const formData = CommonService.getFormDataFromJSON(values);
        if (values?.contract_expiry_date) {
            formData.append("contract_expiry_date", CommonService.convertDateFormat(values?.contract_expiry_date, "YYYY-MM-DD"));
        }
        CommonService._agency.AgencyFacilityContractUploadAPICall(filterData?.selectedAgency, formData)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setFormScreenNavigation("onboarded-screen-completed");
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [filterData?.selectedAgency, setFormScreenNavigation]);

    return (<>

            <DialogComponent cancel={() => {
                CommonService._staticData.openDialogPopup(setOpenDialog, false);
            }} open={openDialog}>
                <AddHcpTypeComponent getSelectedAgencyDetails={getSelectedAgencyDetails}
                                     setRateInnerNavigation={setRateInnerNavigation}
                                     setOpenDialog={setOpenDialog}/>
            </DialogComponent>
            <DialogComponent cancel={() => {
                CommonService._staticData.openDialogPopup(setOpenAdditionalDialog, false);
            }} open={openAdditionalDialog}>
                <AddAdditionalTypeComponent
                    getSelectedAgencyDetails={getSelectedAgencyDetails}
                    setRateInnerNavigation={setRateInnerNavigation}
                    setOpenDialog={setOpenAdditionalDialog}/>
            </DialogComponent>
            <div className={"facility-hcp-rate-component"}>
                <div className={'rate-form-container flex-1'}>
                    {ratesInnerNavigation === "hcp-rate-form" &&
                        <div className={'rate-form'}>

                            <Formik
                                initialValues={{rates: mergedTabList()}}
                                onSubmit={handleHcpFacilityRateSubmit}
                                validateOnChange={false}
                                validateOnBlur={true}
                                enableReinitialize={true}
                                validateOnMount={true}
                            >
                                {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                                    // eslint-disable-next-line react-hooks/rules-of-hooks
                                    useEffect(() => {
                                        validateForm();
                                    }, [validateForm, values]);
                                    return (
                                        <Form>
                                            <div className="add-component-content">
                                                {isHcpTypesListLoading && <LoaderComponent/>}
                                                <div
                                                    className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                                    {isHcpTypesListLoaded &&
                                                        <>
                                                            <div
                                                                className={'d-flex justify-content-space-between align-items-center'}>
                                                                <div className={'form-top-header-text'}>Agency Staff Rates
                                                                </div>
                                                                {/*<div>*/}
                                                                {/*    <ButtonComponent onClick={() => {*/}
                                                                {/*        CommonService._staticData.openDialogPopup(setOpenDialog, true);*/}
                                                                {/*    }*/}
                                                                {/*    }*/}
                                                                {/*             color={"download-button"}>*/}
                                                                {/*        Add Staff Type*/}
                                                                {/*    </ButtonComponent>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                            <div className={'rate-component'}>
                                                                {
                                                                    values.rates.map((rate, index) => (
                                                                        <div key={index} className={'list-item'}>
                                                                            <div className={'d-flex'}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        minWidth: "200px",
                                                                                        maxWidth: "200px"
                                                                                    }}>
                                                                                    {
                                                                                        (rate.title)?.length > 20 ?
                                                                                            <ToolTipComponent
                                                                                                tooltip={rate.title}
                                                                                                showArrow={true}
                                                                                                position={"top"}
                                                                                            >
                                                                                                <div
                                                                                                    className={"ellipses-for-table-data property-name"}>
                                                                                                    {rate.title}
                                                                                                </div>
                                                                                            </ToolTipComponent> :
                                                                                            <div className="hcp-name">
                                                                                                {rate.title || '-'}
                                                                                            </div>
                                                                                    }
                                                                                </label>


                                                                                {/*<SwitchComponent*/}
                                                                                {/*    label={''}*/}
                                                                                {/*    checked={rate.enabled}*/}
                                                                                {/*    onChange={(checked) =>*/}
                                                                                {/*        setFieldValue(`rates[${index}].enabled`, checked)*/}
                                                                                {/*    }*/}
                                                                                {/*    size={"medium"}*/}
                                                                                {/*/>*/}
                                                                                <ToggleComponent
                                                                                    onToggle={(checked) => {
                                                                                        setFieldValue(`rates[${index}].enabled`, checked);
                                                                                    }
                                                                                    }
                                                                                    defaultOn={rate.enabled}
                                                                                />
                                                                                {rate.enabled && (
                                                                                    <div className={'mrg-left-40'}>
                                                                                        <Field
                                                                                            type="number"
                                                                                            name={`rates[${index}].value`}
                                                                                        >
                                                                                            {
                                                                                                (field: FieldProps) => (
                                                                                                    <FormikInputComponent
                                                                                                        className={"mrg-bottom-set-zero"}
                                                                                                        label={''}
                                                                                                        validationPattern={Patterns.NATURAL_INTEGER_DECIMALS}
                                                                                                        placeholder={'Rates'}
                                                                                                        type={"number"}
                                                                                                        required={true}
                                                                                                        formikField={field}
                                                                                                        fullWidth={true}
                                                                                                    />
                                                                                                )
                                                                                            }
                                                                                        </Field>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className={'add-agency-form-action-element'}>
                                                    <div className={"mrg-left-20"}>
                                                    </div>
                                                    <ButtonComponent
                                                        disabled={isSubmitting || !isValid}
                                                        isLoading={isSubmitting}
                                                        type={'submit'}
                                                        className={'next-button-in-rate-form'}
                                                        style={{color: "#202937"}}
                                                        suffixIcon={<ImageConfig.NextButtonIcon/>}
                                                    >
                                                        Next
                                                    </ButtonComponent>
                                                </div>
                                            </div>
                                        </Form>);
                                }}
                            </Formik>
                        </div>
                    }

                    {ratesInnerNavigation === "additioinal-rate-form" &&
                        <Formik
                            initialValues={{rates: mergedAdditonalRateList()}}
                            onSubmit={handleAdditionalRateSubmit}
                            validateOnChange={false}
                            validateOnBlur={true}
                            enableReinitialize={true}
                            validateOnMount={true}
                        >
                            {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                                // eslint-disable-next-line react-hooks/rules-of-hooks
                                useEffect(() => {
                                    validateForm();
                                }, [validateForm, values]);
                                return (
                                    <Form>
                                        <div className="add-component-content">
                                            <div
                                                className={"add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'"}>
                                                {isAdditionalRatesListLoading && <LoaderComponent/>}
                                                {isAdditionalRatesListLoaded && <>
                                                    <div
                                                        className={'d-flex justify-content-space-between align-items-center'}>
                                                        <div className={'form-top-header-text'}>Additional Rates
                                                        </div>
                                                        <div>
                                                            <ButtonComponent onClick={() => {
                                                                CommonService._staticData.openDialogPopup(setOpenAdditionalDialog, true);
                                                            }
                                                            } color={"download-button"}>
                                                                Add Additional Rate
                                                            </ButtonComponent>
                                                        </div>
                                                    </div>
                                                    <div className={'rate-component'}>
                                                        {values.rates.map((rate, index) => (
                                                            <div key={index} className={'list-item'}>
                                                                <div className={'d-flex'}>
                                                                    <label
                                                                        style={{
                                                                            minWidth: "200px",
                                                                            maxWidth: "200px"
                                                                        }}>
                                                                        {
                                                                            (rate.title)?.length > 20 ?
                                                                                <ToolTipComponent
                                                                                    tooltip={rate.title}
                                                                                    showArrow={true}
                                                                                    position={"top"}
                                                                                >
                                                                                    <div
                                                                                        className={"ellipses-for-table-data property-name"}>
                                                                                        {rate.title}
                                                                                    </div>
                                                                                </ToolTipComponent> :
                                                                                <div className="">
                                                                                    {rate.title || '-'}
                                                                                </div>
                                                                        }
                                                                    </label>
                                                                    <ToggleComponent
                                                                        onToggle={(checked) => {
                                                                            setFieldValue(`rates[${index}].enabled`, checked);

                                                                        }}
                                                                        defaultOn={rate.enabled}
                                                                    />

                                                                    {rate.enabled && (
                                                                        <div className={'mrg-left-35'}>
                                                                            <Field
                                                                                type="number"
                                                                                name={`rates[${index}].value`}
                                                                            >
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikInputComponent
                                                                                            className={"mrg-bottom-set-zero"}
                                                                                            label={''}
                                                                                            placeholder={'Rates'}
                                                                                            validationPattern={Patterns.NATURAL_INTEGER_DECIMALS}
                                                                                            type={"number"}
                                                                                            required={true}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                            {/*<ErrorMessage*/}
                                                                            {/*    name={`rates[${index}].value`}*/}
                                                                            {/*    component="div"*/}
                                                                            {/*/>*/}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </div>
                                                </>}
                                            </div>
                                            <div className={'add-agency-form-action-element'}>
                                                <div className={"mrg-left-20"}>
                                                </div>
                                                <ButtonComponent
                                                    disabled={isSubmitting || !isValid}
                                                    isLoading={isSubmitting}
                                                    type={'submit'}
                                                    className={'next-button-in-rate-form'}
                                                    style={{color: "#202937"}}
                                                    suffixIcon={<ImageConfig.NextButtonIcon/>}
                                                >
                                                    Next
                                                </ButtonComponent>
                                            </div>
                                        </div>
                                    </Form>);
                            }}
                        </Formik>}


                    {ratesInnerNavigation === "preference-form" &&
                        <>
                            <Formik
                                initialValues={preferenceInitialValues}
                                onSubmit={handlePreferenceRateSubmit}
                            >
                                {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                                    // eslint-disable-next-line react-hooks/rules-of-hooks
                                    useEffect(() => {
                                        validateForm();
                                    }, [validateForm, values]);
                                    return (
                                        <Form>
                                            <div className="add-component-content">
                                                <div
                                                    className={"add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'"}>
                                                    <div className={'form-top-header-text'}>Preferences
                                                    </div>
                                                    <Field name={'mode_of_contact'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={selectModeOfContactOption}
                                                                    displayWith={(option: any) => option.name}
                                                                    valueExtractor={(option: any) => option._id}
                                                                    label={'Preferred Mode of Contact'}
                                                                    placeholder={'Select Preferred Mode of Contact'}
                                                                  //  required={false}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>

                                                    <Field name={'bill_payment_frequency'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={SelectBillPaymentFrequency}
                                                                    displayWith={(option: any) => option.name}
                                                                    valueExtractor={(option: any) => option._id}
                                                                    label={'Bill Every (Weekly,Bi-weekly,Etc)'}
                                                                    placeholder={'Select Bill Type'}
                                                                   // required={false}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className={'add-agency-form-action-element'}>
                                                    <div className={"mrg-left-20"}>
                                                    </div>
                                                    <ButtonComponent
                                                        disabled={isSubmitting || !isValid}
                                                        isLoading={isSubmitting}
                                                        className={'next-button-in-rate-form'}

                                                        type={'submit'}
                                                        style={{color: "#202937"}}
                                                        suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                                    >
                                                        Next
                                                    </ButtonComponent>
                                                </div>
                                            </div>
                                        </Form>);
                                }}
                            </Formik>
                        </>
                    }
                    {
                        ratesInnerNavigation === "upload-contract" && <>
                            <Formik
                                validationSchema={validationSchema}
                                initialValues={initialValuesContract}
                                validateOnChange={false}
                                validateOnBlur={true}
                                enableReinitialize={true}
                                validateOnMount={true}
                                onSubmit={onFileUpload}
                            >
                                {({values, errors, validateForm, touched, isValid, setFieldValue, isSubmitting}) => {
                                    // eslint-disable-next-line react-hooks/rules-of-hooks
                                    useEffect(() => {
                                        validateForm();
                                    }, [validateForm, values]);
                                    return (
                                        <Form noValidate={true}>
                                            <div className="add-component-content">
                                                <div
                                                    className={"add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'"}>
                                                    <div className={'form-top-header-text'}>Upload Contract
                                                    </div>
                                                    {
                                                        (!values.contract) && <>
                                                            <FilePickerComponent maxFileCount={1}
                                                                                 height={'470px'}
                                                                                 id={"sc_upload_btn"}
                                                                                 onFilesDrop={(acceptedFiles:any, rejectedFiles:any) => {
                                                                                     if (acceptedFiles && acceptedFiles.length > 0) {
                                                                                         const file = acceptedFiles[0];
                                                                                         setFieldValue('contract', file);
                                                                                     }
                                                                                 }}
                                                                                 acceptedFileTypes={["pdf"]}
                                                                                 uploadDescription={"(upload only one file)"}
                                                                                 acceptedFilesText={"Only .pdf files are supported"}
                                                            />
                                                            {
                                                                (_.get(touched, "contract") && !!(_.get(errors, "contract"))) &&
                                                                <ErrorComponent
                                                                    errorText={(_.get(errors, "contract"))}/>
                                                            }
                                                        </>
                                                    }
                                                    <>
                                                        {
                                                            (values.contract) && <>
                                                                <div style={{height: "470px"}}>
                                                                    <div className={'d-flex'}>
                                                                        {/*<FilePreviewThumbnailComponent*/}
                                                                        {/*    file={values.contract}*/}
                                                                        {/*    onRemove={() => {*/}
                                                                        {/*        setFieldValue('contract', '');*/}
                                                                        {/*    }}*/}
                                                                        {/*/>*/}
                                                                        <div className={'flex-1'}>
                                                                            <LabelComponent title={'Uploaded Document'}/>
                                                                            <ContactPreviewFileComponent
                                                                                file={values?.contract} onRemove={() => {
                                                                                setFieldValue('contract', '');
                                                                            }}/>
                                                                        </div>
                                                                        <div className={'flex-1'}>
                                                                            <LabelComponent title={'Contract Expiry Date'}/>
                                                                            <Field name={'contract_expiry_date'}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikDatePickerComponent
                                                                                            // minDate={new Date()}
                                                                                            placeholder={'Select Date'}
                                                                                            required={true}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                    </>
                                                </div>

                                            </div>
                                            <div className={'add-agency-form-action-element'}>
                                                <div className={"mrg-left-20"}>
                                                </div>
                                                {/*<ButtonComponent*/}
                                                {/*    className={'back-button mrg-left-15'}*/}
                                                {/*    suffixIcon={<ImageConfig.BackArrow/>}*/}
                                                {/*    onClick={() => {*/}
                                                {/*        setRateInnerNavigation("preference-form");*/}
                                                {/*        setAgencyAddActiveStep(6);*/}
                                                {/*    }}*/}
                                                {/*>*/}
                                                {/*    Back*/}
                                                {/*</ButtonComponent>*/}
                                                {values?.contract &&
                                                    <div className={'center-element'}>
                                                        <ButtonComponent
                                                            disabled={isSubmitting || !isValid}
                                                            isLoading={isSubmitting}
                                                            type={'submit'}
                                                            style={{color: "#202937"}}
                                                            suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                                        >
                                                            Next
                                                        </ButtonComponent>
                                                    </div>
                                                }
                                                {!values.contract &&
                                                    <div className={'center-element'}>
                                                        <ButtonComponent
                                                            onClick={() => {
                                                                setFormScreenNavigation("onboarded-screen-completed");
                                                            }
                                                            }
                                                            style={{color: "#202937"}}
                                                            suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                                        >
                                                            Skip
                                                        </ButtonComponent>
                                                    </div>
                                                }
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </>
                    }

                </div>
            </div>
        </>
    );
};

export default FacilityHcpRateComponent;
