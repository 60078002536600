import SearchComponent from "../../../shared/components/search/SearchComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {CommonService} from "../../../shared/services";
import {APIConfig, ImageConfig, Misc} from "../../../constants";
import React, {useCallback, useEffect, useMemo, useState} from "react";
// import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
// import {ITableColumn} from "../../../shared/models/table.model";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import FavoriteHcpDetailsComponent from "../favorite-hcp-details/FavoriteHcpDetailsComponent";
import {IAPIResponseType} from "../../../shared/models/api.model";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import FormControl from "@mui/material/FormControl";
// import {Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
// import {MenuProps} from "../../../data";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import SearchCheckboxComponent from "../../../shared/components/search-checkbox/SearchCheckboxComponent";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../shared/components/antd-table-component/AntTableComponent";

const HcpFavoriteListComponent = () => {
    const [filterData, setFilterData] = useState<any>({
        search: '',
        sort: {},
    });
    const [hcpId, setHcpId] = useState<any>(null);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [selectedHcpTypeData, setSelectedHcpTypeData] = React.useState<Array<string>>([]);
    const {HcpTypesList} = useSelector((state: IRootReducerState) => state.meta);

    const handleDownloadHCPFavourite = useCallback(() => {
        setIsDownloading(true);
        const payload: any = {};
        const {status} = filterData;
        payload.status = [status];
        let apiCall: any = {};
        apiCall = CommonService._hcp.downloadHCPDataCSVAPICall(filterData);
        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsDownloading(false);
            }
        })
            .catch((error: any) => {
                CommonService._alert.showToast(error?.error || error?.message || 'Something is wrong!', "error");
                setIsDownloading(false);
            }).finally(() => {
        });
    }, [filterData]);


    const HcpManagementListColumn: TableColumnsType = useMemo(() => {
        return [
            {
                title: "Staff Name",
                width: 250,
                sorter: (a, b) =>
                    (a.hcp_details?.first_name + " " + a.hcp_details?.last_name).localeCompare(b.hcp_details?.first_name + " " + b.hcp_details?.last_name),
                render: (item: any) => {
                    return <div className={'hcp-favorite-details-wrapper'}>
                        <div className={'hcp-name-wrapper'}>
                            {
                                (item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Agency Name",
                width: 250,
                sorter: (a: any, b: any) =>
                    a?.agency_details?.name?.localeCompare(b?.agency_details?.name || '') || 0,
                render: (item: any) => {
                    return <div className={'agency-details-wrapper'}>
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.agency_details?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.agency_details?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.agency_details?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="agency-name">
                                        {item?.agency_details?.name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Staff Type",
                width:250,
                sorter: (a: any, b: any) =>
                    a?.hcp_type_details?.title?.localeCompare(b?.hcp_type_details?.title || '') || 0,
                render: (item: any) => {
                    return <div className={'agency-details-wrapper'}>
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.hcp_type_details?.title)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.hcp_type_details?.title}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.hcp_type_details?.title}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="agency-name">
                                        {item?.hcp_type_details?.title || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "View Details",
                align: 'center',
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    return <LinkComponent onClick={() => {
                        setHcpId(item?.hcp_details._id);
                        CommonService._staticData.openDialogPopup(setOpenDrawer, true);
                    }}>
                        <ImageConfig.ArrowRightOutlinedIcon style={{color: "#183258"}}/>
                    </LinkComponent>;
                }
            }
        ];
    }, []);

    const handleHcpTypeSelection = useCallback((selectedOptions:any) => {
       setSelectedHcpTypeData(selectedOptions)
    }, []);

    useEffect(() => {
        const hcpTypeIdsArray = selectedHcpTypeData.map((item: any) => item?.code);
        if (selectedHcpTypeData?.length > 0) {
            setFilterData({
                ...filterData,
                hcp_types: hcpTypeIdsArray,
            });
        } else {
            delete filterData?.hcp_types;
            setFilterData({
                ...filterData
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedHcpTypeData]);

    return (
        <>
            <DrawerComponent onClose={() => {
                CommonService._staticData.openDialogPopup(setOpenDrawer, false);
            }} isOpen={openDrawer}>
                <FavoriteHcpDetailsComponent setOpenDrawer={setOpenDrawer} hcpId={hcpId} setFilterData={setFilterData}
                                             filterData={filterData}/>
            </DrawerComponent>
            <div className={'hcp-favorite-list-component header-screen mrg-top-25 mrg-right-30 mrg-left-25'}>
                <div className="d-flex align-items-center justify-content-space-between">
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.FavoriteModuleHeaderIcon/>
                            </div>
                            <div className="list-header-text-property">Favorite Staff</div>
                        </div>
                    <div className="d-flex">
                        <div className={"mrg-top-20 mrg-right-10"}>
                            <ButtonComponent color={'download-button'} variant={"contained"}
                                             isLoading={isDownloading}
                                             onClick={handleDownloadHCPFavourite}>
                                Download CSV
                            </ButtonComponent>
                        </div>
                        <div style={{marginTop:"13px"}}>
                            <FormControl sx={{m: 1,width: 245}}>
                                <SearchCheckboxComponent
                                    options={HcpTypesList}
                                    placeholder={"Select Staff Type"}
                                    noDataText={"No Staff Type"}
                                    selectedOptions={selectedHcpTypeData}
                                    onChange={handleHcpTypeSelection}
                                />
                            </FormControl>
                        </div>
                        <div className={"mrg-top-20"}>
                            <SearchComponent
                                placeholder="Search Staff"
                                value={filterData.search}
                                onSearchChange={(value) => {
                                    setFilterData({...filterData, search: value});
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={'agency-list-component mrg-top-10 '}>
                    <>
                        {/*<TableWrapperComponent url={APIConfig.HCP_FAVORITE_MANAGEMENT_LIST.URL}*/}
                        {/*                       method={APIConfig.HCP_FAVORITE_MANAGEMENT_LIST.METHOD}*/}
                        {/*                       isPaginated={true}*/}
                        {/*                       extraPayload={filterData}*/}
                        {/*                       noDataText={filterData.search ? 'Oops! It seems like there are no Staff available for the name you have searched.' :*/}
                        {/*                           filterData.hcp_types ? 'Oops! It seems like there are no Staff available for the selected staff.' :*/}
                        {/*                           'No Staffs Data Found.'}*/}
                        {/*                       columns={HcpManagementListColumn}*/}
                        {/*/>*/}
                        <AntTableComponent
                            url={APIConfig.HCP_FAVORITE_MANAGEMENT_LIST.URL}
                            method={APIConfig.HCP_FAVORITE_MANAGEMENT_LIST.METHOD}
                            isRowSelection={false}
                            extraPayload={filterData}
                            noDataText={filterData.search ? 'Oops! It seems like there are no Staff available for the name you have searched.' :
                                filterData.hcp_types ? 'Oops! It seems like there are no Staff available for the selected staff.' :
                                    'No Staffs Data Found.'}
                            columns={HcpManagementListColumn}
                        />
                    </>
                </div>
            </div>
        </>
    );
};

export default HcpFavoriteListComponent;
