import './OpenShiftViewDetailsCreationComponent.scss';
import {APIConfig, ImageConfig, Misc} from '../../../../constants';
import {CommonService} from '../../../../shared/services';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {IAPIResponseType} from '../../../../shared/models/api.model';
import {shiftTypeLayoutRepresentation} from '../../../../data';
import DataLabelValueComponent from '../../../../shared/components/data-label-value/DataLabelValueComponent';
import DialogComponent from '../../../../shared/components/dialog-component/DialogComponent';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent,
} from '../../../../shared/components/tabs/TabsComponent';
import ToolTipComponent from '../../../../shared/components/tool-tip/ToolTipComponent';
import LinkComponent from '../../../../shared/components/link/LinkComponent';
import HCPDetailsComponent from './hcp-details-in-dialog/HCPDetailsComponent';
import LoaderComponent from '../../../../shared/components/loader/LoaderComponent';
import RejectCancelledCommonComponent
    from '../../../../shared/components/reject-cancellation/RejectCancelledCommonComponent';
import {Tooltip} from '@mui/material';
import ApproveApplicationComponent
    from "../../../shift-requirement/application-approve-component/ApproveApplicationComponent";
import ContractTravelApproveApplicationComponent
    from "../../../shift-requirement/contract-travel-approve-component/ContarctTravelApproveComponent";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../../shared/components/antd-table-component/AntTableComponent";

interface OpenShiftViewDetailsCreationComponentProps {
    setOpenOpenShiftRequirementDialog?: any;
    requirementId?: any;
    setSchedulingListFilterState?: any;
    schedulingListFilterState?: any;
}

const OpenShiftViewDetailsCreationComponent = (
    props: OpenShiftViewDetailsCreationComponentProps,
) => {
    const {
        setOpenOpenShiftRequirementDialog,
        requirementId,
        setSchedulingListFilterState,
        schedulingListFilterState,
    } = props;
    const [shiftRequirementDetails, setShiftRequirementDetails] =
        useState<any>(null);
    const [isShiftDetailsLoaded, setIsShiftDetailsLoaded] = useState<any>(false);
    const [cancelConformation, setCancelConformation] = useState<boolean>(false);
    const [cancellationReason, setCancellationReason] = useState<any>(null);
    const [loadingCancellationReason, setLoadingCancellationReason] =
        useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<any>('pending');
    const [filterData, setFilterData] = useState<any>({
        search: '',
        status: 'pending',
        // sort:{},
    });
    // const [selectedAgencyForHcp, setSelectedAgency] = useState<any>({
    //     selectedAgency: null,
    //     // sort:{},
    // });
    //const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    // const {agencyList} = useSelector((state: IRootReducerState) => state.staticData);
    const [isRejectButtonLoading, setIsRejectButtonLoading] =
        useState<boolean>(false);
    const [shiftCancellationReason, setShiftCancellationReason] =
        useState<any>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedApplicationId, setSelectedApplicationId] =
        useState<string>('');
    const [loadingStates, setLoadingStates] = useState<any>([]);
    //  const [hcpListRequirementBased, setHcpListRequirementBased] = useState<any>(null);
    const [hcpDetails, setHcpDetails] = useState<any>(null);
    const [openHcpDetailsDrawer, setOpenHcpDetailsDrawer] =
        useState<boolean>(false);

    const [isApproveBoxOpen, setIsApproveBoxOpen] = useState<boolean>(false);
    const [isContractApprovalBoxOpen, setIsContractApprovalBoxOpen] = useState<boolean>(false);
    const [applicationShiftDetails, setApplicationShiftDetails] = useState<any>([]);


    const getShiftRequirementDetails = useCallback(() => {
        const payload = {};
        CommonService._shift_management
            .getShiftDetailsAPICall(requirementId, payload)
            .then((response: IAPIResponseType<any>) => {
                console.log(response.data);
                setShiftRequirementDetails(response.data);
                setIsShiftDetailsLoaded(true);
            })
            .catch((error: any) => {
                setIsShiftDetailsLoaded(false);
            })
            .finally(() => {
            });
    }, [requirementId]);

    useEffect(() => {
        getShiftRequirementDetails();
    }, [getShiftRequirementDetails]);

    const cancelShiftDetails = useCallback(
        (requirementId: any, reason: any) => {
            setCancelConformation(true);
            const payload = {
                reason: reason,
            };
            setLoadingCancellationReason(true);
            // console.log(schedulingListFilterState);
            CommonService._shift_management
                .cancelShiftDetails(requirementId, payload)
                .then((response: IAPIResponseType<any>) => {
                    console.log(response);
                    setLoadingCancellationReason(false);
                    CommonService._alert.showToast(response?.message, 'success');
                    setCancelConformation(false);
                    setCancellationReason(null);
                    CommonService._staticData.openDialogPopup(
                        setOpenOpenShiftRequirementDialog,
                        false,
                    );
                    setSchedulingListFilterState({...schedulingListFilterState});
                })
                .catch((error: any) => {
                    CommonService._alert.showToast(error?.message, 'error');
                });
        },
        [
            schedulingListFilterState,
            setOpenOpenShiftRequirementDialog,
            setSchedulingListFilterState,
        ],
    );

    const approveHcpApplication = useCallback(
        (application_id: any, index: any) => {
            const newLoadingStates = [...loadingStates];
            newLoadingStates[index] = true;
            setLoadingStates(newLoadingStates);
            const payload = {};
            CommonService._shift_management
                .approveShiftRequirementApplicationAPICall(application_id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'success',
                    );
                    const newLoadingStates = [...loadingStates];
                    newLoadingStates[index] = false;
                    setLoadingStates(newLoadingStates);
                    setActiveTab('approved');
                    setFilterData({...filterData, status: 'approved'});
                    setSchedulingListFilterState({...schedulingListFilterState});
                })
                .catch((error: any) => {
                    const newLoadingStates = [...loadingStates];
                    newLoadingStates[index] = false;
                    setLoadingStates(newLoadingStates);
                    CommonService._alert.showToast(error?.message, 'error');
                });
        },
        [
            filterData,
            loadingStates,
            schedulingListFilterState,
            setSchedulingListFilterState,
        ],
    );

    const rejectHcpApplication = useCallback(() => {
        setIsRejectButtonLoading(true);
        const payload: any = {};
        let apiCall: any = {};
        payload.rejected_reason = shiftCancellationReason;
        apiCall =
            CommonService._shift_management.rejectShiftRequirementApplicationAPICall(
                selectedApplicationId,
                payload,
            );
        apiCall
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(
                    response[Misc.API_RESPONSE_MESSAGE_KEY],
                    'success',
                );
                CommonService._staticData.openDialogPopup(setOpenDialog, false);
                setIsRejectButtonLoading(false);
                setActiveTab('rejected');
                setFilterData({...filterData, status: 'rejected'});
                setSchedulingListFilterState({...schedulingListFilterState});
            })
            .catch((error: any) => {
                CommonService._alert.showToast('Something went wrong!!',"error")
                setIsRejectButtonLoading(false);
            })
            .finally(() => {
            });
    }, [
        shiftCancellationReason,
        selectedApplicationId,
        filterData,
        setSchedulingListFilterState,
        schedulingListFilterState,
    ]);

    const ShiftsListColumn:TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Requested On',
                width: 120,
                fixed: 'left',
                render: (item: any) => {
                    if (item?.updated_at) {
                        return (
                            <>
                                {CommonService.convertDateFormat2(
                                    item?.updated_at,
                                    'MM-DD-YYYY',
                                )}
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                },
            },
            {
                title: 'Staff Name',
                width: 250,
                align: 'left',
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)
                                    ?.length > 15 ? (
                                    <ToolTipComponent
                                        tooltip={
                                            item?.hcp_details?.first_name +
                                            item?.hcp_details?.last_name
                                        }
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.hcp_details?.first_name +
                                                ' ' +
                                                item?.hcp_details?.last_name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.hcp_details?.first_name +
                                            ' ' +
                                            item?.hcp_details?.last_name || 'N/A'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Agency Name',
                width: 250,
                align: 'left',
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.agency_details?.name?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.agency_details?.name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.agency_details?.name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.agency_details?.name || 'N/A'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Differential $',
                width: 100,
                render: (item: any) => {
                    if (item?.differential) {
                        return (
                            <>
                                <div onClick={() => {
                                }} style={{color: '#E08519'}}>
                                    ${item?.differential}
                                </div>
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                },
            },
            {
                title: '',
                width: 120,
                fixed: 'right',
                render: (_:any,item: any, index: any) => {
                    return (
                        <>
                            {(activeTab === 'pending' || activeTab === 'rejected') && (
                                <ButtonComponent
                                    isLoading={loadingStates[index]}
                                    onClick={() => {
                                        if (shiftRequirementDetails?.requirementType === "Home Health") {
                                            setApplicationShiftDetails(item)
                                            setIsApproveBoxOpen(true);
                                        } else if (shiftRequirementDetails?.requirementType === "Contract Travel") {
                                            setApplicationShiftDetails(item);
                                            setIsContractApprovalBoxOpen(true)
                                        } else {
                                            approveHcpApplication(item?._id, index);
                                        }
                                    }}
                                    style={{
                                        backgroundColor: 'rgba(13, 185, 216, 0.10)',
                                        color: '#0DB9D8',
                                        boxShadow: 'none',
                                        border: '1px solid #0DB9D8',
                                    }}
                                >
                                    Approve
                                </ButtonComponent>
                            )}
                        </>
                    );
                },
            },
            {
                title: '',
                width: 150,
                render: (item: any) => {
                    return (
                        <>
                            {activeTab === 'pending' && (
                                <ButtonComponent
                                    onClick={() => {
                                        setSelectedApplicationId(item?._id);
                                        CommonService._staticData.openDialogPopup(
                                            setOpenDialog,
                                            true,
                                        );
                                    }}
                                    style={{
                                        backgroundColor: '#FFF4F4',
                                        color: '#DB5656',
                                        boxShadow: 'none',
                                        border: '1px solid #DB5656',
                                    }}
                                >
                                    Reject
                                </ButtonComponent>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'View Details',
                align: 'center',
                width: 130,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return (
                            <div>
                                <LinkComponent
                                    onClick={() => {
                                        setHcpDetails(item);
                                        CommonService._staticData.openDialogPopup(
                                            setOpenHcpDetailsDrawer,
                                            true,
                                        );
                                    }}
                                >
                                    <ImageConfig.ArrowRightOutlinedIcon/>
                                </LinkComponent>
                            </div>
                        );
                    }
                },
            },
        ];
    }, [activeTab, approveHcpApplication, loadingStates, shiftRequirementDetails]);

    const address =
        [
            shiftRequirementDetails?.hospice_details?.apt,
            shiftRequirementDetails?.hospice_details?.street,
            shiftRequirementDetails?.hospice_details?.city,
            shiftRequirementDetails?.hospice_details?.state,
            shiftRequirementDetails?.hospice_details?.zipcode,
        ]
            .filter(Boolean)
            .join(', ') || 'N/A';

    return (
        <>
            <DialogComponent cancel={() => {
                setIsApproveBoxOpen(false)
            }} open={isApproveBoxOpen} maxWidth={'xl'}>
                <ApproveApplicationComponent
                    setActiveTab={setActiveTab}
                    setSchedulerOpenFilterData={setFilterData}
                    schedulerOpenFilterData={filterData}
                    status={"scheduler-open-details"}
                    setIsApproveBoxOpen={setIsApproveBoxOpen}
                    selectedApplicationForApproval={[applicationShiftDetails]}
                    getShiftRequirementDetails={getShiftRequirementDetails}
                    setSchedulingListFilterState={setSchedulingListFilterState}
                    schedulingListFilterState={schedulingListFilterState}
                    visitPatientType={shiftRequirementDetails?.hospice_details?.visit_patient_time}
                />
            </DialogComponent>
            <DialogComponent cancel={() => {
                setIsContractApprovalBoxOpen(false)
            }} open={isContractApprovalBoxOpen} maxWidth={'xl'}>
                <ContractTravelApproveApplicationComponent
                    setActiveTab={setActiveTab}
                    setSchedulerOpenFilterData={setFilterData}
                    schedulerOpenFilterData={filterData}
                    status={"scheduler-open-details"}
                    setIsContractApprovalBoxOpen={setIsContractApprovalBoxOpen}
                    selectedApplicationForApproval={[applicationShiftDetails]}
                    getShiftRequirementDetails={getShiftRequirementDetails}
                    setSchedulingListFilterState={setSchedulingListFilterState}
                    schedulingListFilterState={schedulingListFilterState}
                />
            </DialogComponent>
            <DialogComponent
                cancel={() => {
                    CommonService._staticData.openDialogPopup(setOpenDialog, false);
                }}
                open={openDialog}
            >
                <RejectCancelledCommonComponent
                    setOpenDialog={setOpenDialog}
                    cancellationType={'Reject Shift'}
                    setShiftCancellationReason={setShiftCancellationReason}
                    onSubmit={rejectHcpApplication}
                    shiftCancellationReason={shiftCancellationReason}
                    isLoading={isRejectButtonLoading}
                />
            </DialogComponent>
            <DialogComponent
                cancel={() => {
                    setCancelConformation(false);
                }}
                open={cancelConformation}
            >
                <RejectCancelledCommonComponent
                    setOpenDialog={setCancelConformation}
                    cancellationType={'Cancel Shift'}
                    setShiftCancellationReason={setCancellationReason}
                    onSubmit={() => {
                        cancelShiftDetails(requirementId, cancellationReason);
                    }}
                    shiftCancellationReason={cancellationReason}
                    isLoading={loadingCancellationReason}
                />
            </DialogComponent>

            <DialogComponent
                maxWidth={'sm'}
                cancel={() => {
                    CommonService._staticData.openDialogPopup(
                        setOpenHcpDetailsDrawer,
                        false,
                    );
                }}
                open={openHcpDetailsDrawer}
            >
                <HCPDetailsComponent
                    setOpenDrawer={setOpenHcpDetailsDrawer}
                    shiftHcpDetails={hcpDetails}
                />
            </DialogComponent>
            {/*<div className={'open-shift-details-creation-month-day-view-component'}>*/}
            {/*    <div*/}
            {/*        className={*/}
            {/*            'open-shift-details-creation-month-day-view-header d-flex justify-content-space-between align-items-center'*/}
            {/*        }*/}
            {/*    >*/}
            {/*        <div>Open Shift Requirement</div>*/}
            {/*        <div className={'d-flex'}>*/}
            {/*            {shiftRequirementDetails?.status !== 'cancelled' && (*/}
            {/*                <div style={{marginRight: '10px'}}>*/}
            {/*                    <ButtonComponent*/}
            {/*                        onClick={() => setCancelConformation(true)}*/}
            {/*                        color={'cancel-requirement '}*/}
            {/*                        className={'cancel-shift-button'}*/}
            {/*                        prefixIcon={<ImageConfig.CancelIconRed/>}*/}
            {/*                    >*/}
            {/*                        Cancel Shift Requirement*/}
            {/*                    </ButtonComponent>*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*            <div*/}
            {/*                onClick={() => {*/}
            {/*                    CommonService._staticData.openDialogPopup(*/}
            {/*                        setOpenOpenShiftRequirementDialog,*/}
            {/*                        false,*/}
            {/*                    );*/}
            {/*                }}*/}
            {/*                className={'icon-button'}*/}
            {/*            >*/}
            {/*                <ImageConfig.CrossIcon/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={'pdd-20 d-flex'}>*/}
            {/*        <div className={'flex-1 open-requirement-shift-details'}>*/}
            {/*            {!isShiftDetailsLoaded && (*/}
            {/*                <div className={'center-element'}>*/}
            {/*                    <LoaderComponent type={'spinner'}/>*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*            {isShiftDetailsLoaded && shiftRequirementDetails && (*/}
            {/*                <>*/}
            {/*                    <div className={'shift-requirement-day-week-details-wrapper'}>*/}
            {/*                        <div*/}
            {/*                            className={*/}
            {/*                                'shift-requirement-week-day-header mrg-bottom-15 form-header-text '*/}
            {/*                            }*/}
            {/*                        >*/}
            {/*                            Shift Requirement Details*/}
            {/*                        </div>*/}
            {/*                        <div className={'shift-requirement-week-day-details-card'}>*/}
            {/*                            <div className={'pdd-20'}>*/}
            {/*                                <div*/}
            {/*                                    className={*/}
            {/*                                        'd-flex justify-content-space-between align-items-center mrg-bottom-15'*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <div className={'header-text-font'}>Shift Details</div>*/}
            {/*                                    <div>*/}
            {/*                                        <div*/}
            {/*                                            className={`requirement-holder-daily-monthly-view ${*/}
            {/*                                                shiftTypeLayoutRepresentation.find(*/}
            {/*                                                    (shiftType) =>*/}
            {/*                                                        shiftType.code ===*/}
            {/*                                                        shiftRequirementDetails.shift_type,*/}
            {/*                                                )?.classname*/}
            {/*                                            } d-flex align-items-center`}*/}
            {/*                                        >*/}
            {/*                                            {shiftRequirementDetails.shift_type + ' Shift'}*/}
            {/*                                            &nbsp;&nbsp;*/}
            {/*                                            {*/}
            {/*                                                shiftTypeLayoutRepresentation.find(*/}
            {/*                                                    (shiftTypeObj) =>*/}
            {/*                                                        shiftTypeObj.code ===*/}
            {/*                                                        shiftRequirementDetails.shift_type,*/}
            {/*                                                )?.icon*/}
            {/*                                            }*/}
            {/*                                        </div>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                                {shiftRequirementDetails?.requirementType !==*/}
            {/*                                    'Home Health' && (*/}
            {/*                                        <>*/}
            {/*                                            <div className={'d-flex'}>*/}
            {/*                                                <div className={'mrg-right-20 mrg-bottom-20'}>*/}
            {/*                                                    <ImageConfig.ClockIconSmall/>*/}
            {/*                                                </div>*/}
            {/*                                                <div className={'form-minor-text'}>*/}
            {/*                                                    {CommonService?.convertMinutesToTime(*/}
            {/*                                                            shiftRequirementDetails?.shift_timings*/}
            {/*                                                                ?.start_time,*/}
            {/*                                                        ) +*/}
            {/*                                                        '-' +*/}
            {/*                                                        CommonService?.convertMinutesToTime(*/}
            {/*                                                            shiftRequirementDetails?.shift_timings*/}
            {/*                                                                ?.end_time,*/}
            {/*                                                        )}*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'d-flex'}>*/}
            {/*                                                <div className={'mrg-right-20 mrg-bottom-20'}>*/}
            {/*                                                    <ImageConfig.CalenderIconSmall/>*/}
            {/*                                                </div>*/}
            {/*                                                <div className={'form-minor-text'}>*/}
            {/*                                                    {CommonService.convertDateFormat2(*/}
            {/*                                                        shiftRequirementDetails?.shift_date,*/}
            {/*                                                        'MM-DD-YYYY',*/}
            {/*                                                    ) || '-'}*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'d-flex'}>*/}
            {/*                                                <div className={'mrg-right-20 mrg-bottom-20'}>*/}
            {/*                                                    <ImageConfig.HcpType/>*/}
            {/*                                                </div>*/}
            {/*                                                <div className={'form-minor-text'}>*/}
            {/*                                                    {shiftRequirementDetails?.hcp_type_details*/}
            {/*                                                        ?.data[0]?.title || '-'}{' '}*/}
            {/*                                                    (*/}
            {/*                                                    {*/}
            {/*                                                        shiftRequirementDetails?.hcp_speciality_details*/}
            {/*                                                            ?.title*/}
            {/*                                                    }*/}
            {/*                                                    )*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                        </>*/}
            {/*                                    )}*/}
            {/*                                {shiftRequirementDetails?.requirementType ===*/}
            {/*                                    'Home Health' && (*/}
            {/*                                        <>*/}
            {/*                                            <div className={'ts-col-lg-12 mrg-top-10'}>*/}
            {/*                                                <div className={'d-flex'}>*/}
            {/*                                                    <div className={'mrg-right-15'}>*/}
            {/*                                                        <ImageConfig.FacilityIcon/>*/}
            {/*                                                    </div>*/}
            {/*                                                    <div>*/}
            {/*                                                        <Tooltip*/}
            {/*                                                            title={*/}
            {/*                                                                shiftRequirementDetails?.hospice_details*/}
            {/*                                                                    ?.first_name +*/}
            {/*                                                                ' ' +*/}
            {/*                                                                shiftRequirementDetails?.hospice_details*/}
            {/*                                                                    ?.last_name || '-'*/}
            {/*                                                            }*/}
            {/*                                                            placement={'top'}*/}
            {/*                                                        >*/}
            {/*                                                            <div*/}
            {/*                                                                className={'facility-name form-minor-text'}*/}
            {/*                                                            >*/}
            {/*                                                                {' '}*/}
            {/*                                                                {shiftRequirementDetails?.hospice_details*/}
            {/*                                                                        ?.first_name +*/}
            {/*                                                                    ' ' +*/}
            {/*                                                                    shiftRequirementDetails?.hospice_details*/}
            {/*                                                                        ?.last_name || '-'}*/}
            {/*                                                            </div>*/}
            {/*                                                        </Tooltip>*/}
            {/*                                                        <div>*/}
            {/*                                                            ({' '}*/}
            {/*                                                            {*/}
            {/*                                                                shiftRequirementDetails?.hospice_details*/}
            {/*                                                                    ?.gender*/}
            {/*                                                            }{' '}*/}
            {/*                                                            {*/}
            {/*                                                                shiftRequirementDetails?.hospice_details*/}
            {/*                                                                    ?.age*/}
            {/*                                                            }*/}
            {/*                                                            )*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-12 mrg-top-10'}>*/}
            {/*                                                <div className={'d-flex'}>*/}
            {/*                                                    <div className={'mrg-right-15'}>*/}
            {/*                                                        <ImageConfig.ClockIconSmall/>*/}
            {/*                                                    </div>*/}
            {/*                                                    <div className={'form-minor-text'}>*/}
            {/*                                                        {CommonService?.convertMinutesToTime(*/}
            {/*                                                                shiftRequirementDetails?.shift_timings*/}
            {/*                                                                    ?.start_time,*/}
            {/*                                                            ) +*/}
            {/*                                                            '-' +*/}
            {/*                                                            CommonService?.convertMinutesToTime(*/}
            {/*                                                                shiftRequirementDetails?.shift_timings*/}
            {/*                                                                    ?.end_time,*/}
            {/*                                                            )}*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-12 mrg-top-10'}>*/}
            {/*                                                <div className={'d-flex'}>*/}
            {/*                                                    <div className={'mrg-right-15'}>*/}
            {/*                                                        <ImageConfig.CalenderIconSmall/>*/}
            {/*                                                    </div>*/}
            {/*                                                    <div className={'form-minor-text'}>*/}
            {/*                                                        {CommonService.convertDateFormat2(*/}
            {/*                                                            shiftRequirementDetails?.shift_date,*/}
            {/*                                                            'MM-DD-YYYY',*/}
            {/*                                                        ) || '-'}*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-12 mrg-top-10'}>*/}
            {/*                                                <div className={'d-flex'}>*/}
            {/*                                                    <div className={'mrg-right-15'}>*/}
            {/*                                                        <ImageConfig.HcpType/>*/}
            {/*                                                    </div>*/}
            {/*                                                    <div className={'form-minor-text'}>*/}
            {/*                                                        {shiftRequirementDetails?.hcp_type_details*/}
            {/*                                                            ?.data[0]?.title || '-'}{' '}*/}
            {/*                                                        (Req For :{' '}*/}
            {/*                                                        {shiftRequirementDetails?.gender_preference}{' '}*/}
            {/*                                                        staff)*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-12 mrg-top-10'}>*/}
            {/*                                                <div className={'d-flex'}>*/}
            {/*                                                    <div className={'mrg-right-15 form-minor-text'}>*/}
            {/*                                                        <ImageConfig.LocationIcon/>*/}
            {/*                                                    </div>*/}
            {/*                                                    <div className={'form-minor-text'}>{address}</div>*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                        </>*/}
            {/*                                    )}*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className={'mrg-top-10 mrg-bottom-10 pdd-top-20'}>*/}
            {/*                            <div*/}
            {/*                                className={*/}
            {/*                                    'mrg-left-10 font-size-14 font-weight-bold mrg-bottom-15'*/}
            {/*                                }*/}
            {/*                            >*/}
            {/*                                Shift Created For*/}
            {/*                            </div>*/}
            {/*                            <div className={'d-flex'}>*/}
            {/*                                {shiftRequirementDetails?.send_to?.is_internal_hcp && (*/}
            {/*                                    <div*/}
            {/*                                        className={*/}
            {/*                                            'open-requirement-shift-for-chip mrg-left-10 '*/}
            {/*                                        }*/}
            {/*                                    >*/}
            {/*                                        Internal Staff*/}
            {/*                                    </div>*/}
            {/*                                )}*/}
            {/*                                {shiftRequirementDetails?.send_to?.is_float && (*/}
            {/*                                    <div*/}
            {/*                                        className={*/}
            {/*                                            'open-requirement-shift-for-chip mrg-left-10'*/}
            {/*                                        }*/}
            {/*                                    >*/}
            {/*                                        Float Staff*/}
            {/*                                    </div>*/}
            {/*                                )}*/}
            {/*                                {shiftRequirementDetails?.send_to?.specific_agencies && (*/}
            {/*                                    <div*/}
            {/*                                        className={*/}
            {/*                                            'open-requirement-shift-for-chip mrg-left-10'*/}
            {/*                                        }*/}
            {/*                                    >*/}
            {/*                                        Agencies*/}
            {/*                                    </div>*/}
            {/*                                )}*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        {shiftRequirementDetails?.requirementType ===*/}
            {/*                            'Home Health' && (*/}
            {/*                                <>*/}
            {/*                                    <div className={'ts-row mrg-top-15 mrg-left-10'}>*/}
            {/*                                        <div className={'ts-col-lg-5'}>*/}
            {/*                                            <DataLabelValueComponent label={'Phone Number'}>*/}
            {/*                                                {shiftRequirementDetails?.hospice_details*/}
            {/*                                                    ?.phone_number || '-'}*/}
            {/*                                            </DataLabelValueComponent>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-col-lg-7'}>*/}
            {/*                                            <DataLabelValueComponent label={'Email Address'}>*/}
            {/*                                                {shiftRequirementDetails?.hospice_details*/}
            {/*                                                    ?.email_address || '-'}*/}
            {/*                                            </DataLabelValueComponent>*/}
            {/*                                        </div>*/}
            {/*                                    </div>*/}
            {/*                                    <div className={'ts-row  mrg-left-10'}>*/}
            {/*                                        <div className={'ts-col-lg-7'}>*/}
            {/*                                            <DataLabelValueComponent label={'Staff Speciality'}>*/}
            {/*                                                {shiftRequirementDetails?.hcp_speciality_details*/}
            {/*                                                    ?.title || '-'}*/}
            {/*                                            </DataLabelValueComponent>*/}
            {/*                                        </div>*/}
            {/*                                        /!*<div className={'ts-col-lg-5'}>*!/*/}
            {/*                                        /!*    <DataLabelValueComponent label={'Visit Patient By'}>*!/*/}
            {/*                                        /!*        {shiftRequirementDetails?.visit_patient_by || '-'}*!/*/}
            {/*                                        /!*    </DataLabelValueComponent>*!/*/}
            {/*                                        /!*</div>*!/*/}
            {/*                                    </div>*/}
            {/*                                </>*/}
            {/*                            )}*/}
            {/*                        <div*/}
            {/*                            className={*/}
            {/*                                'mrg-bottom-15 font-size-14 font-weight-bold mrg-top-25 mrg-left-10'*/}
            {/*                            }*/}
            {/*                        >*/}
            {/*                            Additional Shift Details*/}
            {/*                        </div>*/}
            {/*                        <div*/}
            {/*                            className={*/}
            {/*                                'pdd-right-15 pdd-left-15 pdd-top-20 pdd-bottom-20'*/}
            {/*                            }*/}
            {/*                        >*/}
            {/*                            {shiftRequirementDetails?.requirementType ===*/}
            {/*                                'Open Shift' && (*/}
            {/*                                    <>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-5'}>*/}
            {/*                                                <DataLabelValueComponent label={'Open Positions'}>*/}
            {/*                                                    {shiftRequirementDetails?.required_hcp_count || 0}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-7'}>*/}
            {/*                                                <DataLabelValueComponent label={'Shift Rate'}>*/}
            {/*                                                    $ {shiftRequirementDetails?.shift_rate || 0}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-5'}>*/}
            {/*                                                <DataLabelValueComponent label={'Wing'}>*/}
            {/*                                                    {shiftRequirementDetails?.facility_wing_details*/}
            {/*                                                        ?.data?.name || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-7'}>*/}
            {/*                                                <DataLabelValueComponent label={'Weekend Rate'}>*/}
            {/*                                                    $ {shiftRequirementDetails?.weekend_rate || 0}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-5'}>*/}
            {/*                                                <DataLabelValueComponent label={'Differential'}>*/}
            {/*                                                    {shiftRequirementDetails?.differential_rate ||*/}
            {/*                                                        '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-7'}>*/}
            {/*                                                <DataLabelValueComponent label={'OT Pay'}>*/}
            {/*                                                    {shiftRequirementDetails?.ot_pay || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-5'}>*/}
            {/*                                                <DataLabelValueComponent label={'Rush Rate'}>*/}
            {/*                                                    $ {shiftRequirementDetails?.rush_rate || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-7'}>*/}
            {/*                                                <DataLabelValueComponent label={'PM Diff'}>*/}
            {/*                                                    $ {shiftRequirementDetails?.pm_rate || 0}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-5'}>*/}
            {/*                                                <DataLabelValueComponent label={'NOC Rate'}>*/}
            {/*                                                    $ {shiftRequirementDetails?.noc_rate || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-12'}>*/}
            {/*                                                <DataLabelValueComponent label={'Notes'}>*/}
            {/*                                                    {shiftRequirementDetails?.note || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        {shiftRequirementDetails?.requirementGalleryPics &&*/}
            {/*                                            shiftRequirementDetails?.requirementGalleryPics*/}
            {/*                                                ?.length > 0 && (*/}
            {/*                                                <div className={'ts-row'}>*/}
            {/*                                                    <div className={'ts-col-lg-12'}>*/}
            {/*                                                        <DataLabelValueComponent label={'Documents '}>*/}
            {/*                                                            <div>*/}
            {/*                                                                {shiftRequirementDetails?.requirementGalleryPics &&*/}
            {/*                                                                    shiftRequirementDetails?.requirementGalleryPics?.map(*/}
            {/*                                                                        (file: any, index: any) => {*/}
            {/*                                                                            return (*/}
            {/*                                                                                <>*/}
            {/*                                                                                    <div*/}
            {/*                                                                                        className={*/}
            {/*                                                                                            'file-holder-wrapper mrg-bottom-10 pdd-10'*/}
            {/*                                                                                        }*/}
            {/*                                                                                    >*/}
            {/*                                                                                        <div*/}
            {/*                                                                                            className={*/}
            {/*                                                                                                'underline-text icon-button'*/}
            {/*                                                                                            }*/}
            {/*                                                                                            onClick={() => {*/}
            {/*                                                                                                const link =*/}
            {/*                                                                                                    document.createElement(*/}
            {/*                                                                                                        'a',*/}
            {/*                                                                                                    );*/}
            {/*                                                                                                link.href = file?.url;*/}
            {/*                                                                                                link.setAttribute(*/}
            {/*                                                                                                    'download',*/}
            {/*                                                                                                    '',*/}
            {/*                                                                                                );*/}

            {/*                                                                                                document.body.appendChild(*/}
            {/*                                                                                                    link,*/}
            {/*                                                                                                );*/}
            {/*                                                                                                link.click();*/}

            {/*                                                                                                // Cleanup*/}
            {/*                                                                                                document.body.removeChild(*/}
            {/*                                                                                                    link,*/}
            {/*                                                                                                );*/}
            {/*                                                                                                // window.open(file?.url);*/}
            {/*                                                                                            }}*/}
            {/*                                                                                        >*/}
            {/*                                                                                            {' '}*/}
            {/*                                                                                            Document {index + 1}*/}
            {/*                                                                                        </div>*/}
            {/*                                                                                    </div>*/}
            {/*                                                                                </>*/}
            {/*                                                                            );*/}
            {/*                                                                        },*/}
            {/*                                                                    )}*/}
            {/*                                                            </div>*/}
            {/*                                                        </DataLabelValueComponent>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            )}*/}
            {/*                                    </>*/}
            {/*                                )}*/}
            {/*                            {shiftRequirementDetails?.requirementType ===*/}
            {/*                                'Home Health' && (*/}
            {/*                                    <>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-12'}>*/}
            {/*                                                <DataLabelValueComponent label={'Open Positions'}>*/}
            {/*                                                    {shiftRequirementDetails?.required_hcp_count || 0}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            /!*<div className={'ts-col-lg-5'}>*!/*/}
            {/*                                            /!*    <DataLabelValueComponent label={"Open Positions"}>*!/*/}
            {/*                                            /!*        {shiftRequirementDetails?.required_hcp_count || 0}*!/*/}
            {/*                                            /!*    </DataLabelValueComponent>*!/*/}
            {/*                                            /!*</div>*!/*/}
            {/*                                            <div className={'ts-col-lg-5'}>*/}
            {/*                                                <DataLabelValueComponent label={'Shift Rate'}>*/}
            {/*                                                    $ {shiftRequirementDetails?.shift_rate || 0}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-7'}>*/}
            {/*                                                <DataLabelValueComponent*/}
            {/*                                                    label={'Language Preferences'}*/}
            {/*                                                >*/}
            {/*                                                    {shiftRequirementDetails?.language || '_'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-5'}>*/}
            {/*                                                <DataLabelValueComponent label={'No. of Visits'}>*/}
            {/*                                                    {shiftRequirementDetails?.number_of_visit || '_'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-7'}>*/}
            {/*                                                <DataLabelValueComponent label={'MRN'}>*/}
            {/*                                                    {shiftRequirementDetails?.hospice_details?.mrn ||*/}
            {/*                                                        '_'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-12'}>*/}
            {/*                                                <DataLabelValueComponent label={'Notes'}>*/}
            {/*                                                    {shiftRequirementDetails?.note || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        {shiftRequirementDetails?.requirementGalleryPics &&*/}
            {/*                                            shiftRequirementDetails?.requirementGalleryPics*/}
            {/*                                                ?.length > 0 && (*/}
            {/*                                                <div className={'ts-row'}>*/}
            {/*                                                    <div className={'ts-col-lg-12'}>*/}
            {/*                                                        <DataLabelValueComponent label={'Documents '}>*/}
            {/*                                                            <div>*/}
            {/*                                                                {shiftRequirementDetails?.requirementGalleryPics &&*/}
            {/*                                                                    shiftRequirementDetails?.requirementGalleryPics?.map(*/}
            {/*                                                                        (file: any, index: any) => {*/}
            {/*                                                                            return (*/}
            {/*                                                                                <>*/}
            {/*                                                                                    <div*/}
            {/*                                                                                        className={*/}
            {/*                                                                                            'file-holder-wrapper mrg-bottom-10 pdd-10'*/}
            {/*                                                                                        }*/}
            {/*                                                                                    >*/}
            {/*                                                                                        <div*/}
            {/*                                                                                            className={*/}
            {/*                                                                                                'underline-text icon-button'*/}
            {/*                                                                                            }*/}
            {/*                                                                                            onClick={() => {*/}
            {/*                                                                                                const link =*/}
            {/*                                                                                                    document.createElement(*/}
            {/*                                                                                                        'a',*/}
            {/*                                                                                                    );*/}
            {/*                                                                                                link.href = file?.url;*/}
            {/*                                                                                                link.setAttribute(*/}
            {/*                                                                                                    'download',*/}
            {/*                                                                                                    '',*/}
            {/*                                                                                                );*/}

            {/*                                                                                                document.body.appendChild(*/}
            {/*                                                                                                    link,*/}
            {/*                                                                                                );*/}
            {/*                                                                                                link.click();*/}

            {/*                                                                                                // Cleanup*/}
            {/*                                                                                                document.body.removeChild(*/}
            {/*                                                                                                    link,*/}
            {/*                                                                                                );*/}
            {/*                                                                                                // window.open(file?.url);*/}
            {/*                                                                                            }}*/}
            {/*                                                                                        >*/}
            {/*                                                                                            {' '}*/}
            {/*                                                                                            Document {index + 1}*/}
            {/*                                                                                        </div>*/}
            {/*                                                                                    </div>*/}
            {/*                                                                                </>*/}
            {/*                                                                            );*/}
            {/*                                                                        },*/}
            {/*                                                                    )}*/}
            {/*                                                            </div>*/}
            {/*                                                        </DataLabelValueComponent>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            )}*/}
            {/*                                    </>*/}
            {/*                                )}*/}
            {/*                            {shiftRequirementDetails?.requirementType ===*/}
            {/*                                'Contract Travel' && (*/}
            {/*                                    <>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-5'}>*/}
            {/*                                                <DataLabelValueComponent label={'Open Positions'}>*/}
            {/*                                                    {shiftRequirementDetails?.required_hcp_count || 0}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-7'}>*/}
            {/*                                                <DataLabelValueComponent label={'Shift Type'}>*/}
            {/*                                                    {shiftRequirementDetails?.shift_type || 0}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-5'}>*/}
            {/*                                                <DataLabelValueComponent label={'Days per week'}>*/}
            {/*                                                    {shiftRequirementDetails?.day_per_week || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-7'}>*/}
            {/*                                                <DataLabelValueComponent label={'Type'}>*/}
            {/*                                                    {shiftRequirementDetails?.type || 0}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-5'}>*/}
            {/*                                                <DataLabelValueComponent label={'Shift Rate'}>*/}
            {/*                                                    $ {shiftRequirementDetails?.shift_rate || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-7'}>*/}
            {/*                                                <DataLabelValueComponent label={'Weekly Earnings'}>*/}
            {/*                                                    $ {shiftRequirementDetails?.weekly_earning || 0}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-5'}>*/}
            {/*                                                <DataLabelValueComponent label={'Travel/Diems'}>*/}
            {/*                                                    {shiftRequirementDetails?.travel_per_diem || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={'ts-col-lg-7'}>*/}
            {/*                                                <DataLabelValueComponent*/}
            {/*                                                    label={'Travel per diems rate'}*/}
            {/*                                                >*/}
            {/*                                                    ${' '}*/}
            {/*                                                    {shiftRequirementDetails?.travel_per_diem_rate ||*/}
            {/*                                                        0}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-12'}>*/}
            {/*                                                <DataLabelValueComponent label={'State License'}>*/}
            {/*                                                    {shiftRequirementDetails?.state_license || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-12'}>*/}
            {/*                                                <DataLabelValueComponent label={'Certifications'}>*/}
            {/*                                                    {shiftRequirementDetails?.certifications || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-12'}>*/}
            {/*                                                <DataLabelValueComponent*/}
            {/*                                                    label={'Speciality Experience'}*/}
            {/*                                                >*/}
            {/*                                                    {shiftRequirementDetails?.speciality_experience ||*/}
            {/*                                                        '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-12'}>*/}
            {/*                                                <DataLabelValueComponent label={'Skill Assessment'}>*/}
            {/*                                                    {shiftRequirementDetails?.skill_assessment || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-12'}>*/}
            {/*                                                <DataLabelValueComponent label={'References'}>*/}
            {/*                                                    {shiftRequirementDetails?.references || '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-12'}>*/}
            {/*                                                <DataLabelValueComponent*/}
            {/*                                                    label={'Additional Skills'}*/}
            {/*                                                >*/}
            {/*                                                    {shiftRequirementDetails?.additional_skills ||*/}
            {/*                                                        '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-12'}>*/}
            {/*                                                <DataLabelValueComponent*/}
            {/*                                                    label={'Additional Confirmations'}*/}
            {/*                                                >*/}
            {/*                                                    {shiftRequirementDetails?.additional_confirmations ||*/}
            {/*                                                        '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={'ts-row'}>*/}
            {/*                                            <div className={'ts-col-lg-12'}>*/}
            {/*                                                <DataLabelValueComponent*/}
            {/*                                                    label={'Travel Contract Limitations'}*/}
            {/*                                                >*/}
            {/*                                                    {shiftRequirementDetails?.travel_contract_limitations ||*/}
            {/*                                                        '-'}*/}
            {/*                                                </DataLabelValueComponent>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                    </>*/}
            {/*                                )}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </>*/}
            {/*            )}*/}
            {/*        </div>*/}
            {/*        <div className={'flex-2 open-requirement-hcp-assigning-wrapper'}>*/}
            {/*            <div className={'shift-application-wrapper'}>*/}
            {/*                <div>*/}
            {/*                    <div>*/}
            {/*                        <TabsWrapperComponent>*/}
            {/*                            <div className={'d-flex justify-content-space-between'}>*/}
            {/*                                <div>*/}
            {/*                                    <TabsComponent*/}
            {/*                                        value={activeTab}*/}
            {/*                                        allowScrollButtonsMobile={false}*/}
            {/*                                        onUpdate={(e: any, v: any) => {*/}
            {/*                                            setActiveTab(v);*/}
            {/*                                            setFilterData({...filterData, status: v});*/}
            {/*                                        }}*/}
            {/*                                    >*/}
            {/*                                        <TabComponent*/}
            {/*                                            label={*/}
            {/*                                                <span className={'d-flex align-items-center'}>*/}
            {/*                    Requests*/}
            {/*                    <span className={'count-box'}>*/}
            {/*                      {*/}
            {/*                          shiftRequirementDetails?.applications_request_count*/}
            {/*                      }*/}
            {/*                    </span>*/}
            {/*                  </span>*/}
            {/*                                            }*/}
            {/*                                            value={'pending'}*/}
            {/*                                        />*/}
            {/*                                        <TabComponent*/}
            {/*                                            label={*/}
            {/*                                                <span className={'d-flex align-items-center'}>*/}
            {/*                    Approved*/}
            {/*                    <span className={'count-box'}>*/}
            {/*                      {*/}
            {/*                          shiftRequirementDetails?.applications_approved_count*/}
            {/*                      }*/}
            {/*                    </span>*/}
            {/*                  </span>*/}
            {/*                                            }*/}
            {/*                                            value={'approved'}*/}
            {/*                                        />*/}
            {/*                                        <TabComponent*/}
            {/*                                            label={*/}
            {/*                                                <span className={'d-flex align-items-center'}>*/}
            {/*                    Rejected*/}
            {/*                    <span className={'count-box'}>*/}
            {/*                      {*/}
            {/*                          shiftRequirementDetails?.applications_rejected_count*/}
            {/*                      }*/}
            {/*                    </span>*/}
            {/*                  </span>*/}
            {/*                                            }*/}
            {/*                                            value={'rejected'}*/}
            {/*                                        />*/}
            {/*                                    </TabsComponent>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <TabContentComponent*/}
            {/*                                value={activeTab}*/}
            {/*                                selectedTab={filterData?.status}*/}
            {/*                            >*/}
            {/*                                <div className={'list-screen mrg-left-25'}>*/}
            {/*                                    <div className='list-screen-content'>*/}
            {/*                                        <TableWrapperComponent*/}
            {/*                                            url={APIConfig.HCP_ASSIGNED_TO_SHIFT.URL(*/}
            {/*                                                requirementId,*/}
            {/*                                            )}*/}
            {/*                                            method={APIConfig.HCP_ASSIGNED_TO_SHIFT.METHOD}*/}
            {/*                                            isPaginated={true}*/}
            {/*                                            extraPayload={filterData}*/}
            {/*                                            noDataText={*/}
            {/*                                                filterData.search*/}
            {/*                                                    ? 'Oops! It seems like there are no Staff available for the name you have searched.'*/}
            {/*                                                    : 'No Data'*/}
            {/*                                            }*/}
            {/*                                            columns={ShiftsListColumn}*/}
            {/*                                        />*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </TabContentComponent>*/}
            {/*                        </TabsWrapperComponent>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={'open-shift-details-creation-month-day-view-component'}>
                <div
                    className={
                        'open-shift-details-creation-month-day-view-header d-flex justify-content-space-between align-items-center'
                    }
                >
                    <div>
                        {isShiftDetailsLoaded && <div>
                            {shiftRequirementDetails?.requirementType === "Home Health" && "Per visit Details"}
                            {shiftRequirementDetails?.requirementType === "Contract Travel" && "Contract/Travel Details"}
                            {(shiftRequirementDetails?.requirementType !== "Contract Travel" && shiftRequirementDetails?.requirementType !== "Home Health") && "Per Diem Details"}
                        </div>}
                    </div>
                    <div className={'d-flex'}>
                        {shiftRequirementDetails?.status !== 'cancelled' && (
                            <div style={{marginRight: '10px'}}>
                                <ButtonComponent
                                    onClick={() => setCancelConformation(true)}
                                    color={'cancel-requirement '}
                                    className={'cancel-shift-button'}
                                    prefixIcon={<ImageConfig.CancelIconRed/>}
                                >
                                    Cancel Shift Requirement
                                </ButtonComponent>
                            </div>
                        )}
                        <div
                            onClick={() => {
                                CommonService._staticData.openDialogPopup(
                                    setOpenOpenShiftRequirementDialog,
                                    false,
                                );
                            }}
                            className={'icon-button'}
                        >
                            <ImageConfig.CrossIcon/>
                        </div>
                    </div>
                </div>
                <div className={'pdd-20 d-flex'}>
                    <div className={'flex-1 open-requirement-shift-details'}>
                        {!isShiftDetailsLoaded && (
                            <div className={'center-element'}>
                                <LoaderComponent type={'spinner'}/>
                            </div>
                        )}
                        {isShiftDetailsLoaded && shiftRequirementDetails && (
                            <>
                                <div className={'shift-requirement-day-week-details-wrapper'}>
                                    <div
                                        className={
                                            'shift-requirement-week-day-header mrg-bottom-15 form-header-text '
                                        }
                                    >
                                        Shift Requirement Details
                                    </div>
                                    <div className={'shift-requirement-week-day-details-card'}>
                                        <div className={'pdd-20'}>
                                            <div
                                                className={'d-flex justify-content-space-between ts-align-items-center mrg-bottom-20'}>
                                                <div className={'header-text-font'}>
                                                    {shiftRequirementDetails?.requirementType === "Contract Travel" ? " Opportunity" : "Shift Details"}
                                                </div>
                                                <div>
                                                    <div
                                                        className={`requirement-holder-daily-monthly-view ${
                                                            shiftTypeLayoutRepresentation.find(
                                                                (shiftType) =>
                                                                    shiftType.code ===
                                                                    shiftRequirementDetails.shift_type,
                                                            )?.classname
                                                        } d-flex align-items-center`}
                                                    >
                                                        {shiftRequirementDetails.shift_type}&nbsp;&nbsp;
                                                        {
                                                            shiftTypeLayoutRepresentation.find(
                                                                (shiftTypeObj) =>
                                                                    shiftTypeObj.code ===
                                                                    shiftRequirementDetails.shift_type,
                                                            )?.icon
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {shiftRequirementDetails?.requirementType !==
                                                'Home Health' && (
                                                    <>
                                                        {/*<div className={'d-flex'}>*/}
                                                        {/*    <div className={'mrg-right-20 mrg-bottom-20'}>*/}
                                                        {/*        <ImageConfig.FacilityIcon/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className={'facility-name'}>*/}
                                                        {/*        {shiftRequirementDetails?.facility_details?.data*/}
                                                        {/*            .name || '-'}*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        <div className={'d-flex'}>
                                                            <div className={'mrg-right-20 mrg-bottom-20'}>
                                                                <ImageConfig.ClockIconSmall/>
                                                            </div>
                                                            <div className={'form-minor-text'}>
                                                                {CommonService?.convertMinutesToTime(
                                                                        shiftRequirementDetails?.shift_timings
                                                                            ?.start_time,
                                                                    ) +
                                                                    ' - ' +
                                                                    CommonService?.convertMinutesToTime(
                                                                        shiftRequirementDetails?.shift_timings
                                                                            ?.end_time,
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <div className={'d-flex'}>
                                                            <div className={'mrg-right-20 mrg-bottom-20'}>
                                                                <ImageConfig.CalenderIconSmall/>
                                                            </div>
                                                            <div className={'form-minor-text'}>
                                                                {CommonService.convertDateFormat2(shiftRequirementDetails?.shift_date, 'MM-DD-YYYY') || ' - '} {shiftRequirementDetails?.requirementType === "Contract Travel" && ` - ${CommonService.convertDateFormat2(shiftRequirementDetails?.shift_end_date, 'MM-DD-YYYY')}`}
                                                            </div>
                                                        </div>
                                                        <div className={'d-flex'}>
                                                            <div className={'mrg-right-20 mrg-bottom-20'}>
                                                                <ImageConfig.HcpType/>
                                                            </div>
                                                            <div className={'form-minor-text'}>
                                                                {shiftRequirementDetails?.hcp_type_details
                                                                    ?.title || '-'}{' '}
                                                                { shiftRequirementDetails?.hcp_speciality_details
                                                                        ?.title &&
                                                                    "(" +
                                                                        shiftRequirementDetails?.hcp_speciality_details
                                                                            ?.title
                                                                    +")"
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            {shiftRequirementDetails?.requirementType ===
                                                'Home Health' && (
                                                    <>
                                                        <div className={'ts-col-lg-12 mrg-top-10'}>
                                                            <div className={'d-flex'}>
                                                                <div className={'mrg-right-15'}>
                                                                    <ImageConfig.FacilityIcon/>
                                                                </div>
                                                                <div>
                                                                    <Tooltip
                                                                        title={
                                                                            shiftRequirementDetails?.hospice_details
                                                                                ?.first_name +
                                                                            ' ' +
                                                                            shiftRequirementDetails?.hospice_details
                                                                                ?.last_name || 'N/A'
                                                                        }
                                                                        placement={'top'}
                                                                    >
                                                                        <div
                                                                            className={'facility-name form-minor-text'}
                                                                        >
                                                                            {' '}
                                                                            {shiftRequirementDetails?.hospice_details
                                                                                    ?.first_name +
                                                                                ' ' +
                                                                                shiftRequirementDetails?.hospice_details
                                                                                    ?.last_name || 'N/A'}
                                                                        </div>
                                                                    </Tooltip>
                                                                    <div>
                                                                        ({' '}
                                                                        {
                                                                            shiftRequirementDetails?.hospice_details
                                                                                ?.gender
                                                                        }{' '}
                                                                        {CommonService?.calculateAge(shiftRequirementDetails?.hospice_details?.dob)} years
                                                                        )
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*<div className={'ts-col-lg-12 mrg-top-10'}>*/}
                                                        {/*    <div className={'d-flex'}>*/}
                                                        {/*        <div className={'mrg-right-15'}>*/}
                                                        {/*            <ImageConfig.ClockIconSmall/>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className={'form-minor-text'}>*/}
                                                        {/*            {CommonService?.convertMinutesToTime(*/}
                                                        {/*                    shiftRequirementDetails?.shift_timings*/}
                                                        {/*                        ?.start_time,*/}
                                                        {/*                ) +*/}
                                                        {/*                '-' +*/}
                                                        {/*                CommonService?.convertMinutesToTime(*/}
                                                        {/*                    shiftRequirementDetails?.shift_timings*/}
                                                        {/*                        ?.end_time,*/}
                                                        {/*                )}*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        <div className={'ts-col-lg-12 mrg-top-10'}>
                                                            <div className={'d-flex'}>
                                                                <div className={'mrg-right-15'}>
                                                                    <ImageConfig.CalenderIconSmall/>
                                                                </div>
                                                                <div className={'form-minor-text'}>
                                                                    Visit Patient
                                                                    By: {CommonService.convertDateFormat2(shiftRequirementDetails?.shift_date, 'MM-DD-YYYY') || '-'} , {CommonService?.convertMinutesToTime(shiftRequirementDetails?.hospice_details?.visit_patient_time)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'ts-col-lg-12 mrg-top-10'}>
                                                            <div className={'d-flex'}>
                                                                <div className={'mrg-right-15'}>
                                                                    <ImageConfig.HcpType/>
                                                                </div>
                                                                <div className={'form-minor-text'}>
                                                                    {shiftRequirementDetails?.hcp_type_details
                                                                        ?.title || '-'}{' '}
                                                                    (Req For :{' '}
                                                                    {shiftRequirementDetails?.gender_preference}{' '}
                                                                    staff)
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'ts-col-lg-12 mrg-top-10'}>
                                                            <div className={'d-flex'}>
                                                                <div className={'mrg-right-15 form-minor-text'}>
                                                                    <ImageConfig.LocationIcon/>
                                                                </div>
                                                                <div className={'form-minor-text'}>{address}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                    </div>

                                    <div className={'mrg-top-10 mrg-bottom-10 pdd-top-20'}>
                                        {/*<HorizontalLineComponent/>*/}
                                        <div
                                            className={'font-size-14 font-weight-bold mrg-bottom-15'}
                                        >
                                            Shift Created For
                                        </div>
                                        <div className={'d-flex'}>
                                            {shiftRequirementDetails?.send_to?.is_internal_hcp && (
                                                <div
                                                    className={
                                                        'open-requirement-shift-for-chip mrg-right-10'
                                                    }
                                                >
                                                    Internal Staff
                                                </div>
                                            )}
                                            {shiftRequirementDetails?.send_to?.is_float && (
                                                <div
                                                    className={
                                                        'open-requirement-shift-for-chip  mrg-right-10'
                                                    }
                                                >
                                                    Float Staff
                                                </div>
                                            )}
                                            {shiftRequirementDetails?.send_to?.specific_agencies && (
                                                <div
                                                    className={
                                                        'open-requirement-shift-for-chip  mrg-right-10'
                                                    }
                                                >
                                                    Agencies
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {shiftRequirementDetails?.requirementType ===
                                        'Home Health' && (
                                            <>
                                                <div className={'ts-row mrg-top-15'}>
                                                    <div className={'ts-col-lg-5'}>
                                                        <DataLabelValueComponent label={'Phone Number'}>
                                                            {CommonService.formatPhoneNumber(shiftRequirementDetails?.hospice_details
                                                                ?.phone_number) || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-7'}>
                                                        <DataLabelValueComponent label={'Email Address'}>
                                                            {shiftRequirementDetails?.hospice_details
                                                                ?.email_address || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-7'}>
                                                        <DataLabelValueComponent label={'Staff Speciality'}>
                                                            {shiftRequirementDetails?.hcp_speciality_details
                                                                ?.title || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    <div
                                        className={
                                            'shift-requirement-week-day-header form-header-text '
                                        }
                                    >
                                        {shiftRequirementDetails?.requirementType === "Contract Travel" ? " Job Details" : "Additional Shift  Details"}
                                    </div>
                                    <div
                                        className={
                                            'pdd-right-15 pdd-top-20 pdd-bottom-20'
                                        }
                                    >
                                        {shiftRequirementDetails?.requirementType ===
                                            'Open Shift' && (
                                                <>
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-5'}>
                                                            <DataLabelValueComponent label={'Open Positions'}>
                                                                {shiftRequirementDetails?.required_hcp_count || 0}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                        <div className={'ts-col-lg-7'}>
                                                            <DataLabelValueComponent label={'Shift Rate'}>
                                                                $ {shiftRequirementDetails?.shift_rate || 0}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-5'}>
                                                            <DataLabelValueComponent label={'Wing'}>
                                                                {shiftRequirementDetails?.facility_wing_details
                                                                    ?.data?.name || 'N/A'}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                        <div className={'ts-col-lg-7'}>
                                                            <DataLabelValueComponent label={'Weekend Rate'}>
                                                                {shiftRequirementDetails?.hcp_weekend_rate ? `$ ${shiftRequirementDetails?.hcp_weekend_rate}` : 'N/A'}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                    {/*<div className={'ts-row'}>*/}
                                                    {/*    <div className={'ts-col-lg-5'}>*/}
                                                    {/*        <DataLabelValueComponent label={'Differential'}>*/}
                                                    {/*            {shiftRequirementDetails?.differential_rate ||*/}
                                                    {/*                'N/A'}*/}
                                                    {/*        </DataLabelValueComponent>*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className={'ts-col-lg-7'}>*/}
                                                    {/*        <DataLabelValueComponent label={'OT Pay'}>*/}
                                                    {/*            {shiftRequirementDetails?.ot_pay || 'N/A'}*/}
                                                    {/*        </DataLabelValueComponent>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-5'}>
                                                            <DataLabelValueComponent label={'Rush Rate'}>
                                                                {shiftRequirementDetails?.hcp_rush_rate ? `$${shiftRequirementDetails?.hcp_rush_rate}` : 'N/A'}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                        <div className={'ts-col-lg-7'}>
                                                            <DataLabelValueComponent label={'PM Diff'}>
                                                                {shiftRequirementDetails?.hcp_pm_diff ? `$ ${shiftRequirementDetails?.hcp_pm_diff}` : 'N/A'}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-5'}>
                                                            <DataLabelValueComponent label={'NOC Rate'}>
                                                                {shiftRequirementDetails?.hcp_noc_diff ? `$${shiftRequirementDetails?.hcp_noc_diff}` : 'N/A'}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                        <div className={'ts-col-lg-7'}>
                                                            <DataLabelValueComponent label={'Hazard Rate'}>
                                                                {shiftRequirementDetails?.hcp_hazard_rate ? `$ ${shiftRequirementDetails?.hcp_hazard_rate}` : 'N/A'}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-12'}>
                                                            <DataLabelValueComponent label={'Notes'}>
                                                                {shiftRequirementDetails?.note || 'N/A'}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                    {shiftRequirementDetails?.requirementGalleryPics &&
                                                        shiftRequirementDetails?.requirementGalleryPics
                                                            ?.length > 0 && (
                                                            <div className={'ts-row'}>
                                                                <div className={'ts-col-lg-12'}>
                                                                    <DataLabelValueComponent label={'Documents '}>
                                                                        <div>
                                                                            {shiftRequirementDetails?.requirementGalleryPics &&
                                                                                shiftRequirementDetails?.requirementGalleryPics?.map(
                                                                                    (file: any, index: any) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    className={
                                                                                                        'file-holder-wrapper mrg-bottom-10 pdd-10'
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        className={
                                                                                                            'underline-text icon-button'
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            const link =
                                                                                                                document.createElement(
                                                                                                                    'a',
                                                                                                                );
                                                                                                            link.href = file?.url;
                                                                                                            link.setAttribute(
                                                                                                                'download',
                                                                                                                '',
                                                                                                            );

                                                                                                            document.body.appendChild(
                                                                                                                link,
                                                                                                            );
                                                                                                            link.click();

                                                                                                            // Cleanup
                                                                                                            document.body.removeChild(
                                                                                                                link,
                                                                                                            );
                                                                                                            // window.open(file?.url);
                                                                                                        }}
                                                                                                    >
                                                                                                        {' '}
                                                                                                        Document {index + 1}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    },
                                                                                )}
                                                                        </div>
                                                                    </DataLabelValueComponent>
                                                                </div>
                                                            </div>
                                                        )}
                                                </>
                                            )}
                                        {shiftRequirementDetails?.requirementType ===
                                            'Home Health' && (
                                                <>
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-12'}>
                                                            <DataLabelValueComponent label={'Open Positions'}>
                                                                {shiftRequirementDetails?.required_hcp_count || 0}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                    <div className={'ts-row'}>
                                                        {/*<div className={'ts-col-lg-5'}>*/}
                                                        {/*    <DataLabelValueComponent label={"Open Positions"}>*/}
                                                        {/*        {shiftRequirementDetails?.required_hcp_count || 0}*/}
                                                        {/*    </DataLabelValueComponent>*/}
                                                        {/*</div>*/}
                                                        <div className={'ts-col-lg-5'}>
                                                            <DataLabelValueComponent label={'Shift Rate'}>
                                                                $ {shiftRequirementDetails?.shift_rate || 0}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                        <div className={'ts-col-lg-7'}>
                                                            <DataLabelValueComponent
                                                                label={'Language Preferences'}
                                                            >
                                                                {shiftRequirementDetails?.languages ? shiftRequirementDetails?.languages?.filter((language: any) => language !== null)
                                                                    .join(', ') : "N/A"}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-5'}>
                                                            <DataLabelValueComponent label={'No. of Visits'}>
                                                                {shiftRequirementDetails?.number_of_visit || 'N/A'}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                        <div className={'ts-col-lg-7'}>
                                                            <DataLabelValueComponent label={'MRN'}>
                                                                {shiftRequirementDetails?.hospice_details?.mrn ||
                                                                    'N/A'}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-5'}>
                                                            <DataLabelValueComponent label={'Latitude'}>
                                                                {shiftRequirementDetails?.hospice_details?.location?.coordinates[1] || "N/A"}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                        <div className={'ts-col-lg-7'}>
                                                            <DataLabelValueComponent label={'Longitude'}>
                                                                {shiftRequirementDetails?.hospice_details?.location?.coordinates[0] || "N/A"}
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                    {/*<div className={'ts-row'}>*/}
                                                    {/*    <div className={'ts-col-lg-12'}>*/}
                                                    {/*        <DataLabelValueComponent label={'Notes'}>*/}
                                                    {/*            {shiftRequirementDetails?.note || '-'}*/}
                                                    {/*        </DataLabelValueComponent>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    {shiftRequirementDetails?.requirementGalleryPics &&
                                                        shiftRequirementDetails?.requirementGalleryPics
                                                            ?.length > 0 && (
                                                            <div className={'ts-row'}>
                                                                <div className={'ts-col-lg-12'}>
                                                                    <DataLabelValueComponent label={'Documents '}>
                                                                        <div>
                                                                            {shiftRequirementDetails?.requirementGalleryPics &&
                                                                                shiftRequirementDetails?.requirementGalleryPics?.map(
                                                                                    (file: any, index: any) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    className={
                                                                                                        'file-holder-wrapper mrg-bottom-10 pdd-10'
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        className={
                                                                                                            'underline-text icon-button'
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            const link =
                                                                                                                document.createElement(
                                                                                                                    'a',
                                                                                                                );
                                                                                                            link.href = file?.url;
                                                                                                            link.setAttribute(
                                                                                                                'download',
                                                                                                                '',
                                                                                                            );

                                                                                                            document.body.appendChild(
                                                                                                                link,
                                                                                                            );
                                                                                                            link.click();

                                                                                                            // Cleanup
                                                                                                            document.body.removeChild(
                                                                                                                link,
                                                                                                            );
                                                                                                            // window.open(file?.url);
                                                                                                        }}
                                                                                                    >
                                                                                                        {' '}
                                                                                                        Document {index + 1}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    },
                                                                                )}
                                                                        </div>
                                                                    </DataLabelValueComponent>
                                                                </div>
                                                            </div>
                                                        )}
                                                </>
                                            )}
                                        {
                                            shiftRequirementDetails?.requirementType === "Contract Travel" && <>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-5'}>
                                                        <DataLabelValueComponent label={"# of positions"}>
                                                            {shiftRequirementDetails?.required_hcp_count || 0}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-7'}>
                                                        <DataLabelValueComponent label={'Hours per Shift'}>
                                                            {shiftRequirementDetails?.shift_duration || 0} hours
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-5'}>
                                                        <DataLabelValueComponent label={'# of Days per Week'}>
                                                            {shiftRequirementDetails?.day_per_week || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-7'}>
                                                        <DataLabelValueComponent label={'Job Type'}>
                                                            {shiftRequirementDetails?.job_type || "N/A"}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-12'}>
                                                        <DataLabelValueComponent label={'# of Weeks'}>
                                                            {shiftRequirementDetails?.no_of_weeks || "N/A"}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>

                                                {/*<div className={'ts-row'}>*/}
                                                {/*    /!*<div className={'ts-col-lg-5'}>*!/*/}
                                                {/*    /!*    <DataLabelValueComponent label={'Shift Rate'}>*!/*/}
                                                {/*    /!*        $ {shiftRequirementDetails?.shift_rate || '-'}*!/*/}
                                                {/*    /!*    </DataLabelValueComponent>*!/*/}
                                                {/*    /!*</div>*!/*/}
                                                {/*    <div className={'ts-col-lg-5'}>*/}
                                                {/*        <DataLabelValueComponent label={'$/week'}>*/}
                                                {/*            $ {shiftRequirementDetails?.weekly_earning || 0}*/}
                                                {/*        </DataLabelValueComponent>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div>

                                                </div>
                                                <div
                                                    className={'shift-requirement-week-day-header form-header-text mrg-bottom-10'}
                                                >
                                                    Rates
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-4'}>
                                                        <DataLabelValueComponent label={'Type'}>

                                                            {
                                                                shiftRequirementDetails?.travel_per_diems?.map((item: any, index: any) => {
                                                                    return (
                                                                        <div className={'mrg-bottom-10'}>
                                                                            {item?.type || 'N/A'}
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-4'}>
                                                        <DataLabelValueComponent
                                                            label={'Rate'}
                                                        >
                                                            {
                                                                shiftRequirementDetails?.travel_per_diems?.map((item: any, index: any) => {
                                                                    return (
                                                                        <div
                                                                            className={'d-flex ts-justify-content-between mrg-bottom-10'}>
                                                                            {item?.rate ?
                                                                            <div>
                                                                                $ {item?.rate} {index === 0 ? "/hr" : "/day"}
                                                                            </div>
                                                                            :<div>N/A</div>}
                                                                            {/*<div*/}
                                                                            {/*    className={'days-rate-text-orientation '}>*/}
                                                                            {/*    {`(${shiftRequirementDetails?.day_per_week}days  *  $${item?.rate})`}*/}
                                                                            {/*</div>*/}
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-4'}>
                                                        <DataLabelValueComponent
                                                            label={'Rate/week'}
                                                        >
                                                            {
                                                                shiftRequirementDetails?.travel_per_diems?.map((item: any, index: any) => {
                                                                    return (
                                                                        <div
                                                                            className={'d-flex ts-justify-content-between mrg-bottom-10'}>
                                                                            {item?.week_rate ?
                                                                            <div>
                                                                                $ {item?.week_rate} /week
                                                                            </div>
                                                                            :<div>N/A</div>}
                                                                            {/*<div*/}
                                                                            {/*    className={'days-rate-text-orientation '}>*/}
                                                                            {/*    {`(${shiftRequirementDetails?.day_per_week}days  *  $${item?.rate})`}*/}
                                                                            {/*</div>*/}
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                {shiftRequirementDetails?.cancel_policy &&
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-12'}>
                                                            <DataLabelValueComponent label={'Cancel Policy'}>
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: shiftRequirementDetails?.cancel_policy?.replace(/\n/g, '<br/>')}}/>
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                }
                                                {shiftRequirementDetails?.staff_requirement &&
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-12'}>
                                                            <DataLabelValueComponent label={'Staff Requirements'}>
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: shiftRequirementDetails?.staff_requirement?.replace(/\n/g, '<br/>')}}/>
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                }
                                                {shiftRequirementDetails?.job_details &&
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-12'}>
                                                            <DataLabelValueComponent label={'Job Details'}>
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: shiftRequirementDetails?.job_details?.replace(/\n/g, '<br/>')}}/>
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                }
                                                {shiftRequirementDetails?.job_benefits &&
                                                    <div className={'ts-row'}>
                                                        <div className={'ts-col-lg-12'}>
                                                            <DataLabelValueComponent label={'Job Benefits'}>
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: shiftRequirementDetails?.job_benefits?.replace(/\n/g, '<br/>')}}/>
                                                            </DataLabelValueComponent>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className={'flex-2 open-requirement-hcp-assigning-wrapper'}>
                        <div className={'shift-application-wrapper'}>
                            <div>
                                <div>
                                    <TabsWrapperComponent>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>
                                                <TabsComponent
                                                    value={activeTab}
                                                    allowScrollButtonsMobile={false}
                                                    onUpdate={(e: any, v: any) => {
                                                        setActiveTab(v);
                                                        setFilterData({...filterData, status: v});
                                                    }}
                                                >
                                                    <TabComponent
                                                        label={
                                                            <span className={'d-flex align-items-center'}>
                                Requests
                                <span className={'count-box'}>
                                  {
                                      shiftRequirementDetails?.applications_request_count || 0
                                  }
                                </span>
                              </span>
                                                        }
                                                        value={'pending'}
                                                    />
                                                    <TabComponent
                                                        label={
                                                            <span className={'d-flex align-items-center'}>
                                Approved
                                <span className={'count-box'}>
                                  {
                                      shiftRequirementDetails?.applications_approved_count || 0
                                  }
                                </span>
                              </span>
                                                        }
                                                        value={'approved'}
                                                    />
                                                    <TabComponent
                                                        label={
                                                            <span className={'d-flex align-items-center'}>
                                Rejected
                                <span className={'count-box'}>
                                  {
                                      shiftRequirementDetails?.applications_rejected_count || 0
                                  }
                                </span>
                              </span>
                                                        }
                                                        value={'rejected'}
                                                    />
                                                </TabsComponent>
                                            </div>
                                        </div>
                                        <TabContentComponent
                                            value={activeTab}
                                            selectedTab={filterData?.status}
                                        >
                                            <div className={'mrg-left-25'}>
                                                <div>
                                                    {/*<TableWrapperComponent*/}
                                                    {/*    url={APIConfig.HCP_ASSIGNED_TO_SHIFT.URL(*/}
                                                    {/*        requirementId,*/}
                                                    {/*    )}*/}
                                                    {/*    method={APIConfig.HCP_ASSIGNED_TO_SHIFT.METHOD}*/}
                                                    {/*    isPaginated={true}*/}
                                                    {/*    extraPayload={filterData}*/}
                                                    {/*    noDataText={*/}
                                                    {/*        filterData.search*/}
                                                    {/*            ? 'Oops! It seems like there are no Staff available for the name you have searched.'*/}
                                                    {/*            : `No ${activeTab} Shift Application Found.`*/}
                                                    {/*    }*/}
                                                    {/*    columns={ShiftsListColumn}*/}
                                                    {/*/>*/}
                                                    <AntTableComponent
                                                        url={APIConfig.HCP_ASSIGNED_TO_SHIFT.URL(
                                                            requirementId,
                                                        )}
                                                        method={APIConfig.HCP_ASSIGNED_TO_SHIFT.METHOD}
                                                        isRowSelection={false}
                                                        extraPayload={filterData}
                                                        noDataText={
                                                            filterData.search
                                                                ? 'Oops! It seems like there are no Staff available for the name you have searched.'
                                                                : `No ${activeTab} Shift Application Found.`
                                                        }
                                                        columns={ShiftsListColumn}
                                                        />
                                                </div>
                                            </div>
                                        </TabContentComponent>
                                    </TabsWrapperComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OpenShiftViewDetailsCreationComponent;
