import * as Yup from 'yup';
import React, {useCallback, useEffect, useState} from 'react';
import {Field, FieldProps, Form, Formik, FormikHelpers} from 'formik';
import {useNavigate} from 'react-router-dom';
import DatePickers from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import './AddShiftRequirementDetails.scss';
import moment from 'moment';
import {
    Checkbox,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {IRootReducerState} from '../../../../store/reducers';
import {CommonService} from '../../../../shared/services';
import {IAPIResponseType} from '../../../../shared/models/api.model';
import {ImageConfig, Misc} from '../../../../constants';
import LabelComponent from '../../../../shared/components/label/LabelComponent';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import {MenuProps, shift_type} from '../../../../data';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import FormikCheckBoxComponent
    from '../../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent';
import FormControl from '@mui/material/FormControl';
import CheckBoxComponent from '../../../../shared/components/form-controls/check-box/CheckBoxComponent';
import ErrorComponent from '../../../../shared/components/error/ErrorComponent';
import RadioButtonGroupComponent
    from '../../../../shared/components/form-controls/radio-button-group/RadioButtonGroupComponent';
import HomeHealthHospiceTypeShiftRequirement from './home-health -hospice/HomeHealthHospiceTypeShiftRequirement';
import {addMonths} from 'date-fns';
import FormikSelectDropdownComponent
    from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import FormikTextAreaComponent
    from '../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent';
import FormikDatePickerComponent
    from '../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent';
import FilePickerComponent from '../../../../shared/components/file-picker/FilePickerComponent';
import ContractTravelComponent from './contract-travel-shift/ContractTravelComponent';
import {getStaffSpecialityList} from '../../../../store/actions/meta.action'; // Import addMonths function from date-fns library
// import ShiftRateComponent from "../shift-rate/ShiftRateComponent";

// Calculate the date 6 months from now
const sixMonthsFromNow = addMonths(new Date(), 6);

interface AddShiftDetailsProps {
    setShiftTypeHeader?: any;
    onCalender?: any;
    selectedDateFromCalender?: any;
    isShiftCreationForSingleDate?: any;
    schedulingListFilterState?: any;
    setSchedulingListFilterState?: any;
    setOpenDialog?: any;
}

const shiftDetailsValidationSchema = Yup.object().shape({
    hcp_type_id: Yup.string().required('Staff Type is required'),
    required_hcp_count: Yup.number().required('Open Positions is required')
        .min(1,"Minimum 1 open position is required")
        .max(25,"Open positions cannot be more than 25"),
    shift_type: Yup.string().required('Shift Type is required'),
    hcp_speciality_id: Yup.string(),
    shift_timings: Yup.string().required('Shift timings is required'),
});

interface formInitialValuesFormSchema {
    facility_id: string;
    title: string;
    hcp_type_id: any;
    required_hcp_count: any;
    // shift_rate: string;
    facility_wing_id: any;
    hazard_rate: any;
    hcp_speciality_id: any;
    shift_type: any;
    rush_rate: any;
    pm_rate: any;
    noc_rate: any;
    weekend_rate: any;
    shift_dates: any;
    send_to: {
        is_float: any;
        is_internal: any;
        specific_agencies: any;
    };
    base_shift_rate:any;
    hcp_hazard_rate:any;
    hcp_noc_diff:any;
    hcp_pm_diff:any;
    hcp_weekend_rate:any;
    hcp_rush_rate:any;
    is_hazard:boolean;
    is_noc_diff:boolean;
    is_pm_diff:boolean;
    is_weekend:boolean;
    is_rush:boolean;
    is_holiday:boolean;
}

const AddShiftDetailsComponent = (props: AddShiftDetailsProps) => {
    const dispatch = useDispatch();
    const {
        setShiftTypeHeader,
        onCalender,
        selectedDateFromCalender,
        isShiftCreationForSingleDate,
        setSchedulingListFilterState,
        schedulingListFilterState,
        setOpenDialog,
    } = props;
    const formInitialValues: formInitialValuesFormSchema = {
        facility_id: '',
        title: '',
        // shift_rate: "",
        facility_wing_id: '',
        hcp_type_id: '',
        shift_dates: selectedDateFromCalender ? selectedDateFromCalender : null,
        hazard_rate: '',
        shift_type: '',
        hcp_speciality_id: '',
        pm_rate: '',
        noc_rate: '',
        required_hcp_count: '',
        rush_rate: '',
        weekend_rate: '',
        send_to: {
            is_float: false,
            is_internal: false,
            specific_agencies: false,
        },
        base_shift_rate:0,
        hcp_hazard_rate:0,
        hcp_noc_diff:0,
        hcp_pm_diff:0,
        hcp_weekend_rate:0,
        hcp_rush_rate:0,
        is_hazard:false,
        is_noc_diff:false,
        is_pm_diff:false,
        is_weekend:false,
        is_rush:false,
        is_holiday:false,
    };
    const [initialValues] =
        useState<formInitialValuesFormSchema>(formInitialValues);
    const {agencyList, wingsList} = useSelector(
        (state: IRootReducerState) => state.staticData,
    );
    const navigate = useNavigate();
    const {HcpTypesList} = useSelector(
        (state: IRootReducerState) => state.meta,
    );
    const [modifiedWingsList, setModifiedWingsList] = useState<any>(null);
    const [value, setValue] = useState<any>(null);
    const [selectedFacilityDetails, setSelectedFacilityDetails] =
        useState<any>(null);
    const [isInternalSelected, setIsInternalSelected] = useState(false);
    const [isFloatSelected, setIsFloatSelected] = useState(false);
    const [selectedInternalHcp, setSelectedInternalHcp] = React.useState<
        Array<string>
    >([]);
    const [selectedFloatHcp, setSelectedFloatHcp] = React.useState<Array<string>>(
        [],
    );
    const [selectedAgency, setSelectedAgency] = React.useState<Array<string>>([]);
    const [isAgencySelected, setIsAgencySelected] = useState(false);
    const [selectedShiftType, setSelectedShiftType] = useState<any>(null);
    const [selectedHcpType, setSelectedHcpType] = useState<any>(null);
    const [isInternalHcp, setIsInternalHcp] = useState(false);
    const [internalHcpListRequirementBased, setInternalHcpListRequirementBased] =
        useState<any>([]);
    const [floatHcpListRequirementBased, setFloatHcpListRequirementBased] =
        useState<any>([]);
    const [
        modifiedInternalHcpListRequirementBased,
        setModifiedInternalHcpListRequirementBased,
    ] = useState<any>([]);
    const [
        modifiedFloatHcpListRequirementBased,
        setModifiedFloatHcpListRequirementBased,
    ] = useState<any>([]);
    const [createdRequirementList, setCreatedRequirementList] =
        useState<any>(null);
    const [isInternalValueSelected, setIsInternalValueSelected] = useState(false);
    const [isFloatValueSelected, setIsFloatValueSelected] = useState(false);
    const [selectedShiftRequirementType, setSelectedShiftRequirementType] =
        useState<any>('open-shift');
    const [isApprovedShiftCreationLoading, setIsApprovedShiftCreationLoading] =
        useState(false);
    const [timesheetFile, setTimesheetFile] = React.useState<any>([]);
    const [restructedShiftTimings, setRestructedShiftTimings] = useState<any>([]);
    const {staffSpecialityList} = useSelector(
        (state: IRootReducerState) => state.meta,
    );

    //  const [completedApiCall, setCompletedApiCall] = useState(0);

    const handleAgencyTypeSelection = (
        event: SelectChangeEvent<typeof selectedAgency>,
    ) => {
        // @ts-ignore
        setSelectedAgency(event.target.value);
    };

    const removeFile = (index: number) => {
        // Create a new array excluding the file at the given index
        const newTimesheetFile = [...timesheetFile];
        newTimesheetFile.splice(index, 1);
        // Update the timesheetFile state with the new array
        setTimesheetFile(newTimesheetFile);
    };

    const getFacilityDetails = useCallback(() => {
        const payload = {};
        CommonService._facility
            .FacilityDetailsAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                console.log(response.data);
                setSelectedFacilityDetails(response.data);
            })
            .catch((error: any) => {
            })
            .finally(() => {
            });
    }, []);

    useEffect(() => {
        if (selectedShiftRequirementType === 'open-shift') {
            getFacilityDetails();
        }
    }, [getFacilityDetails, selectedShiftRequirementType]);

    useEffect(() => {
        if (wingsList) {
            const modifiedData = wingsList?.map((item: any) => {
                const {_id, name, ...rest} = item; // Destructure the "name" property and keep the rest of the object

                return {
                    ...rest, // Keep the other properties unchanged
                    title: name,
                    code: _id, // Add the new key "newName" with the value of the old "name"
                };
            });
            setModifiedWingsList(modifiedData);
        } else {
            setModifiedWingsList(null);
        }
    }, [wingsList]);

    const onSubmit = useCallback(
        (values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
            const formData = new FormData();

            timesheetFile.forEach((file: any, index: number) => {
                // Append each file with the same key ('image')
                formData.append('image', file);
            });

            let shift_dates;

            if (!isShiftCreationForSingleDate) {
                shift_dates = value.map((item: any) => {
                    let mm = item?.month?.number;
                    let dd = item?.day;
                    let yyyy = item?.year;
                    return moment(`${yyyy}-${mm}-${dd}`).format('YYYY-MM-DD');
                });
            } else {
                shift_dates = [CommonService.formatDate(selectedDateFromCalender?._d)];
            }
            formData.append('facility_wing_id', values.facility_wing_id || '');

            shift_dates?.forEach((item: any) => {
                formData.append('shift_dates[]', item);
            });

            formData.append('shift_type', values.shift_type);

            formData.append('required_hcp_count', values.required_hcp_count || '');

            formData.append('hcp_type_id', values.hcp_type_id);

            formData.append('hcp_speciality_id', values.hcp_speciality_id || '');

            formData.append('shift_rate', values.shift_rate || 0);

            formData.append('differential_rate', values.differential_rate || '');

            formData.append('ot_pay', values.ot_pay || '');

            formData.append('rush_rate', values.rush_rate || '');

            formData.append('weekend_rate', values.weekend_rate || '');

            formData.append('noc_rate', values.noc_rate || '');

            formData.append('pm_rate', values.pm_rate || '');
            formData.append(
                'send_to.specific_agencies',
                // @ts-ignore
                Boolean(values.send_to.specific_agencies),
            );
            formData.append(
                'send_to.is_internal',
                // @ts-ignore
                Boolean(values.send_to.is_internal),
            );
            // @ts-ignore
            formData.append('send_to.is_float', Boolean(values.send_to.is_float));
            // @ts-ignore

            formData.append('start_time', values.start_time);
            // @ts-ignore
            formData.append('end_time', values.end_time);

            formData.append('facility_id', values.facility_id);

            formData.append('note', values.note || '');

            selectedAgency?.forEach((item: any) => {
                formData.append('allowed_agency_ids[]', item.code);
            });

            formData.append('requirementType', 'Open Shift');
            formData.append('base_shift_rate',values?.base_shift_rate || 0);
            formData.append('hcp_hazard_rate',values?.hcp_hazard_rate || 0);
            formData.append('hcp_noc_diff',values?.hcp_noc_diff || 0);
            formData.append('hcp_pm_diff',values?.hcp_pm_diff || 0);
            formData.append('hcp_weekend_rate',values?.hcp_weekend_rate || 0);
            formData.append('hcp_rush_rate',values?.hcp_rush_rate || 0);
            formData.append('is_hazard',values?.is_hazard);
            formData.append('is_noc_diff',values?.is_noc_diff);
            formData.append('is_pm_diff',values?.is_pm_diff);
            formData.append('is_weekend',values?.is_weekend);
            formData.append('is_rush',values?.is_rush);
            formData.append('is_holiday',values?.is_holiday);

            let apiCall: any = {};
            apiCall =
                CommonService._shift_management.addNewOpenRequirementShiftAPICall(
                    formData,
                );
            apiCall
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'success',
                    );
                    if (values?.send_to?.is_internal || values?.send_to?.is_float) {
                        setIsInternalHcp(true);
                        setShiftTypeHeader('Create Approved Shift');
                        setCreatedRequirementList(response?.data);
                        if (values?.send_to?.is_internal && !values?.send_to?.is_float) {
                            setIsInternalValueSelected(true);
                        } else if (
                            values?.send_to?.is_float &&
                            !values?.send_to?.is_internal
                        ) {
                            setIsFloatValueSelected(true);
                        } else if (
                            values?.send_to?.is_float &&
                            values?.send_to?.is_internal
                        ) {
                            setIsInternalValueSelected(true);
                            setIsFloatValueSelected(true);
                        }
                    } else {
                        if (onCalender) {
                            setOpenDialog(false);
                            setSchedulingListFilterState({...schedulingListFilterState});
                            // navigate(CommonService._routeConfig.SchedulerRoute());
                        } else {
                            navigate(CommonService._routeConfig.ShiftManagementRoute());
                        }
                    }
                })
                .catch((error: any) => {
                    CommonService.handleErrors(setErrors, error);
                    CommonService._alert.showToast(error?.error, 'error');
                })
                .finally(() => {
                    setSubmitting(false);
                });
            // eslint-disable-next-line
        },
        [
            selectedAgency,
            navigate,
            value,
            onCalender,
            selectedDateFromCalender,
            isShiftCreationForSingleDate,
            schedulingListFilterState,
            timesheetFile,
            setOpenDialog,
            setShiftTypeHeader,
            setSchedulingListFilterState,
        ],
    );

    function handleDatePicker(value: any) {
        // console.log(value);
        setValue(value);
    }

    const handleInternalHCPListSubmit = useCallback(() => {
        const payload: any = {
            //  agency_id: selectedAgencyForHcp?.selectedAgency
        };
        payload.is_float = false;
        payload.is_internal_hcp = true;
        payload.hcp_type_id = selectedHcpType?.code;
        CommonService._hcp
            .HcpListRequirementBasedAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                setInternalHcpListRequirementBased(response?.data);
            })
            .catch((error: any) => {
            })
            .finally(() => {
            });
    }, [selectedHcpType]);

    const handleFloatHcpListSubmit = useCallback(() => {
        const payload: any = {
            //  agency_id: selectedAgencyForHcp?.selectedAgency
        };
        payload.is_float = true;
        payload.is_internal_hcp = false;
        payload.hcp_type_id = selectedHcpType?.code;
        CommonService._hcp
            .HcpListRequirementBasedAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                setFloatHcpListRequirementBased(response?.data);
            })
            .catch((error: any) => {
            })
            .finally(() => {
            });
    }, [selectedHcpType]);

    useEffect(() => {
        if (selectedShiftRequirementType === 'open-shift') {
            handleInternalHCPListSubmit();
            handleFloatHcpListSubmit();
        }
    }, [
        handleFloatHcpListSubmit,
        handleInternalHCPListSubmit,
        selectedShiftRequirementType,
    ]);

    useEffect(() => {
        if (internalHcpListRequirementBased) {
            const modifiedData = internalHcpListRequirementBased?.map((item: any) => {
                const {_id, first_name, last_name, ...rest} = item; // Destructure the "name" property and keep the rest of the object

                return {
                    ...rest, // Keep the other properties unchanged
                    title: first_name + ' ' + last_name,
                    code: _id, // Add the new key "newName" with the value of the old "name"
                };
            });
            setModifiedInternalHcpListRequirementBased(modifiedData);
        } else {
            setModifiedInternalHcpListRequirementBased(null);
        }
    }, [internalHcpListRequirementBased]);

    useEffect(() => {
        if (floatHcpListRequirementBased) {
            const modifiedData = floatHcpListRequirementBased?.map((item: any) => {
                const {_id, first_name, last_name, ...rest} = item; // Destructure the "name" property and keep the rest of the object

                return {
                    ...rest, // Keep the other properties unchanged
                    title: first_name + ' ' + last_name,
                    code: _id, // Add the new key "newName" with the value of the old "name"
                };
            });
            setModifiedFloatHcpListRequirementBased(modifiedData);
        } else {
            setModifiedFloatHcpListRequirementBased(null);
        }
    }, [floatHcpListRequirementBased]);

    const handleInternalHcpSelect = useCallback((event: any) => {
        setSelectedInternalHcp(event.target.value);
    }, []);

    const handleFloatHcpSelect = useCallback((event: any) => {
        setSelectedFloatHcp(event.target.value);
    }, []);

    const handleCreateApprovedShiftForInternalStaff = useCallback(
        (requirementId: any) => {

            const codes = [...selectedFloatHcp, ...selectedInternalHcp].map(
                (item: any) => item.code,
            );
            const payload = {
                applications: codes.map((code) => ({hcp_id: code})),
            };


            CommonService._shift_management
                .addNewApprovedShiftsForSelectedInternalStaff(requirementId, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'success',
                    );
                    if (onCalender) {
                        navigate(CommonService._routeConfig.SchedulerRoute());
                    } else {
                        navigate(CommonService._routeConfig.ShiftManagementRoute());
                    }
                })
                .catch((error: any) => {
                    // Handle error
                })
                .finally(() => {
                    // Set loading state to false after API call completes
                });
        },
        [navigate, selectedFloatHcp, selectedInternalHcp, onCalender],
    );

    const handlingInternalStaffApprovedShifts = useCallback(() => {
        console.log('handling internal staff approved', [createdRequirementList]?.length);
        setIsApprovedShiftCreationLoading(false); // Set loading state to true when handling internal staff approved shifts
        // Assuming createdRequirementList is an array
        Promise.all(
            [createdRequirementList]?.map((application: any) =>
                handleCreateApprovedShiftForInternalStaff(application?._id),
            ),
        ).then(() => {
            setIsApprovedShiftCreationLoading(true); // Set loading state to false after all API calls complete
        });
    }, [createdRequirementList, handleCreateApprovedShiftForInternalStaff]);

    const shiftTimings =
        selectedFacilityDetails?.shift_timings?.map(
            (item: { shift_type: any }) => item.shift_type,
        ) || [];

    const filteredShifts = shift_type?.filter((shift: { code: any }) =>
        shiftTimings.includes(shift?.code),
    );

    useEffect(() => {
        if (selectedShiftType) {
            const restructuredData = selectedFacilityDetails?.shift_timings
                ?.filter(
                    (item: { shift_type: any }) => selectedShiftType === item?.shift_type,
                )
                ?.map(
                    (item: {
                        shift_start_time: any;
                        shift_end_time: any;
                        shift_type: any;
                    }) => ({
                        title: `${CommonService.convertMinutesToTime(
                            item.shift_start_time,
                        )} - ${CommonService.convertMinutesToTime(item.shift_end_time)} (${
                            item.shift_type
                        }-Shift)`,
                        timings: {
                            start_time: item.shift_start_time,
                            end_time: item.shift_end_time,
                        },
                    }),
                );
            setRestructedShiftTimings(restructuredData);
            console.log(restructuredData);
        } else {
            setRestructedShiftTimings([]);
        }
    }, [selectedFacilityDetails, selectedShiftType]);

    return (
        <div
            className={
                'shift-requirement-details-screen details-screen add-screen dialog-large-width'
            }
        >
            {!isInternalHcp && (
                <div className={'pdd-right-25 pdd-left-25 mrg-top-20'}>
                    <RadioButtonGroupComponent
                        options={[
                            {
                                title: 'Per Diem',
                                code: 'open-shift',
                            },
                            {title: 'Per Job/Visit', code: 'home-health-hospice'},
                            {title: 'Contract / Travel', code: 'contract-travel'},
                        ]}
                        valueExtractor={(option: any) => option.code}
                        onChange={(e) => {
                            setSelectedShiftRequirementType(e);
                            setSelectedHcpType(null);
                            setSelectedShiftType(null);
                            setValue(null);
                            setSelectedAgency([]);
                            setIsAgencySelected(false);
                        }}
                        value={selectedShiftRequirementType}
                    />
                </div>
            )}
            <>
                {!isInternalHcp && selectedShiftRequirementType === 'open-shift' && (
                    <>
                        <Formik
                            validationSchema={shiftDetailsValidationSchema}
                            initialValues={initialValues}
                            validateOnChange={false}
                            validateOnBlur={true}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={onSubmit}
                        >
                            {({
                                  touched,
                                  values,
                                  errors,
                                  validateForm,
                                  isValid,
                                  setFieldValue,
                                  isSubmitting,
                                  handleBlur,
                              }) => {
                                // eslint-disable-next-line react-hooks/rules-of-hooks
                                useEffect(() => {
                                    validateForm();
                                }, [validateForm, values]);
                                return (
                                    <Form className='t-form scroll-content' noValidate={true}>
                                        {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                                        <div className='add-component-content'>
                                            <>
                                                <div className={'body-min-height'}>
                                                    <div className={'requirement-form-body-wrapper '}>
                                                        <div className={'form-header-text mrg-bottom-10'}>
                                                            Enter Shift Details
                                                        </div>
                                                        <div className={'ts-row'}>
                                                            <div className='ts-col-3'>
                                                                <Field name={'facility_wing_id'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={modifiedWingsList}
                                                                            displayWith={(option: any) =>
                                                                                option.title
                                                                            }
                                                                            valueExtractor={(option: any) =>
                                                                                option.code
                                                                            }
                                                                            label={'Select Wing'}
                                                                            placeholder={'Select'}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className='ts-col-3'>
                                                                {!isShiftCreationForSingleDate && (
                                                                    <div>
                                                                        <LabelComponent title={'Shift Dates*'}/>
                                                                        <div className='shift-calender mrg-bottom-20'>
                                                                            <Field
                                                                                required={true}
                                                                                onUpdate={(e: any) => {
                                                                                    setFieldValue('shift_dates', []);
                                                                                }}
                                                                                inputClass='custom-input'
                                                                                className='rmdp-mobile'
                                                                                plugins={[
                                                                                    <DatePanel eachDaysInRange/>,
                                                                                ]}
                                                                                format='MM/DD/YYYY'
                                                                                //  range={mode === "range" ? true : false}
                                                                                multiple={true}
                                                                                onChange={handleDatePicker}
                                                                                value={value}
                                                                                variant='inline'
                                                                                inputVariant='outlined'
                                                                                placeholder={'Select'}
                                                                                id='input_shift_requirement_shift_datepicker'
                                                                                name='shift_dates'
                                                                                InputLabelProps={{shrink: true}}
                                                                                component={DatePickers}
                                                                                minDate={new Date()}
                                                                                maxDate={sixMonthsFromNow}
                                                                            />
                                                                            <div
                                                                                className={
                                                                                    'd-flex justify-content-right'
                                                                                }
                                                                            >
                                                                                {touched.shift_dates &&
                                                                                (!value ||
                                                                                    (value && value?.length === 0)) ? (
                                                                                    // <div>{errors.hcp_type_id}</div>
                                                                                    <ErrorComponent
                                                                                        errorText={
                                                                                            'Shift Dates is required'
                                                                                        }
                                                                                    />
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {isShiftCreationForSingleDate && (
                                                                    <div>
                                                                        <LabelComponent title={'Shift Date*'}/>
                                                                        <div className='shift-calender mrg-bottom-20'>
                                                                            <Field name={'shift_dates'}>
                                                                                {(field: FieldProps) => (
                                                                                    <FormikDatePickerComponent
                                                                                        // minDate={new Date()}
                                                                                        disabled={true}
                                                                                        placeholder={'Select Date'}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='ts-col-3'>
                                                                <Field name={'shift_type'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={filteredShifts}
                                                                            displayWith={(option: any) =>
                                                                                option.title
                                                                            }
                                                                            valueExtractor={(option: any) =>
                                                                                option.code
                                                                            }
                                                                            label={'Shift Type*'}
                                                                            placeholder={'Select'}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                            onUpdate={(e: any) => {
                                                                                setSelectedShiftType(e);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className='ts-col-3'>
                                                                <Field name={'required_hcp_count'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Open Positions*'}
                                                                            placeholder={'max 99'}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                            type={'number'}
                                                                            maxLength={2}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={
                                                                'shift-requirement-divider-line mrg-bottom-10'
                                                            }
                                                        />

                                                        <div className='ts-row'>
                                                            <div className='ts-col-3'>
                                                                <Field name={'hcp_type_id'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={HcpTypesList}
                                                                            displayWith={(option: any) =>
                                                                                option.title
                                                                            }
                                                                            valueExtractor={(option: any) =>
                                                                                option.code
                                                                            }
                                                                            label={'Staff Type*'}
                                                                            placeholder={'Select'}
                                                                            formikField={field}
                                                                            searchable={true}
                                                                            fullWidth={true}
                                                                            onUpdate={(val: any) => {
                                                                                if (val) {
                                                                                    dispatch(getStaffSpecialityList(val));
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className='ts-col-3'>
                                                                <Field name={'hcp_speciality_id'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={staffSpecialityList}
                                                                            displayWith={(option: any) =>
                                                                                option.title
                                                                            }
                                                                            valueExtractor={(option: any) =>
                                                                                option.code
                                                                            }
                                                                            label={'Staff Speciality'}
                                                                            disabled={!values?.hcp_type_id}
                                                                            placeholder={'Select'}
                                                                            formikField={field}
                                                                            searchable={true}
                                                                            fullWidth={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className='ts-col-6'>
                                                                <Field name={'shift_timings'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={restructedShiftTimings}
                                                                            displayWith={(option: any) =>
                                                                                option.title
                                                                            }
                                                                            valueExtractor={(option: any) =>
                                                                                option.title
                                                                            }
                                                                            label={'Select Shift Timings and Type*'}
                                                                            placeholder={'Select'}
                                                                            noDataMessage={
                                                                                'Please select the shift Type'
                                                                            }
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                            onUpdate={(e: any) => {
                                                                                const [
                                                                                    startTimeString,
                                                                                    endTimeString,
                                                                                ] = e
                                                                                    ?.match(/\d+:\d+\s(?:AM|PM)/g)
                                                                                    ?.map((time: any) =>
                                                                                        CommonService.convertTimeToMinutes(
                                                                                            time,
                                                                                        ),
                                                                                    );
                                                                                setFieldValue(
                                                                                    'start_time',
                                                                                    startTimeString,
                                                                                );
                                                                                setFieldValue(
                                                                                    'end_time',
                                                                                    endTimeString,
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            {/*<div className='ts-col-3'>*/}
                                                            {/*    <Field name={'shift_rate'}>*/}
                                                            {/*        {(field: FieldProps) => (*/}
                                                            {/*            <FormikInputComponent*/}
                                                            {/*                label={'Shift Rate'}*/}
                                                            {/*                placeholder={'Enter value ($/hr)'}*/}
                                                            {/*                formikField={field}*/}
                                                            {/*                fullWidth={true}*/}
                                                            {/*                type={'number'}*/}
                                                            {/*                validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}*/}
                                                            {/*            />*/}
                                                            {/*        )}*/}
                                                            {/*    </Field>*/}
                                                            {/*</div>*/}
                                                            {/*<div className='ts-col-3'>*/}
                                                            {/*    <Field name={'differential_rate'}>*/}
                                                            {/*        {(field: FieldProps) => (*/}
                                                            {/*            <FormikInputComponent*/}
                                                            {/*                label={'Differential Rate'}*/}
                                                            {/*                placeholder={'Enter value ($/hr)'}*/}
                                                            {/*                formikField={field}*/}
                                                            {/*                fullWidth={true}*/}
                                                            {/*                type={'number'}*/}
                                                            {/*                validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}*/}
                                                            {/*            />*/}
                                                            {/*        )}*/}
                                                            {/*    </Field>*/}
                                                            {/*</div>*/}
                                                        </div>

                                                        <div
                                                            className={
                                                                'shift-requirement-divider-line mrg-bottom-20'
                                                            }
                                                        />

                                                        {/*<div>*/}
                                                        {/*    <ShiftRateComponent values={values}*/}
                                                        {/*                        setFieldValue={setFieldValue}/>*/}
                                                        {/*</div>*/}

                                                        {/*<div className={'ts-row'}>*/}
                                                        {/*    <div className='ts-col-3'>*/}
                                                        {/*        <Field name={'ot_pay'}>*/}
                                                        {/*            {(field: FieldProps) => (*/}
                                                        {/*                <FormikSelectDropdownComponent*/}
                                                        {/*                    options={OTpayOption}*/}
                                                        {/*                    displayWith={(option: any) =>*/}
                                                        {/*                        option.title*/}
                                                        {/*                    }*/}
                                                        {/*                    valueExtractor={(option: any) =>*/}
                                                        {/*                        option.code*/}
                                                        {/*                    }*/}
                                                        {/*                    label={'OT Pay'}*/}
                                                        {/*                    placeholder={'Select'}*/}
                                                        {/*                    formikField={field}*/}
                                                        {/*                    fullWidth={true}*/}
                                                        {/*                />*/}
                                                        {/*            )}*/}
                                                        {/*        </Field>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className='ts-col-3'>*/}
                                                        {/*        <Field name={'rush_rate'}>*/}
                                                        {/*            {(field: FieldProps) => (*/}
                                                        {/*                <FormikInputComponent*/}
                                                        {/*                    label={'Rush Rate'}*/}
                                                        {/*                    placeholder={'Enter value ($/hr)'}*/}
                                                        {/*                    formikField={field}*/}
                                                        {/*                    fullWidth={true}*/}
                                                        {/*                    type={'number'}*/}
                                                        {/*                    validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}*/}
                                                        {/*                />*/}
                                                        {/*            )}*/}
                                                        {/*        </Field>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className='ts-col-3'>*/}
                                                        {/*        <Field name={'weekend_rate'}>*/}
                                                        {/*            {(field: FieldProps) => (*/}
                                                        {/*                <FormikInputComponent*/}
                                                        {/*                    label={'Weekend  Rate'}*/}
                                                        {/*                    placeholder={'Enter value ($/hr)'}*/}
                                                        {/*                    formikField={field}*/}
                                                        {/*                    fullWidth={true}*/}
                                                        {/*                    validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}*/}
                                                        {/*                    type={'number'}*/}
                                                        {/*                />*/}
                                                        {/*            )}*/}
                                                        {/*        </Field>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className='ts-col-3'>*/}
                                                        {/*        <Field name={'pm_rate'}>*/}
                                                        {/*            {(field: FieldProps) => (*/}
                                                        {/*                <FormikInputComponent*/}
                                                        {/*                    label={'PM Rate'}*/}
                                                        {/*                    placeholder={'Enter value ($/hr)'}*/}
                                                        {/*                    formikField={field}*/}
                                                        {/*                    fullWidth={true}*/}
                                                        {/*                    type={'number'}*/}
                                                        {/*                    validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}*/}
                                                        {/*                />*/}
                                                        {/*            )}*/}
                                                        {/*        </Field>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}

                                                        {/*<div className={'ts-row'}>*/}
                                                        {/*    <div className='ts-col-3'>*/}
                                                        {/*        <Field name={'noc_rate'}>*/}
                                                        {/*            {(field: FieldProps) => (*/}
                                                        {/*                <FormikInputComponent*/}
                                                        {/*                    label={'NOC Rate'}*/}
                                                        {/*                    placeholder={'Enter value ($/hr)'}*/}
                                                        {/*                    formikField={field}*/}
                                                        {/*                    fullWidth={true}*/}
                                                        {/*                    type={'number'}*/}
                                                        {/*                    validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}*/}
                                                        {/*                />*/}
                                                        {/*            )}*/}
                                                        {/*        </Field>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className='ts-col-9'>*/}
                                                        {/*        <Field name={'shift_timings'}>*/}
                                                        {/*            {(field: FieldProps) => (*/}
                                                        {/*                <FormikSelectDropdownComponent*/}
                                                        {/*                    options={restructedShiftTimings}*/}
                                                        {/*                    displayWith={(option: any) =>*/}
                                                        {/*                        option.title*/}
                                                        {/*                    }*/}
                                                        {/*                    valueExtractor={(option: any) =>*/}
                                                        {/*                        option.title*/}
                                                        {/*                    }*/}
                                                        {/*                    label={'Select Shift Timings and Type*'}*/}
                                                        {/*                    placeholder={'Select'}*/}
                                                        {/*                    noDataMessage={*/}
                                                        {/*                        'Please select the shift Type'*/}
                                                        {/*                    }*/}
                                                        {/*                    formikField={field}*/}
                                                        {/*                    fullWidth={true}*/}
                                                        {/*                    onUpdate={(e: any) => {*/}
                                                        {/*                        const [startTimeString, endTimeString] =*/}
                                                        {/*                            e*/}
                                                        {/*                                ?.match(/\d+:\d+\s(?:AM|PM)/g)*/}
                                                        {/*                                ?.map((time: any) =>*/}
                                                        {/*                                    CommonService.convertTimeToMinutes(*/}
                                                        {/*                                        time,*/}
                                                        {/*                                    ),*/}
                                                        {/*                                );*/}
                                                        {/*                        setFieldValue(*/}
                                                        {/*                            'start_time',*/}
                                                        {/*                            startTimeString,*/}
                                                        {/*                        );*/}
                                                        {/*                        setFieldValue(*/}
                                                        {/*                            'end_time',*/}
                                                        {/*                            endTimeString,*/}
                                                        {/*                        );*/}
                                                        {/*                    }}*/}
                                                        {/*                />*/}
                                                        {/*            )}*/}
                                                        {/*        </Field>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}

                                                        {/*<div*/}
                                                        {/*    className={*/}
                                                        {/*        'shift-requirement-divider-line mrg-bottom-10 mrg-top-20'*/}
                                                        {/*    }*/}
                                                        {/*/>*/}

                                                        <div className='ts-row'>
                                                            <div className='ts-col-12'>
                                                                <Field name={'note'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikTextAreaComponent
                                                                            label={'Note'}
                                                                            placeholder={'Enter note'}
                                                                            rows={3}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={
                                                                'shift-requirement-divider-line mrg-bottom-10'
                                                            }
                                                        />

                                                        <div
                                                            className={
                                                                'document-upload-wrapper d-flex justify-content-space-between mrg-top-10'
                                                            }
                                                        >
                                                            <div>
                                                                <div className={'form-header-text'}>
                                                                    Upload Documents
                                                                </div>
                                                                <div className={'file-upload-supported-text'}>
                                                                    Upload the relevant documents
                                                                </div>
                                                                <div className={'file-upload-supported-text'}>
                                                                    Supported formats : .pdf, .jpg, .jpeg, .png
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <FilePickerComponent
                                                                    maxFileCount={3}
                                                                    height={'470px'}
                                                                    id={'sc_upload_btn'}
                                                                    btnText={'Select Files'}
                                                                    disabled={timesheetFile?.length === 3}
                                                                    multiple={true}
                                                                    showDropZone={false}
                                                                    onFilesDrop={(
                                                                        acceptedFiles: any,
                                                                        rejectedFiles: any,
                                                                    ) => {
                                                                        if (
                                                                            acceptedFiles &&
                                                                            acceptedFiles.length > 0
                                                                        ) {
                                                                            const file = acceptedFiles[0];
                                                                            //   setTimesheetFile(file);
                                                                            setTimesheetFile([
                                                                                ...timesheetFile,
                                                                                file,
                                                                            ]);
                                                                        }
                                                                    }}
                                                                    acceptedFileTypes={[
                                                                        'pdf',
                                                                        'jpeg',
                                                                        'jpg',
                                                                        'png',
                                                                    ]}
                                                                    uploadDescription={'(upload only one file)'}
                                                                    acceptedFilesText={
                                                                        'Only .pdf files are supported'
                                                                    }
                                                                />
                                                                <div
                                                                    className={
                                                                        'd-flex justify-content-end mrg-top-5 file-upload-supported-text'
                                                                    }
                                                                >
                                                                    Max upload : 3
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'mrg-top-10 d-flex-wrap'}>
                                                            {timesheetFile &&
                                                                timesheetFile?.map(
                                                                    (file: any, index: number) => {
                                                                        return (
                                                                            <>
                                                                                <div
                                                                                    className={
                                                                                        'd-flex justify-content-space-between file-holder-wrapper mrg-right-10 mrg-10'
                                                                                    }
                                                                                >
                                                                                    <div> {file?.name}</div>
                                                                                    <div
                                                                                        className={'icon-button'}
                                                                                        onClick={() => {
                                                                                            removeFile(index);
                                                                                        }}
                                                                                    >
                                                                                        <ImageConfig.DeleteIcon/>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    },
                                                                )}
                                                        </div>

                                                        <div
                                                            className={
                                                                'shift-requirement-divider-line mrg-bottom-10 mrg-top-10'
                                                            }
                                                        />

                                                        <LabelComponent
                                                            title={'Send Shift Req to'}
                                                            isBold={true}
                                                        />
                                                        <div className='ts-row mrg-top-10'>
                                                            <div className='ts-col-4'>
                                                                <Field name={'send_to.is_internal'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikCheckBoxComponent
                                                                            formikField={field}
                                                                            label={'Internal HCP'}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className='ts-col-4'>
                                                                <Field name={'send_to.is_float'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikCheckBoxComponent
                                                                            formikField={field}
                                                                            label={'Floating HCP'}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className='ts-col-4'>
                                                                <Field name={'send_to.specific_agencies'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikCheckBoxComponent
                                                                            formikField={field}
                                                                            label={'Agency'}
                                                                            onChange={(e) => {
                                                                                setIsAgencySelected(e);
                                                                                if (e) {
                                                                                    const allAgencyCodes = agencyList.map(
                                                                                        (item: any) => item,
                                                                                    );
                                                                                    setSelectedAgency(allAgencyCodes);
                                                                                } else {
                                                                                    setSelectedAgency([]);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        {isAgencySelected && (
                                                            <FormControl
                                                                sx={{
                                                                    m: 1,
                                                                    minWidth: '818px',
                                                                    maxWidth: '818px',
                                                                    padding: '10px',
                                                                }}
                                                            >
                                                                {selectedAgency?.length === 0 && (
                                                                    <div
                                                                        className={
                                                                            'material-select-box-placeholder-custom '
                                                                        }
                                                                    >
                                                                        Select Agencies
                                                                    </div>
                                                                )}
                                                                <Select
                                                                    labelId='demo-multiple-checkbox-label'
                                                                    id='demo-multiple-checkbox'
                                                                    multiple
                                                                    value={selectedAgency}
                                                                    onChange={handleAgencyTypeSelection}
                                                                    // input={<OutlinedInput label="Select Agency"/>}
                                                                    renderValue={(selected) =>
                                                                        selected
                                                                            .map((item: any) => item?.title)
                                                                            .join(', ')
                                                                    }
                                                                    MenuProps={MenuProps}
                                                                >
                                                                    {agencyList.map((item: any) => (
                                                                        <MenuItem key={item.code} value={item}>
                                                                            <Checkbox
                                                                                style={{
                                                                                    color: '#202937',
                                                                                }}
                                                                                checked={selectedAgency.some(
                                                                                    (selectedItem: any) =>
                                                                                        selectedItem.code === item.code,
                                                                                )}
                                                                            />
                                                                            <ListItemText primary={item.title}/>
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                            <div
                                                className={
                                                    'mrg-top-15 d-flex ts-justify-content-center'
                                                }
                                            >
                                                <ButtonComponent
                                                    disabled={isSubmitting || !isValid}
                                                    isLoading={isSubmitting}
                                                    type={'submit'}
                                                    color={'download-button'}
                                                    suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                                >
                                                    Create Shift
                                                </ButtonComponent>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </>
                )}
                {!isInternalHcp &&
                    selectedShiftRequirementType === 'home-health-hospice' && (
                        <HomeHealthHospiceTypeShiftRequirement
                            setSelectedHcpType={setSelectedHcpType}
                            isShiftCreationForSingleDate={isShiftCreationForSingleDate}
                            selectedHcpType={selectedHcpType}
                            sixMonthsFromNow={sixMonthsFromNow}
                            getFacilityDetails={getFacilityDetails}
                            handleInternalHCPListSubmit={handleInternalHCPListSubmit}
                            handleFloatHcpListSubmit={handleFloatHcpListSubmit}
                            setIsInternalHcp={setIsInternalHcp}
                            setCreatedRequirementList={setCreatedRequirementList}
                            setIsInternalValueSelected={setIsInternalValueSelected}
                            setIsFloatValueSelected={setIsFloatValueSelected}
                            setShiftTypeHeader={setShiftTypeHeader}
                            selectedDateFromCalender={selectedDateFromCalender}
                            onCalender={onCalender}
                            setOpenDialog={setOpenDialog}
                            setSchedulingListFilterState={setSchedulingListFilterState}
                            schedulingListFilterState={schedulingListFilterState}
                        />
                    )}

                {!isInternalHcp &&
                    selectedShiftRequirementType === 'contract-travel' && (
                        <>
                            <ContractTravelComponent
                                setSelectedHcpType={setSelectedHcpType}
                                isShiftCreationForSingleDate={isShiftCreationForSingleDate}
                                selectedHcpType={selectedHcpType}
                                sixMonthsFromNow={sixMonthsFromNow}
                                getFacilityDetails={getFacilityDetails}
                                handleInternalHCPListSubmit={handleInternalHCPListSubmit}
                                handleFloatHcpListSubmit={handleFloatHcpListSubmit}
                                setIsInternalHcp={setIsInternalHcp}
                                setCreatedRequirementList={setCreatedRequirementList}
                                setIsInternalValueSelected={setIsInternalValueSelected}
                                setIsFloatValueSelected={setIsFloatValueSelected}
                                setShiftTypeHeader={setShiftTypeHeader}
                                selectedDateFromCalender={selectedDateFromCalender}
                                onCalender={onCalender}
                                setOpenDialog={setOpenDialog}
                                setSchedulingListFilterState={setSchedulingListFilterState}
                                schedulingListFilterState={schedulingListFilterState}
                            />
                        </>
                    )}

                {isInternalHcp && (
                    <>
                        <div className='ts-row pdd-20'>
                            {isInternalValueSelected && (
                                <div className='ts-col-4'>
                                    <CheckBoxComponent
                                        label={'Internal HCP'}
                                        checked={isInternalSelected}
                                        onChange={(e) => {
                                            setIsInternalSelected(e);
                                            if (!e) {
                                                //   setIsInternalSelected(false);
                                                setSelectedInternalHcp([]);
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            {isFloatValueSelected && (
                                <div className='ts-col-4'>
                                    <CheckBoxComponent
                                        checked={isFloatSelected}
                                        label={'Floating HCP'}
                                        onChange={(e) => {
                                            setIsFloatSelected(e);
                                            if (!e) {
                                                //   setIsInternalSelected(false);
                                                setSelectedFloatHcp([]);
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={'pdd-right-20 pdd-left-20'}>
                            {isInternalSelected && (
                                <>
                                    <div className={'mrg-bottom-10'}>
                                        <FormControl sx={{m: 1, width: '100%'}}>
                                            {selectedInternalHcp?.length === 0 && (
                                                <div
                                                    className={'material-select-box-placeholder-custom '}
                                                >
                                                    Select Internal HCP
                                                </div>
                                            )}
                                            <Select
                                                labelId='demo-multiple-checkbox-label'
                                                id='demo-multiple-checkbox'
                                                multiple
                                                value={selectedInternalHcp}
                                                onChange={handleInternalHcpSelect}
                                                //    input={<OutlinedInput label="Select Internal HCP"/>}
                                                renderValue={(selected) =>
                                                    selected.map((item: any) => item.title).join(', ')
                                                }
                                                MenuProps={MenuProps}
                                            >
                                                {modifiedInternalHcpListRequirementBased.map(
                                                    (item: any) => (
                                                        <MenuItem key={item.code} value={item}>
                                                            <Checkbox
                                                                style={{
                                                                    color: '#202937',
                                                                }}
                                                                checked={selectedInternalHcp.some(
                                                                    (selectedItem: any) =>
                                                                        selectedItem.code === item.code,
                                                                )}
                                                            />
                                                            <ListItemText primary={item.title}/>
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </>
                            )}
                            {isFloatSelected && (
                                <>
                                    <FormControl sx={{m: 1, width: '100%'}}>
                                        {selectedFloatHcp?.length === 0 && (
                                            <div
                                                className={'material-select-box-placeholder-custom '}
                                            >
                                                Select Float HCP
                                            </div>
                                        )}
                                        <Select
                                            labelId='demo-multiple-checkbox-label'
                                            id='demo-multiple-checkbox'
                                            multiple
                                            value={selectedFloatHcp}
                                            onChange={handleFloatHcpSelect}
                                            //  input={<OutlinedInput label="Select Float HCP"/>}
                                            renderValue={(selected) =>
                                                selected.map((item: any) => item.title).join(', ')
                                            }
                                            MenuProps={MenuProps}
                                        >
                                            {modifiedFloatHcpListRequirementBased.map((item: any) => (
                                                <MenuItem key={item.code} value={item}>
                                                    <Checkbox
                                                        style={{
                                                            color: '#202937',
                                                        }}
                                                        checked={selectedFloatHcp.some(
                                                            (selectedItem: any) =>
                                                                selectedItem.code === item.code,
                                                        )}
                                                    />
                                                    <ListItemText primary={item.title}/>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </>
                            )}
                            <div
                                className={
                                    'ts-row mrg-top-15 mrg-bottom-20 d-flex justify-content-center align-items-center'
                                }
                            >
                                <ButtonComponent
                                    color={'download-button'}
                                    isLoading={isApprovedShiftCreationLoading}
                                    disabled={
                                        (!isInternalSelected && !isFloatSelected) ||
                                        isApprovedShiftCreationLoading ||
                                        (selectedFloatHcp?.length === 0 &&
                                            selectedInternalHcp?.length === 0)
                                    }
                                    suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                    onClick={handlingInternalStaffApprovedShifts}
                                >
                                    Create Approved Shift
                                </ButtonComponent>
                            </div>
                        </div>
                    </>
                )}
            </>
        </div>
    );
};

export default AddShiftDetailsComponent;
