import "./AddAgencyScreenComponent.scss";
import {ImageConfig, Misc} from "../../../../constants";
import {CommonService} from "../../../../shared/services";
import {useNavigate} from "react-router-dom";
import SelectDropdownComponent
    from "../../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../../../store/reducers";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import LoaderComponent from "../../../../shared/components/loader/LoaderComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import FormikInputComponent from "../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import FormikTextAreaComponent
    from "../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
import * as Yup from "yup";
import FacilityHcpRateComponent from "../../hcp-facility-rate/FacilityHcpRateComponent";
import {getAgencyListLite} from "../../../../store/actions/static-data.action";
import OnboardingCompletedComponent from "../../onboarding-completed/OnboardingCompletedComponent";
import LabelComponent from "../../../../shared/components/label/LabelComponent";
import {addAgencySteps, americanTimeZone, LinkAgencySteps} from "../../../../data";
import FormikSelectDropdownComponent
    from "../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import {AGENCY_LIST} from "../../../../constants/RoutesConfig";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import ModuleCardDetailsComponent
    from "../../../../shared/components/module-details-card-component/ModuleCardDetailsComponent";
import StepperComponent from "../../../../shared/components/stepper-component/StepperComponent";
import FormikPhoneInputComponent
    from "../../../../shared/components/form-controls/formik-phone-input/FormikPhoneInputComponent";

const AgencyValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Agency Name should be at least 3 characters')
        .max(50, 'Agency Name should not exceed 50 characters')
        .required('Agency Name is required'),
    contact_number: Yup.string().min(14, "Phone number must be 10 digits").required("Phone Number is Required"),
    email: Yup.string().email('Invalid email address').required('Email Address is required'),
    about: Yup.string(),
    admin_first_name: Yup.string().required('First name is required'),
    admin_last_name: Yup.string().required('Last name is required'),
    admin_contact_number: Yup.string().min(12, "Phone number must be 10 digits").required("Phone Number is Required"),
    admin_email: Yup.string().email('Invalid admin email address').required('Email Address is required'),
    timezone: Yup.string().required('Timezone is required'),
    address: Yup.object({
        street: Yup.string()
            .min(1, 'Address Line should be at least 1 character')
            .required('Address Line  is required'),
        zip_code: Yup.string().required('ZIP Code is required').min(5, 'ZIP Code should be at least 5 characters').max(6, 'ZIP Code should be maximum 6 characters'),
        state: Yup.string()
            .min(1, 'State should be at least 1 character')
            .max(30, 'State should not exceed 30 characters')
            .required('State is required'),
        city: Yup.string()
            .min(1, 'City should be at least 1 character')
            .max(30, 'City should not exceed 30 characters')
            .required('City is required'),
        country: Yup.string()
            .min(1, 'Country should be at least 1 character')
            .max(30, 'Country should not exceed 30 characters')
            .required('Country is required'),
        // region: Yup.string()
        //     .min(1, 'Region should be at least 1 character')
        //     .max(30, 'Region should not exceed 30 characters')
        //     .required('Region is required'),
    }),
});

interface formInitialValuesFormSchema {
    name: string;
    contact_number: any;
    email: string;
    about: string;
    admin_first_name: any;
    admin_last_name: any;
    admin_contact_number: any;
    admin_email: any;
    location_url: any;
    timezone: any;
    address: {
        street: any,
        city: any,
        state: any,
        region: any,
        country: any,
        zip_code: any,
    };
    notification_preferences: {
        isSmsRequired: any;
        isEmailRequired: any;
        isPush_notificationRequired: any;
    };

}

const formInitialValues: formInitialValuesFormSchema = {
    name: "",
    contact_number: "",
    email: "",
    about: "",
    admin_first_name: "",
    admin_last_name: "",
    location_url: "",
    admin_contact_number: "",
    admin_email: "",
    timezone: "",
    address: {
        street: "",
        city: "",
        state: "",
        region: "",
        country: "",
        zip_code: "",
    },
    notification_preferences: {
        isSmsRequired: true,
        isEmailRequired: true,
        isPush_notificationRequired: true,
    }
};

const AddAgencyScreenComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {agencyList} = useSelector((state: IRootReducerState) => state.staticData);
    const [filterData, setFilterData] = useState<any>({
        selectedFacility: null,
        selectedAgency: null,
    });
    const [isSelectedAgencyDetailsLoading, setIsSelectedAgencyDetailsLoading] = useState<boolean>(false);
    const [isSelectedAgencyDetailsLoaded, setIsSelectedAgencyDetailsLoaded] = useState<boolean>(false);
    const [selectedAgencyDetails, setSelectedAgencyDetails] = useState<any>(null);
    const [isAgencyLinking, setIsAgencyLinking] = useState<boolean>(false);
    const [formScreenNavigation, setFormScreenNavigation] = useState<"agency-selection" | "add-new-agency" | "facility-hcp-rate" | "contract-upload" | "onboarded-screen-completed">("agency-selection");
    const [ratesInnerNavigation, setRateInnerNavigation] = useState<"hcp-rate-form" | "additioinal-rate-form" | "preference-form" | "upload-contract">("hcp-rate-form");
    const [formTab, setFormTab] = useState<any>("agency-details");
    const [initialValues] = useState<formInitialValuesFormSchema>(formInitialValues);
    //const [isHcpRatesAgencyDetailsLoading, setIsHcpRatesAgencyDetailsLoading] = useState<boolean>(false);
    // const [isHcpRatesAgencyDetailsLoaded, setIsHcpRatesAgencyDetailsLoaded] = useState<boolean>(false);
    const [HcpRatesAgencyDetails, setHcpRatesAgencyDetails] = useState<any>(null);
    const [agencyAddActiveStep, setAgencyAddActiveStep] = React.useState(0);
    const {regionList} = useSelector((state: IRootReducerState) => state.staticData);
    const [currentAndLink, setCurrentAddLink] = useState<any>("Link");

    const handleNext = () => {
        setAgencyAddActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = useCallback(() => {
        if (agencyAddActiveStep > 0) {
            setAgencyAddActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    }, [agencyAddActiveStep]);

    const getSelectedAgencyHcpRates = useCallback(() => {
        //  setIsHcpRatesAgencyDetailsLoading(true);
        const payload = {};
        CommonService._agency.AgencyDetailsHCPRatesAPICall(filterData?.selectedAgency, payload)
            .then((response: IAPIResponseType<any>) => {
                console.log(response.data);
                //  setIsHcpRatesAgencyDetailsLoaded(true);
                // setSelectedAgencyDetails(response?.data);
                setHcpRatesAgencyDetails(response?.data);
            }).catch((error: any) => {
            //  setIsHcpRatesAgencyDetailsLoaded(false);
            // setIsHcpRatesAgencyDetailsLoading(true);
        }).finally(() => {
            // setIsHcpRatesAgencyDetailsLoading(false);
        });
    }, [filterData?.selectedAgency]);

    useEffect(() => {
        getSelectedAgencyHcpRates();
    }, [getSelectedAgencyHcpRates]);

    // useEffect(() => {
    //     if (formScreenNavigation === "agency-selection") {
    //     } else if (formScreenNavigation === "facility-hcp-rate" && ratesInnerNavigation === "hcp-rate-form") {
    //         //  setAgencyAddActiveStep(3);
    //         handleNext();
    //     }
    // }, [formScreenNavigation, ratesInnerNavigation]);

    const getSelectedAgencyDetails = useCallback(() => {
        if (filterData?.selectedAgency) {
            setIsSelectedAgencyDetailsLoading(true);
            const payload = {};
            CommonService._facility.AgencyDetailsAPICall(filterData?.selectedAgency, payload)
                .then((response: IAPIResponseType<any>) => {
                    console.log(response.data);
                    setIsSelectedAgencyDetailsLoaded(true);
                    setSelectedAgencyDetails(response?.data);
                }).catch((error: any) => {
                setIsSelectedAgencyDetailsLoaded(false);
                setIsSelectedAgencyDetailsLoading(true);
            }).finally(() => {
                setIsSelectedAgencyDetailsLoading(false);
            });
        } else {
            setSelectedAgencyDetails(null);
        }
    }, [filterData?.selectedAgency]);

    useEffect(() => {
        getSelectedAgencyDetails();
    }, [getSelectedAgencyDetails]);

    const handleAgencyFacilityLinking = useCallback((agencyId: any) => {
        if (currentAndLink === "Link") {
            handleNext();
        }
        setIsAgencyLinking(true);
        console.log(agencyAddActiveStep);
        const payload = {};
        CommonService._agency.LinkAgencyToFacilityAPICall(agencyId, payload)
            .then((response: IAPIResponseType<any>) => {
                setIsAgencyLinking(false);
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setFormScreenNavigation("facility-hcp-rate");
            }).catch((error: any) => {
            // CommonService._alert.showToast(error[Misc.API_ERROR_MESSAGE_KEY], "error");
            setIsAgencyLinking(false);
            setFormScreenNavigation("facility-hcp-rate");
        }).finally(() => {
            setIsAgencyLinking(false);
            setFormScreenNavigation("facility-hcp-rate");
        });
    }, [agencyAddActiveStep, currentAndLink]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        if (formTab === "agency-details") {
            setFormTab("Address");
            setSubmitting(false);
            return;
        } else if (formTab === "Address") {
            setSubmitting(true);
            const payload = {
                ...values,
                contact_number:"+1" + values?.contact_number?.replace(/\D/g,''),
                admin_contact_number:"+1" + values?.admin_contact_number?.replace(/\D/g,''),
                notification_preferences: {
                    isSmsRequired: true,
                    isEmailRequired: true,
                    isPush_notificationRequired: true,
                }
            };
            let apiCall: any = {};
            apiCall = CommonService._agency.AddAgencyAPICall(payload);
            apiCall.then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                //  handleAgencyFacilityLinking();
                //  navigate(CommonService._routeConfig.FacilityDetailsRoute(response.data._id));
                //setAgencyAddActiveStep(2);
                handleNext();
                dispatch(getAgencyListLite({}));
                setFilterData({
                    ...filterData,
                    selectedAgency: response?.data?._id,
                });
                handleAgencyFacilityLinking(response?.data?._id);
            })
                .catch((error: any) => {
                    CommonService.handleErrors(setErrors, error);
                    // setFormTab("Facility");
                }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [formTab, filterData, dispatch, handleAgencyFacilityLinking]);

    useEffect(() => {
        dispatch(getAgencyListLite({}));
    }, [dispatch]);

    const isStaffFormValidated=useCallback((errors:any)=>{
        const fieldsToValidate=[
            "name",
            "contact_number",
            "email",
            "admin_first_name",
            "admin_last_name",
            "admin_contact_number",
            "admin_email",
        ]
        return fieldsToValidate.every((field:any)=>!errors[field]);
    },[])

    return (<>

        {formScreenNavigation !== "onboarded-screen-completed" &&
            <>
                <div className={"add-agency-details-component"}>
                    <div className={'add-agency-sidebar-steper'}>
                        <div className={'add-agency-sidebar-steper-wrapper pdd-20'}>
                            <div className={'flex-1'}>
                                <div className={'adding-module-branding-wrapper'}>
                                    <div className="menu-item-icon">
                                        <ImageConfig.VarsShortLogoIcon/>
                                    </div>

                                    <div className={'vars-health-logo-text'}>
                                        Work OS
                                    </div>
                                </div>
                                <div className={'form-top-header-text mrg-top-40'}>
                                    Agency Onboarding
                                </div>
                                <StepperComponent activeStep={agencyAddActiveStep}
                                                  steps={currentAndLink === "Add" ? addAgencySteps : LinkAgencySteps}/>
                            </div>
                            <div>
                                <ButtonComponent
                                    id={"exit_btn"}
                                    suffixIcon={<ImageConfig.ExitIcon/>}
                                    style={{
                                        backgroundColor: "#F96868",
                                        color: "white",
                                        border: "1px solid #F96868"
                                    }}
                                    onClick={() => {
                                        CommonService.onExitFlowHandling(navigate, AGENCY_LIST);
                                    }}
                                >
                                    Exit Agency Onboarding
                                </ButtonComponent>
                            </div>
                        </div>
                    </div>
                    <div className={'add-agency-formik-wrapper mrg-top-20'}>
                        {formScreenNavigation === "agency-selection" &&
                            <div className={"agency-selection-component"}>
                                <div className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                    <div>
                                        <div className={'d-flex justify-content-space-between align-items-center'}>
                                            <div className={'form-top-header-text'}>Select The Agency</div>
                                            {
                                                formScreenNavigation === "agency-selection" && !selectedAgencyDetails && !isSelectedAgencyDetailsLoading &&
                                                <div className={'add-agency-button'}
                                                    // style={{alignSelf: "center"}}
                                                     onClick={() => {
                                                         setFormScreenNavigation("add-new-agency");
                                                         setCurrentAddLink("Add");
                                                         //  handleNext();
                                                     }}>
                                                    <ButtonComponent
                                                        prefixIcon={<ImageConfig.AddOutlinedIcon/>}
                                                        className={"center-element add-button-text "}>Add New
                                                        Agency</ButtonComponent>
                                                </div>
                                            }
                                        </div>
                                        <SelectDropdownComponent noDataFoundText={"No Agency Found"} navigation={() => {
                                            setFormScreenNavigation("add-new-agency");
                                        }} buttonRequired={false} placeholder={"Select Agency"}
                                                                 searchMode={"clientSide"}
                                                                 value={filterData.selectedAgency || null}
                                                                 options={agencyList}
                                                                 valueExtractor={item => item.code} searchable={true}
                                                                 onUpdate={
                                                                     (value: any) => {
                                                                         setFilterData({
                                                                             ...filterData,
                                                                             selectedAgency: value,
                                                                         });
                                                                     }
                                                                 }/>
                                    </div>
                                    <div>
                                        {
                                            isSelectedAgencyDetailsLoading && <LoaderComponent color={'primary'}/>
                                        }
                                    </div>
                                    {
                                        !isSelectedAgencyDetailsLoading && isSelectedAgencyDetailsLoaded && selectedAgencyDetails && <>
                                            <div>
                                                <HorizontalLineComponent/>
                                                <div className={'mrg-top-20 mrg-bottom-10 card-details-header'}>Agency
                                                    Details
                                                </div>
                                                <ModuleCardDetailsComponent
                                                    title={selectedAgencyDetails?.name}
                                                    headerIcon={<ImageConfig.AgencyDetailsIcon/>}
                                                    addressIcon={<ImageConfig.AddressDetailsIcon/>}
                                                    emailIcon={<ImageConfig.EmailDetailsIcon/>}
                                                    phoneIcon={<ImageConfig.PhoneDetailsIcon/>}
                                                    moduleName={'Agency'}
                                                    moduleDetails={selectedAgencyDetails}/>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={'add-agency-form-action-element'}>
                                    <div className={"mrg-left-20"}>
                                    </div>
                                    <ButtonComponent
                                        disabled={!filterData.selectedAgency}
                                        isLoading={isAgencyLinking}
                                                     onClick={() => {
                                                         handleAgencyFacilityLinking(filterData.selectedAgency)
                                                     }}
                                                     suffixIcon={<ImageConfig.NextButtonIcon/>}
                                    >
                                        Next
                                    </ButtonComponent>
                                </div>
                            </div>
                        }

                        {
                            formScreenNavigation === "add-new-agency" &&
                            <Formik
                                validationSchema={AgencyValidationSchema}
                                initialValues={initialValues}
                                validateOnChange={false}
                                validateOnBlur={true}
                                enableReinitialize={true}
                                validateOnMount={true}
                                onSubmit={onSubmit}
                            >
                                {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                                    // eslint-disable-next-line react-hooks/rules-of-hooks
                                    useEffect(() => {
                                        validateForm();
                                    }, [validateForm, values]);
                                    return (
                                        <Form noValidate={true} className={"add-agency-from"}>
                                            <div className="add-component-content">
                                                <div
                                                    className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                                                    {formTab === "agency-details" && <>
                                                        <div className={"flex-1"}>
                                                            <div className={'form-top-header-text'}>Agency Details</div>
                                                            <Field name={'name'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Agency Name'}
                                                                            placeholder={'Enter Agency Name'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                            <div className="ts-row">
                                                                <div className="ts-col-6">
                                                                    <LabelComponent title={'Phone Number'}
                                                                                    required={true}/>
                                                                    <Field name={'contact_number'}>
                                                                        {(field: FieldProps) => (
                                                                            <FormikPhoneInputComponent formikField={field}
                                                                                                       placeholder={"Enter Phone Number"}
                                                                                                       required={true}
                                                                                                       fullWidth={true}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                                <div className="ts-col-6">
                                                                    <Field name={'email'}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    label={'Email Address'}
                                                                                    placeholder={'example@xyz.com'}
                                                                                    type={"text"}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div className={"mrg-top-10"}>
                                                                <Field name={'about'}>
                                                                    {
                                                                        (field: FieldProps) => (
                                                                            <FormikTextAreaComponent
                                                                                label={'About The Agency'}
                                                                                rows={6}
                                                                                placeholder={''}
                                                                                //required={true}
                                                                                formikField={field}
                                                                                fullWidth={true}
                                                                            />
                                                                        )
                                                                    }
                                                                </Field>
                                                            </div>
                                                            <div className="add-component-header-admin">
                                                                <div
                                                                    style={{
                                                                        fontWeight: "600",
                                                                        fontSize: "14px"
                                                                    }}
                                                                    className="header-text mrg-bottom-20 mrg-top-20">

                                                                    Admin Details
                                                                </div>
                                                            </div>
                                                            <div className="ts-row">
                                                                <div className="ts-col-6">
                                                                    <Field name={'admin_first_name'}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    label={'First Name'}
                                                                                    placeholder={'ex : John'}
                                                                                    type={"text"}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                                <div className="ts-col-6">
                                                                    <Field name={'admin_last_name'}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    label={'Last Name'}
                                                                                    placeholder={'ex : Doe'}
                                                                                    type={"text"}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div className="ts-row">
                                                                <div className="ts-col-6">
                                                                    <LabelComponent title={'Phone Number'}
                                                                                    required={true}/>
                                                                    <Field name={'admin_contact_number'}>
                                                                        {(field: FieldProps) => (
                                                                            <FormikPhoneInputComponent formikField={field}
                                                                                                       placeholder={"Enter Phone Number"}
                                                                                                       required={true}
                                                                                                       fullWidth={true}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                                <div className="ts-col-6">
                                                                    <Field name={'admin_email'}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    label={'Email Address'}
                                                                                    placeholder={'example@xyz.com'}
                                                                                    type={"text"}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>}
                                                    {
                                                        formTab === "Address" && <>
                                                            <div className={'form-top-header-text'}>Agency Address Details
                                                            </div>
                                                            <div className={"flex-1"}>
                                                                <Field name={'address.street'}>
                                                                    {
                                                                        (field: FieldProps) => (
                                                                            <FormikInputComponent
                                                                                label={'Address Line'}
                                                                                placeholder={'Enter address here'}
                                                                                type={"text"}
                                                                                required={true}
                                                                                formikField={field}
                                                                                fullWidth={true}
                                                                            />
                                                                        )
                                                                    }
                                                                </Field>
                                                                <div className="ts-row">
                                                                    <div className="ts-col-6">
                                                                        <Field name={'address.country'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikInputComponent
                                                                                        label={'Country'}
                                                                                        placeholder={'Enter the Country'}
                                                                                        type={"text"}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                    <div className="ts-col-6">
                                                                        <Field name={'address.state'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikSelectDropdownComponent
                                                                                        options={regionList}
                                                                                        displayWith={(option: any) => option.title}
                                                                                        valueExtractor={(option: any) => option.code}
                                                                                        label={'State'}
                                                                                        placeholder={'Select State'}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                        searchable={true}
                                                                                        noDataMessage={'No state available'}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="ts-row">
                                                                    <div className="ts-col-6">
                                                                        <Field name={'address.zip_code'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikInputComponent
                                                                                        label={'ZIP Code'}
                                                                                        placeholder={'Enter ZIP Code'}
                                                                                        type={"number"}
                                                                                        maxLength={6}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                    <div className="ts-col-6">
                                                                        <Field name={'address.city'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikInputComponent
                                                                                        label={'City'}
                                                                                        placeholder={'Enter City'}
                                                                                        type={"text"}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="ts-row">
                                                                    <div className="ts-col-6">
                                                                        <Field name={'timezone'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikSelectDropdownComponent
                                                                                        options={americanTimeZone}
                                                                                        displayWith={(option: any) => option.title}
                                                                                        valueExtractor={(option: any) => option.code}
                                                                                        label={'Timezone'}
                                                                                        placeholder={'Select Timezone'}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                        searchable={true}
                                                                                        noDataMessage={'No timezone available'}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className={'add-agency-form-action-element'}>
                                                    {formTab === "agency-details" &&
                                                        <div className={"mrg-left-20"}>
                                                            <ButtonComponent
                                                                className={'back-button'}
                                                                suffixIcon={<ImageConfig.BackArrow/>}
                                                                onClick={() => {
                                                                    //  setFormTab("Address");
                                                                    setFormScreenNavigation('agency-selection');
                                                                    setCurrentAddLink("Link");
                                                                    handleBack();
                                                                }}
                                                            >
                                                                Back
                                                            </ButtonComponent>
                                                        </div>
                                                    }
                                                    {formTab === "agency-details" &&
                                                        <div>
                                                            <ButtonComponent
                                                                disabled={!isStaffFormValidated(errors)}
                                                                style={{color: "black"}}
                                                                onClick={() => {
                                                                    setFormTab("Address");
                                                                    handleNext();
                                                                    // setAgencyAddActiveStep(2);
                                                                }}
                                                                suffixIcon={<ImageConfig.NextButtonIcon/>}
                                                            >
                                                                Next
                                                            </ButtonComponent>
                                                        </div>
                                                    }

                                                    {formTab === "Address" &&
                                                        <div className={"mrg-left-20"}>
                                                            <ButtonComponent
                                                                className={'back-button'}
                                                                suffixIcon={<ImageConfig.BackArrow/>}
                                                                onClick={() => {
                                                                    setFormTab("agency-details");
                                                                    handleBack();
                                                                }}
                                                            >
                                                                Back
                                                            </ButtonComponent>
                                                        </div>
                                                    }

                                                    {formTab === "Address" &&
                                                        <div>
                                                            <div
                                                                className={"ts-row center-element"}>
                                                                <ButtonComponent
                                                                    disabled={isSubmitting || !isValid}
                                                                    isLoading={isSubmitting}
                                                                    style={{color: "black"}}
                                                                    type={'submit'}
                                                                    suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                                                >
                                                                    Save Details
                                                                </ButtonComponent>
                                                            </div>
                                                        </div>

                                                    }
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        }

                        {
                            formScreenNavigation === "facility-hcp-rate" &&
                            <>
                                <div className={'facility-hcp-rate-screen-box'}>
                                    <FacilityHcpRateComponent setAgencyAddActiveStep={setAgencyAddActiveStep}
                                                              setRateInnerNavigation={setRateInnerNavigation}
                                                              getSelectedAgencyDetails={getSelectedAgencyHcpRates}
                                                              ratesInnerNavigation={ratesInnerNavigation}
                                                              setFormScreenNavigation={setFormScreenNavigation}
                                                              filterData={filterData}
                                                              HcpRatesAgencyDetails={HcpRatesAgencyDetails}
                                                              selectedAgencyDetails={selectedAgencyDetails}
                                                              handleNext={handleNext}
                                    />
                                </div>
                            </>
                        }

                    </div>
                </div>
            </>
        }
        {formScreenNavigation === "onboarded-screen-completed" && <>
            <OnboardingCompletedComponent/>
        </>}

    </>);
};

export default AddAgencyScreenComponent;
