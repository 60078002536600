import "./FacilityListComponent.scss";
import {ImageConfig, Misc} from "../../../constants";
import {CommonService} from "../../../shared/services";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {IAPIResponseType} from "../../../shared/models/api.model";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../shared/components/tabs/TabsComponent";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../shared/components/status-card/StatusCardComponent";
import CardComponent from "../../../shared/components/card/CardComponent";
import DataLabelValueComponent from "../../../shared/components/data-label-value/DataLabelValueComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import EditFacilityComponent from "../edit-facility/EditFacilityComponent";
import EditAdminDetailsComponent from "../edit-facility/EditAdminDetailsComponent";
import AddWingComponent from "../wings/add-wings/AddWingsComponent";
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import AddShiftTimings from "./AddShiftTimings";
import EditWingComponent from "../wings/edit-wing/EditWingsComponent";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../shared/components/antd-table-component/AntTableComponent";

interface PropertyListComponentProps {

}

const FacilityListComponent = (props: PropertyListComponentProps) => {
    const [activeTab, setActiveTab] = useState("basic-details");
    const [filterData, setFilterData] = useState<any>({
        search: '',
        selectedTab: 'Facility Details',
        // sort:{},
    });
    const [facilityDetails, setFacilityDetails] = useState<any>(null);
    const [isFacilityDetailsLoading, setIsFacilityDetailsLoading] = useState<boolean>(false);
    const [isFacilityDetailsLoadingFailed, setIsFacilityDetailsLoadingFailed] = useState<boolean>(false);
    const [isFacilityDetailsLoaded, setIsFacilityDetailsLoaded] = useState<boolean>(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<any>(false);
    const [isEditAdminDrawerOpen, setIsEditAdminDrawerOpen] = useState<any>(false);
    // const [isWingListLoading, setWingListLoading] = useState(false);
    const [isAddWingDrawerOpen, setIsAddWingDrawerOpen] = useState<boolean>(false);
    const [isAddShiftTimingsOpen, setIsAddShiftTimingsOpen] = useState<boolean>(false);
    const [isEditWingDrawerOpen, setIsEditWingDrawerOpen] = useState<boolean>(false);
    const [selectedWingsForEdit, setSelectedWingsForEdit] = useState<any>(null);
    const [facilityAdminDetails,setFacilityAdminDetails]=useState<any>(null);

    const getFacilityDetails = useCallback(() => {
        setIsFacilityDetailsLoading(true);
        const payload = {};
        // console.log(isWingListLoading);
        CommonService._facility.FacilityDetailsAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                console.log(response.data);
                setFacilityDetails(response.data);
                setIsFacilityDetailsLoaded(true);
                setIsFacilityDetailsLoadingFailed(false);
            }).catch((error: any) => {
            setIsFacilityDetailsLoaded(false);
            setIsFacilityDetailsLoadingFailed(true);
        }).finally(() => {
            setIsFacilityDetailsLoading(false);
        });

    }, []);

    useEffect((): void => {
        getFacilityDetails();
    }, [getFacilityDetails]);

    const openEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(true);
    }, []);

    const openAdminEditDrawer = useCallback(() => {
        setIsEditAdminDrawerOpen(true);
    }, []);

    const closeEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(false);
    }, []);

    const closeAdminEditDrawer = useCallback(() => {
        setIsEditAdminDrawerOpen(false);
    }, []);


    const handleCloseAddWingDrawer = useCallback(() => {
        setIsAddWingDrawerOpen(false);
    }, []);

    const onWingsActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  Wing`,
                image: ImageConfig.ActivateDeactivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'deactivate' : 'activate'}  the
    wing?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._facility.ActivteDeactivateWings(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    getFacilityDetails();
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "No apis Found for this action", "error");
            });
        });
    }, [getFacilityDetails]);


    const deleteWingsApiCall = useCallback((wingId: any) => {
        const payload: any = {};
        CommonService._facility.deleteWings(wingId, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getFacilityDetails();
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error, "error");
        });
    }, [getFacilityDetails]);


    const onWingsDelete = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `Delete Wing`,
                image: ImageConfig.ActivateDeactivateIcon,
                confirmationDescription: `Are you sure you want to delete the
    wing?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, Delete`,
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            deleteWingsApiCall(item?._id);
        });
    }, [deleteWingsApiCall]);

    const deleteShiftTimingsApiCall = useCallback((shiftTimingsId: any) => {
        const payload: any = {};
        CommonService._facility.deleteShiftTimings(shiftTimingsId, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getFacilityDetails();
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error, "error");
        });
    }, [getFacilityDetails]);


    const onShiftTimingsDelete = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `Delete this shift`,
                image: ImageConfig.ActivateDeactivateIcon,
                confirmationDescription: `Are you sure you want to delete this shift?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, Delete`,
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            deleteShiftTimingsApiCall(item?._id);
        });
    }, [deleteShiftTimingsApiCall]);


    const shiftTimingsColumn:TableColumnsType= useMemo(() => {
        return [
            {
                title: 'Shift Type',
                fixed: 'left',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        {item?.shift_type || 'N/A'}
                    </div>;
                }
            },
            {
                title: 'Shift Start Time',
                width: 150,
                render: (item: any) => {
                    return <>
                        <div>
                            {CommonService.convertMinutesToTime(item?.shift_start_time) || 'N/A'}
                        </div>
                    </>;
                }
            },
            {
                title: "Shift End Time",
                width: 150,
                fixed: 'right',
                render: (item: any) => {
                    return <>
                        <div>
                            {CommonService.convertMinutesToTime(item?.shift_end_time) || 'N/A'}
                        </div>
                    </>;
                }
            },
            {
                title: "",
                align: 'center',
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    return <LinkComponent onClick={() => {
                        // Filter the list to exclude the item with the specified ID
                        onShiftTimingsDelete(item);
                    }
                    }>
                        <ImageConfig.DeleteIcon/>
                    </LinkComponent>;
                }
            }
        ];
    }, [onShiftTimingsDelete]);

    const wingDetailsColumn: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Wing Name',
                width: 250,
                render: (item: any) => {
                    return <>
                        {
                            (item?.name?.name)?.length > 20 ?
                                <ToolTipComponent
                                    tooltip={item?.name?.name}
                                    showArrow={true}
                                    position={"top"}
                                >
                                    <div style={{cursor: "pointer"}}
                                         className={"ellipses-for-table-data property-name"}>
                                        {item?.name?.name || item?.name}
                                    </div>
                                </ToolTipComponent> :
                                <div className="facility-name">
                                    {item?.name?.name || item?.name || "N/A"}
                                </div>
                        }
                    </>;
                }
            },
            {
                title: "",
                align: 'center',
                fixed: 'right',
                width:80,
                render: (item: any) => {
                    return <LinkComponent onClick={() => {
                        // Filter the list to exclude the item with the specified ID
                        //   deleteWingsApiCall(item?._id);
                        onWingsDelete(item);
                    }
                    }>
                        <ImageConfig.DeleteIcon/>
                    </LinkComponent>;
                }
            },

            {
                title: '',
                align: 'center',
                fixed: 'right',
                width: 100,
                render: (item: any) => {

                    return <div className={'display-center'}>
                        <CustomToggleComponent toggleHandler={() => {
                            onWingsActivateDeactivate(item);
                        }} defaultOn={item?.is_active}/>
                    </div>;
                }
            },

            {
                title: "",
                align: 'center',
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent onClick={() => {
                            setSelectedWingsForEdit(item);
                            setIsEditWingDrawerOpen(true);
                        }}>
                            <ImageConfig.PencilEditIcon/>
                        </LinkComponent>;
                    }
                }
            }

        ];
    }, [onWingsActivateDeactivate, onWingsDelete]);

    const handleCloseAddShiftDrawer = useCallback(() => {
        setIsAddShiftTimingsOpen(false);
    }, []);

    const handleCloseEditWingDrawer = useCallback(() => {
        setIsEditWingDrawerOpen(false);
    }, []);

    const facilityAdminListColumns:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Admin Name',
                width:250,
                render:(item:any)=>{
                    return <div className={'agency-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.first_name + " " + item?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.first_name + " " + item?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.first_name + " " + item?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="agency-name">
                                        {item?.first_name + " " + item?.last_name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:'Phone Number',
                width:120,
                render:(item:any)=>{
                    return (
                        <div>
                            {CommonService.formatPhoneNumber(item?.contact_number?.replace(/\+1/,'')) || 'N/A'}
                        </div>
                    )
                }

            },
            {
                title:'Role',
                width:150,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.role || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Email',
                width:180,
                render:(item:any)=>{
                    return <div className={'agency-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.email)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.email}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.email}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="agency-name">
                                        {item?.email || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:'Edit Admin Details',
                width:150,
                render:(item:any)=>{
                    return (
                        <div className={'display-center'}
                             onClick={()=>{
                                 setFacilityAdminDetails(item)
                                 openAdminEditDrawer()
                             }}
                        >
                            <ImageConfig.PencilEditIcon/>
                        </div>
                    )
                }
            }
        ]
    },[openAdminEditDrawer])

    return (
        <>
            <DrawerComponent isOpen={isAddShiftTimingsOpen} onClose={handleCloseAddShiftDrawer}>
                <AddShiftTimings closeEditDrawer={handleCloseAddShiftDrawer} getFacilityDetails={getFacilityDetails}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isEditDrawerOpen} direction={"right"} onClose={closeEditDrawer}>
                <EditFacilityComponent getFacilityDetails={getFacilityDetails} closeEditDrawer={closeEditDrawer}
                                       facilityDetails={facilityDetails}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isEditAdminDrawerOpen} direction={"right"} onClose={closeAdminEditDrawer}>
                <EditAdminDetailsComponent facilityDetails={facilityAdminDetails} closeEditDrawer={closeAdminEditDrawer}
                                           getFacilityDetails={getFacilityDetails}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isAddWingDrawerOpen} onClose={handleCloseAddWingDrawer}>
                <AddWingComponent getFacilityDetails={getFacilityDetails}
                                  closeEditDrawer={handleCloseAddWingDrawer}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isEditWingDrawerOpen} onClose={handleCloseEditWingDrawer}>
                <EditWingComponent getFacilityDetails={getFacilityDetails}
                                   selectedFacilityForEdit={selectedWingsForEdit}
                                   closeEditDrawer={handleCloseEditWingDrawer}/>
            </DrawerComponent>
            <div className={'facility-list-component header-screen mrg-top-25 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.FacilityModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title mrg-bottom-25">Facility</div>
                        </div>
                    </div>
                </div>
                <TabsWrapperComponent>
                    <TabsComponent
                        value={activeTab}
                        allowScrollButtonsMobile={false}
                        onUpdate={(e: any, v: any) => {
                            setActiveTab(v);
                            setFilterData({...filterData, selectedTab: v, page: 1});
                        }}
                    >
                        <TabComponent label="Basic Details" value={"basic-details"}/>
                        <TabComponent label="Admin Details" value={"admin-details"}/>
                        <TabComponent label="Wing Details" value={"wing-details"}/>
                        <TabComponent label="Shift Timings" value={"shift-timings"}/>
                    </TabsComponent>
                    <div className={'pdd-top-10'}>
                        <TabContentComponent value={activeTab} selectedTab={"basic-details"}>
                            {
                                isFacilityDetailsLoading && <div className="h-v-center">
                                    <LoaderComponent type={'spinner'}/>
                                </div>
                            }
                            {
                                isFacilityDetailsLoadingFailed &&
                                <div className="h-v-center">
                                    <StatusCardComponent title={'Failed to Load'}/>
                                </div>
                            }
                            {
                                isFacilityDetailsLoaded && <>
                                    <CardComponent size={"sm"}>
                                        <div className={'details-screen'}>
                                            <div className="details-screen-title-with-image-wrapper pdd-10">
                                                <div
                                                    className={'details-screen-title-wrapper center-right-element mrg-left-30 mrg-right-30'}>
                                                    <div className={'details-screen-title'}>Facility
                                                        Details
                                                    </div>
                                                    <div onClick={openEditDrawer} className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Facility Details</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Facility Name'}>
                                                            {facilityDetails?.name || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Phone Number'}>
                                                            {CommonService.formatPhoneNumber(facilityDetails?.contact_number?.replace(/^\+1/, '')) || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Email ID'}>
                                                            {facilityDetails?.email || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Address Line'}>
                                                            {`${facilityDetails?.address?.street}, ${facilityDetails?.address?.city}, ${facilityDetails?.address?.state}, ${facilityDetails?.address?.zip_code}, ${facilityDetails?.address?.country}` || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Timezone'}>
                                                            {CommonService.getTimezoneTitleByCode(facilityDetails?.timezone) || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    {/*<div className={'ts-col-lg-3'}>*/}
                                                    {/*    <DataLabelValueComponent label={'Region'}>*/}
                                                    {/*        {*/}
                                                    {/*            facilityDetails?.address?.region || 'N/A'*/}
                                                    {/*        }*/}
                                                    {/*    </DataLabelValueComponent>*/}
                                                    {/*</div>*/}
                                                    <div className={'ts-col-lg-6'}>
                                                        <DataLabelValueComponent label={'Domain'}>
                                                            {facilityDetails?.domain || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                <div className={"ts-row"}>
                                                    <div className={'ts-col-lg-6'}>
                                                        <DataLabelValueComponent label={'Latitude'}>
                                                            <div>
                                                                {facilityDetails?.location?.coordinates[1] || 'N/A'}
                                                            </div>
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-6'}>
                                                        <DataLabelValueComponent label={'Longitude'}>
                                                            <div>
                                                                {facilityDetails?.location?.coordinates[0] || 'N/A'}
                                                            </div>
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                <div className={"ts-row"}>
                                                    <div className={'ts-col-lg-6'}>
                                                        <DataLabelValueComponent label={'About the Facility'}>
                                                            {facilityDetails?.about || 'N/A'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardComponent>
                                    {/*<CardComponent size={"sm"}>*/}
                                    {/*    <div className={'details-screen'}>*/}
                                    {/*        <div className="details-screen-title-with-image-wrapper pdd-10">*/}
                                    {/*            <div*/}
                                    {/*                className={'details-screen-title-wrapper center-right-element pdd-left-30 pdd-right-30'}>*/}
                                    {/*                <div className={'details-screen-title'}>Admin Details*/}
                                    {/*                </div>*/}
                                    {/*                <div onClick={openAdminEditDrawer} className={'icon-button'}>*/}
                                    {/*                        <span*/}
                                    {/*                            className={'center-element button-font-text'}><ImageConfig.EditIcon*/}
                                    {/*                            className={'mrg-right-10'}/>Edit Admin Details</span>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>*/}
                                    {/*        <div className={'ts-row'}>*/}
                                    {/*                <div className={'ts-col-lg-3'}>*/}
                                    {/*                    <DataLabelValueComponent label={'Admin Name'}>*/}
                                    {/*                        {Object.keys(facilityDetails?.admin_details || {}).length > 0*/}
                                    {/*                            ? `${facilityDetails.admin_details.first_name} ${facilityDetails.admin_details.last_name}`*/}
                                    {/*                            : "N/A"}*/}
                                    {/*                        </DataLabelValueComponent>*/}
                                    {/*                </div>*/}
                                    {/*                <div className={'ts-col-lg-3'}>*/}
                                    {/*                    <DataLabelValueComponent label={'Phone Number'}>*/}
                                    {/*                        {CommonService.formatPhoneNumber(facilityDetails?.admin_details?.contact_number?.replace(/^\+1/, '')) || 'N/A'}*/}
                                    {/*                    </DataLabelValueComponent>*/}
                                    {/*                </div>*/}
                                    {/*                <div className={'ts-col-lg-3'}>*/}
                                    {/*                    <DataLabelValueComponent label={'Email ID'}>*/}
                                    {/*                        {facilityDetails?.admin_details?.email || 'N/A'}*/}
                                    {/*                    </DataLabelValueComponent>*/}
                                    {/*                </div>*/}
                                    {/*                <div className={'ts-col-lg-3'}>*/}
                                    {/*                    <DataLabelValueComponent label={'Notifications Preference'}>*/}
                                    {/*                        {(facilityDetails?.admin_details?.notification_preferences?.isSmsRequired ? "SMS, " : "") + (facilityDetails?.admin_details?.notification_preferences?.isEmailRequired ? "Email, " : "") + (facilityDetails?.admin_details?.notification_preferences?.isPush_notificationRequired ? "In App " : "") || 'N/A'}*/}
                                    {/*                    </DataLabelValueComponent>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</CardComponent>*/}
                                    {/*<TabContentComponent selectedTab={activeTab} value={"wing-details"}>*/}
                                    {/*    <div className={"mrg-left-100  mrg-right-100"}>*/}
                                    {/*        <WingsListComponent facilityId={facilityId}/>*/}
                                    {/*    </div>*/}
                                    {/*</TabContentComponent>*/}
                                </>
                            }
                        </TabContentComponent>
                        <TabContentComponent value={activeTab} selectedTab={'admin-details'}>
                            <CardComponent size={"sm"}>
                                <div className={'details-screen'}>
                                    <div className="details-screen-title-with-image-wrapper pdd-10">
                                        <div
                                            className={'details-screen-title-wrapper center-right-element pdd-left-30 pdd-right-30'}>
                                            <div className={'details-screen-title'}>Admin Details
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={"pdd-left-25 pdd-right-25 pdd-top-5 facility-admin-table-wrapper"}>
                                        <AntTableComponent
                                            data={facilityDetails?.admin_details || []}
                                            extraPayload={facilityDetails?.admin_details}
                                            columns={facilityAdminListColumns}
                                            noDataText={'No Facility Admin Data Found.'}
                                        />
                                    </div>
                                </div>
                            </CardComponent>
                        </TabContentComponent>
                        <TabContentComponent selectedTab={activeTab} value={"wing-details"}>
                            <div>
                                <div className={'d-flex justify-content-right mrg-bottom-10'}>
                                    <ButtonComponent
                                        prefixIcon={<ImageConfig.AddOutlinedIcon/>}
                                        className={"center-element add-button-text "} onClick={() => {
                                        setIsAddWingDrawerOpen(true);
                                    }}>Add New
                                        Wing</ButtonComponent>
                                </div>
                                <div className={'mrg-top-20'}>
                                    <AntTableComponent
                                        columns={wingDetailsColumn}
                                        noDataText={"no wings for this facility."}
                                        data={facilityDetails?.wings}
                                        isRowSelection={false}
                                        extraPayload={facilityDetails?.wings}
                                    />
                                    {/*<TableComponent columns={wingDetailsColumn} noDataText={"no wings for this facility."}*/}
                                    {/*                data={facilityDetails?.wings} className={"shift-timings-wrapper"}/>*/}
                                </div>
                                {/*<WingsListComponent facilityId={facilityId}/>*/}
                            </div>
                        </TabContentComponent>
                        <TabContentComponent selectedTab={activeTab} value={"shift-timings"}>
                            <div>
                                <div className={'d-flex justify-content-right mrg-bottom-10'}>
                                    <ButtonComponent
                                        prefixIcon={<ImageConfig.AddOutlinedIcon/>}
                                        className={"center-element add-button-text "} onClick={() => {
                                        setIsAddShiftTimingsOpen(true);
                                    }}>Add Shift Timings</ButtonComponent>
                                </div>
                                <div className={'mrg-top-20'}>
                                    {/*<TableComponent columns={shiftTimingsColumn} noDataText={"no shift timings for this facility."}*/}
                                    {/*                data={facilityDetails?.shift_timings} className={"shift-timings-wrapper"}/>*/}
                                    <AntTableComponent
                                        columns={shiftTimingsColumn}
                                        noDataText={"no shift timings for this facility."}
                                        isRowSelection={false}
                                        data={facilityDetails?.shift_timings}
                                        extraPayload={facilityDetails?.shift_timings}
                                    />
                                </div>
                            </div>
                        </TabContentComponent>
                    </div>
                </TabsWrapperComponent>
            </div>

        </>
    );

};

export default FacilityListComponent;
