import React, {useCallback, useEffect, useState} from "react";
import "./ApprovedShiftDetailsComponent.scss";
import {APIConfig, ImageConfig, Misc} from "../../../../constants";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import TimeSheetUploadPreviewFile from "./TimeSheetUploadPreviewFile";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import CardComponent from "../../../../shared/components/card/CardComponent";
import LoaderComponent from "../../../../shared/components/loader/LoaderComponent";
import FilePickerComponent from "../../../../shared/components/file-picker/FilePickerComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import CommonHcpDetailsCard from "../hcp-details-common-card/CommonHcpDetailsCard";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../../shared/components/tabs/TabsComponent";
import {Tooltip} from "@mui/material";
import Lottie from "react-lottie";
import FavoriteJson from "../../../../assets/animations/Favorite.json";
import {useUserListColumn} from "../../../../shared/common-table/CommonTableColumns";
import ContractTravelDetails from "../contract-travel-details/ContractTravelDetailsComponent";
import HomeHospiceDetails from "../hospice-home-shift-details/HomeHospiceDetailsComponent";
import AntTableComponent from "../../../../shared/components/antd-table-component/AntTableComponent";


interface ApprovedShiftDetailsComponentProps {
    setOpenDrawer?: any;
    shiftDetails?: any;
    setFilterData?: any;
    filterData?: any;
    isOnCalender?: any;
    dayMonthView?: any;
}

const ApprovedShiftDetailsComponent = (props: ApprovedShiftDetailsComponentProps) => {
    const {setOpenDrawer, shiftDetails, filterData, setFilterData, isOnCalender, dayMonthView} = props;
    const shiftTimingsColumn = useUserListColumn();
    const [hcpDetails, setHcpDetails] = useState<any>(null);
    const [isHcpDetailsLoaded, setIsHcpDetailsLoaded] = useState<boolean>(false);
    const [isHcpDetailsLoading, setIsHcpDetailsLoading] = useState<boolean>(false);
    const [hcpDocumentsList, setHcpDocumentsList] = useState<any>(null);
    const [isTimesheetUploaded, setIsTimesheetUploaded] = useState<boolean>(false);
    const [timesheetFile, setTimesheetFile] = React.useState<any>(null);
    const [innerShiftDetails, setInnerShiftDetails] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<any>("shift-requirement");
    const [hcpFacilityShiftFilterData, setHcpFacilityShiftFilterData] = useState({});
    const [isShiftDetailsLoaded, setIsInnerShiftDetailsLoaded] = useState<any>(false);
    const [isInnerShiftDetailsLoading, setIsInnerShiftDetailsLoading] = useState<boolean>(false);

    const previewPDF = useCallback(() => {
        window.open(innerShiftDetails?.timesheet_details?.url, '_blank');
    }, [innerShiftDetails?.timesheet_details?.url]);

    const getHcpDetails = useCallback(() => {
        setIsHcpDetailsLoading(true);
        const payload = {};
        if (dayMonthView) {
            CommonService._hcp.HcpDetailsAPICall(shiftDetails?.hcp_id, payload)
                .then((response: IAPIResponseType<any>) => {
                    setHcpDetails(response.data);
                    setIsHcpDetailsLoaded(true);
                }).catch((error: any) => {
                setIsHcpDetailsLoaded(false);
            }).finally(() => {
                setIsHcpDetailsLoading(false);
            });
        } else {
            CommonService._hcp.HcpDetailsAPICall(shiftDetails?.hcp_details?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    setHcpDetails(response.data);
                    setIsHcpDetailsLoaded(true);
                }).catch((error: any) => {
                setIsHcpDetailsLoaded(false);
            }).finally(() => {
                setIsHcpDetailsLoading(false);
            });
        }
    }, [dayMonthView, shiftDetails?.hcp_details?._id, shiftDetails?.hcp_id]);

    const getHcpDocuments = useCallback(() => {
        const payload = {};
        if (dayMonthView) {
            CommonService._hcp.HcpDocumentAPICall(shiftDetails?.hcp_id, payload)
                .then((response: IAPIResponseType<any>) => {
                    setHcpDocumentsList(response?.data);
                }).catch((error: any) => {
            }).finally(() => {
            });
        } else {
            CommonService._hcp.HcpDocumentAPICall(shiftDetails?.hcp_details?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    setHcpDocumentsList(response?.data);
                }).catch((error: any) => {
            }).finally(() => {
            });
        }
    }, [dayMonthView, shiftDetails?.hcp_details?._id, shiftDetails?.hcp_id]);

    const getShiftDetails = useCallback(() => {
        setIsInnerShiftDetailsLoaded(false);
        setIsInnerShiftDetailsLoading(true);
        const payload = {};
        if (shiftDetails?._id) {
            CommonService._shift_management.getAssignedShiftDetailsAPICall(shiftDetails?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    setInnerShiftDetails(response.data);
                    setIsInnerShiftDetailsLoaded(true);
                    setIsInnerShiftDetailsLoading(false)
                }).catch((error: any) => {
            }).finally(() => {
            });
        }
    }, [shiftDetails?._id]);

    useEffect((): void => {
        getHcpDetails();
    }, [getHcpDetails]);

    useEffect(() => {
        getHcpDocuments();
    }, [getHcpDocuments]);

    useEffect(() => {
        getShiftDetails();
    }, [getShiftDetails]);

    const onSubmit = useCallback(() => {
        if (timesheetFile) {
            setOpenDrawer(false);
            setIsTimesheetUploaded(true);
            let payload= CommonService.getFormDataFromJSON({
                'contract': timesheetFile})
            // const formData = new FormData();
            // formData.append('contract', timesheetFile);
            let apiCall: any = {};
            apiCall = CommonService._shift_management.uploadTimesheetAPICall(shiftDetails?._id, payload);
            apiCall.then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setIsTimesheetUploaded(false);
            })
                .catch((error: any) => {
                    CommonService._alert.showToast(error?.error,'error');
                }).finally(() => {
                if (isOnCalender) {
                    setFilterData({...filterData});
                } else {
                    setFilterData({...filterData, status: "approved"});
                }
                setIsTimesheetUploaded(false);
            });
        } else {
            return;
        }
    }, [filterData, isOnCalender, setFilterData, setOpenDrawer, shiftDetails?._id, timesheetFile]);

    useEffect(() => {
        onSubmit();
    }, [onSubmit]);

    useEffect(() => {
        if (isHcpDetailsLoaded) {
            setHcpFacilityShiftFilterData({
                hcp_id: hcpDetails?._id,
                facility_id: innerShiftDetails?.facility_id
            });
        }
    }, [hcpDetails, innerShiftDetails, isHcpDetailsLoaded]);

    const onMarkingShiftCompleted = useCallback(() => {
        const payload: any = {};
        let apiCall: any = {};
        payload.shift_ids = [innerShiftDetails._id];
        apiCall = CommonService._shift_management.markApprovedShiftToCompletedAPICall(payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            setFilterData({...filterData});
            setOpenDrawer(false);
        })
            .catch((error: any) => {
                CommonService._alert.showToast(error?.error,'error');
            }).finally(() => {

        });
    }, [filterData, innerShiftDetails?._id, setFilterData, setOpenDrawer]);

    const handleConfirmingShiftMarkingAsCompleted = useCallback(() => {
        CommonService.onConfirm(
            {
                confirmationTitle: `Mark As Completed`,
                image: ImageConfig.MarkAsCompleted,
                confirmationDescription: "Are you sure you want to mark this shift as completed ?",
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: "Yes, Mark as Completed",
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            onMarkingShiftCompleted();
        });

    }, [onMarkingShiftCompleted]);

    return (
        <>
            <div className={"approved-shift-view-details"}>
                <div className={"approved-header d-flex justify-content-space-between pdd-20"}
                //      style={{
                //     backgroundImage: `url(${ImageConfig.ApprovedGradient}`,
                //     backgroundRepeat: "no-repeat"
                // }}
                >
                    <div className={'d-flex ts-align-items-center'}>
                        <div className={'form-header-text'}>Shift Details
                        </div>
                        <div className={'mrg-left-15 pending-wrapper'}>Approved</div>
                    </div>
                    <div className={'icon-button'} onClick={() => {
                        setOpenDrawer(false);
                    }}><ImageConfig.CrossIcon/></div>
                </div>
                <HorizontalLineComponent/>
                {(isHcpDetailsLoading || isInnerShiftDetailsLoading) && <>
                    <div className="h-v-center">
                        <LoaderComponent type={'spinner'}/>
                    </div>
                </>}
                {(isHcpDetailsLoaded && isShiftDetailsLoaded) && <>
                    <div className={"non-header-container"}>
                        <div className={"mrg-bottom-15"}>
                            {(!timesheetFile && !innerShiftDetails?.is_timesheet_uploaded) &&
                                <div className="shift-details__timesheet">
                                    <FilePickerComponent maxFileCount={1}
                                                         height={'470px'}
                                                         id={"sc_upload_btn"}
                                                         btnText={"Upload Timesheet"}
                                                         disabled={isTimesheetUploaded}
                                                         showDropZone={false}
                                                         onFilesDrop={(acceptedFiles: any, rejectedFiles: any) => {
                                                             if (acceptedFiles && acceptedFiles.length > 0) {
                                                                 const file = acceptedFiles[0];
                                                                 setTimesheetFile(file);
                                                             }
                                                         }}
                                                         acceptedFileTypes={["pdf"]}
                                                         uploadDescription={"(upload only one file)"}
                                                         acceptedFilesText={"Only .pdf files are supported"}
                                                         largeButton={true}
                                    />
                                </div>}

                            {timesheetFile && <>
                                <TimeSheetUploadPreviewFile
                                    timesheetFile={timesheetFile}
                                    previewPDF={previewPDF}
                                />
                            </>}


                            {
                                innerShiftDetails?.timesheet_details &&
                                <TimeSheetUploadPreviewFile
                                    timesheetFile={innerShiftDetails?.timesheet_details}
                                    previewPDF={previewPDF}
                                    getShiftDetails={getShiftDetails}
                                    setFilterData={setFilterData}
                                    filterData={filterData}
                                    shiftType={'approved'}
                                    isonCalender={isOnCalender}
                                />
                            }
                        </div>
                        <div className={'hcp-details-wrapper'}>
                            <>
                                <div className={'shift-requirement-day-week-details-wrapper'}>
                                    <div className={'d-flex d-flex-space-between ts-align-items-center'}>
                                        <div className={'d-flex align-items-center'}>
                                            <div className={'hcp-details-header-text-drawer'}>Staff Details</div>
                                            {hcpDetails?.is_favourite &&
                                                <Lottie width={50} height={50} speed={1}
                                                        options={{animationData: FavoriteJson}}/>}
                                        </div>
                                        <div className={'d-flex'}>
                                            <ButtonComponent onClick={() => {
                                                handleConfirmingShiftMarkingAsCompleted();
                                            }} color={"mark-as-completed"}>
                                                Mark as completed
                                            </ButtonComponent>
                                        </div>
                                    </div>
                                    <div>
                                        <CommonHcpDetailsCard hcpDetails={hcpDetails}
                                                              innerShiftDetails={innerShiftDetails}
                                                              getHcpDetails={getHcpDetails}
                                                              shiftDetails={shiftDetails}
                                        />
                                    </div>
                                    <div className={'drawer-tab-wrapper mrg-top-20'}>
                                        <TabsWrapperComponent>
                                            <TabsComponent
                                                value={activeTab}
                                                allowScrollButtonsMobile={false}
                                                onUpdate={(e: any, v: any) => {
                                                    setActiveTab(v);
                                                }}
                                            >
                                                <TabComponent label="Shift Requirement details"
                                                              value={"shift-requirement"}/>
                                                <TabComponent label="Documents" value={"document"}/>
                                                <TabComponent label="Shifts in this facility" value={"facility-shift"}/>
                                            </TabsComponent>
                                            <TabContentComponent value={activeTab} selectedTab={"shift-requirement"}>
                                                <CardComponent size={"sm"}
                                                               className={"mrg-top-10 shift-details-card-hcp"}>
                                                    <div className={"pdd-left-15 pdd-right-25 pdd-top-25"}>
                                                        {isShiftDetailsLoaded &&
                                                            <>
                                                                {innerShiftDetails?.requirementType === "Open Shift" &&
                                                                    <div
                                                                        className={"pdd-left-15 pdd-right-25 pdd-top-25"}>
                                                                        <div
                                                                            className={'shift-requirement-week-day-header mrg-bottom-15 form-header-text '}>Shift
                                                                            Requirement Details
                                                                        </div>

                                                                        <div
                                                                            className={'shift-requirement-week-day-details-card'}>
                                                                            <div className={'pdd-10'}>
                                                                                <div className={'d-flex mrg-bottom-20'}>
                                                                                    <div
                                                                                        className={'d-flex flex-2 ts-align-items-center'}>
                                                                                        <div
                                                                                            className={"mrg-right-15 d-flex ts-align-items-center"}>
                                                                                            <ImageConfig.FacilityIcon/>
                                                                                        </div>
                                                                                        <Tooltip
                                                                                            title={innerShiftDetails?.facilityDetails?.name || 'N/A'}
                                                                                            placement={"top"}>
                                                                                            <div
                                                                                                className={'ellipses-for-calender-card-data'}> {innerShiftDetails?.facilityDetails?.name || 'N/A'}
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    <div
                                                                                        className={'d-flex flex-2 ts-align-items-center'}>
                                                                                        <ImageConfig.TimeIcon/>
                                                                                        <div
                                                                                            className={'shift-details-field-text mrg-left-15'}>
                                                                                            {(CommonService.convertMinutesToTime(innerShiftDetails?.expected
                                                                                                ?.shift_start_time) + " - " + CommonService.convertMinutesToTime(innerShiftDetails?.expected
                                                                                                ?.shift_end_time)) || "N/A"}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={'d-flex mrg-bottom-20'}>
                                                                                    <div
                                                                                        className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                        <ImageConfig.DateIcon/>
                                                                                        <div
                                                                                            className={'shift-details-field-text mrg-left-15'}>
                                                                                            {/*{CommonService.convertDateFormat(innerShiftDetails?.shift_date) || "-"}*/}
                                                                                            {CommonService.convertDateFormat2(innerShiftDetails?.shift_date, 'MM-DD-YYYY') || 'N/A'}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                        <ImageConfig.HCPTypeIcon/>
                                                                                        <div
                                                                                            className={'shift-details-field-text mrg-left-15'}>
                                                                                            {innerShiftDetails?.hcp_type_details?.title || "N/A"}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={'d-flex mrg-bottom-20'}>
                                                                                    <div
                                                                                        className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                        <ImageConfig.HCPCountIcon/>
                                                                                        <div
                                                                                            className={'shift-details-field-text'}>
                                                                                            {innerShiftDetails?.required_hcp_count?
                                                                                                `${innerShiftDetails?.required_hcp_count} Staff Required` : 'N/A'}
                                                                                        </div>
                                                                                    </div>
                                                                                    {/*<div*/}
                                                                                    {/*    className={'d-flex  flex-2 ts-align-items-center'}>*/}
                                                                                    {/*    <ImageConfig.RateIcon/>*/}
                                                                                    {/*    <div*/}
                                                                                    {/*        className={'shift-details-field-text mrg-left-15'}>*/}
                                                                                    {/*        {innerShiftDetails?.shift_rate ? `$ ${innerShiftDetails?.shift_rate}` :'N/A'}*/}
                                                                                    {/*    </div>*/}
                                                                                    {/*</div>*/}
                                                                                </div>
                                                                                {innerShiftDetails?.facility_wing_details?.name &&
                                                                                    <div className={'d-flex'}>
                                                                                        <div
                                                                                            className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                            <ImageConfig.WingIcon/>
                                                                                            <div
                                                                                                className={'shift-details-field-text mrg-left-15'}>
                                                                                                {innerShiftDetails?.facility_wing_details?.name || 'N/A'}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                {
                                                                    innerShiftDetails?.requirementType === "Home Health" &&
                                                                    <HomeHospiceDetails
                                                                        hospiceHomeShiftDetails={innerShiftDetails}/>
                                                                }
                                                                {
                                                                    innerShiftDetails?.requirementType === "Contract Travel" &&
                                                                    <ContractTravelDetails
                                                                        contractTravelShiftDetails={innerShiftDetails}/>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </CardComponent>
                                            </TabContentComponent>
                                            <TabContentComponent value={activeTab} selectedTab={"document"}>
                                                {(hcpDocumentsList?.length > 0 && isHcpDetailsLoaded) &&
                                                    <CardComponent size={"sm"}
                                                                   className={"mrg-top-10 shift-details-card-hcp"}>
                                                        <div className={'document-box'}>
                                                            <div
                                                                className={'document-text mrg-left-20 mrg-top-20 mrg-bottom-5'}>Document
                                                            </div>
                                                            {
                                                                hcpDocumentsList?.map((document: any, index: any) => {

                                                                    let transformedName = "";
                                                                    //  CommonService.transformName(item?.attachment_type);
                                                                    if (document?.attachment_type === "tb_test") {
                                                                        transformedName = "TB Test";
                                                                        //  CPR BLS Card
                                                                    } else if (document?.attachment_type === "cpr_bls_card") {
                                                                        transformedName = "CPR BLS Card";
                                                                    } else {
                                                                        transformedName = CommonService.transformName(document?.attachment_type);
                                                                    }

                                                                    return (
                                                                        <>
                                                                            <div
                                                                                className={'hcp-document-list-wrapper cursor-pointer'}
                                                                                onClick={() => {
                                                                                    window.open(document?.url, '_blank');
                                                                                }}>
                                                                                <div className={'icon-button'}>
                                                                                    <ImageConfig.FileUploadHcp/>
                                                                                </div>
                                                                                <div
                                                                                    className={'underline-text mrg-left-10'}>
                                                                                    {transformedName}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </CardComponent>}
                                                {(hcpDocumentsList?.length === 0 && isHcpDetailsLoaded) &&
                                                    <CardComponent size={"sm"} className={"mrg-top-10"}>
                                                        <div className={'details-screen'}>
                                                            <div
                                                                className="details-screen-title-with-image-wrapper pdd-10">
                                                                <div
                                                                    className={'details-screen-title-wrapper center-right-element'}>
                                                                    <div className={'details-screen-title'}>Documents
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <HorizontalLineComponent/>
                                                            <div className={'no-content differential-hcp-rate'}>
                                                                No Document Uploaded yet..
                                                            </div>
                                                        </div>
                                                    </CardComponent>}
                                            </TabContentComponent>
                                            <TabContentComponent value={activeTab} selectedTab={"facility-shift"}>
                                                <div className={'mrg-top-20'}>
                                                    {/*<TableWrapperComponent url={APIConfig.FACILITY_HCP_SHIFTS.URL}*/}
                                                    {/*                       method={APIConfig.FACILITY_HCP_SHIFTS.METHOD}*/}
                                                    {/*                       isPaginated={true}*/}
                                                    {/*                       extraPayload={hcpFacilityShiftFilterData}*/}
                                                    {/*                       columns={shiftTimingsColumn}*/}
                                                    {/*/>*/}
                                                    <AntTableComponent
                                                        url={APIConfig.FACILITY_HCP_SHIFTS.URL}
                                                        method={APIConfig.FACILITY_HCP_SHIFTS.METHOD}
                                                        isRowSelection={false}
                                                        extraPayload={hcpFacilityShiftFilterData}
                                                        columns={shiftTimingsColumn}
                                                    />
                                                </div>
                                            </TabContentComponent>

                                        </TabsWrapperComponent>
                                    </div>
                                </div>
                            </>

                        </div>
                    </div>
                </>}
            </div>
        </>
    );
};

export default ApprovedShiftDetailsComponent;
