import "./SearchCheckboxComponent.scss";
import React, {useCallback, useState,useMemo,useRef} from 'react';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
    Menu,
    MenuItem,
    TextField,
    Checkbox,
    ListItemText,
} from "@mui/material";
import HorizontalLineComponent from "../horizontal-line/horizontal-line/HorizontalLineComponent";
import {ImageConfig} from "../../../constants";

interface SearchCheckboxComponentProps {
    options: any;
    placeholder: string;
    noDataText?: string;
    selectedOptions?: any;
    onChange?: (selected: any) => void;
    disabled?:boolean
}

const SearchCheckboxComponent = (props: SearchCheckboxComponentProps) => {
    const { options, placeholder, noDataText, selectedOptions = [], onChange,disabled } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [visibleOptions, setVisibleOptions] = useState([]);
    const menuRef = useRef(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if(disabled) return ;
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSearchQuery("");
    };

    const handleOptionChange = useCallback(
        (option: any) => {
            const isSelected = selectedOptions.some(
                (selected: any) => selected.code === option.code
            );
            const updatedOptions = isSelected
                ? selectedOptions.filter((selected: any) => selected.code !== option.code)
                : [...selectedOptions, option];
            onChange?.(updatedOptions);
        },
        [selectedOptions, onChange]
    );

    const displaySelectedOptions = useCallback(() => {
        if (selectedOptions.length === 0) return placeholder;
        const displayed = selectedOptions
            .slice(0, 2)
            .map((option: any) => option.title)
            .join(", ");
        return selectedOptions.length > 2 ? `${displayed}...` : displayed;
    }, [placeholder, selectedOptions]);

    const filteredOptions = useMemo(() => {
        return options?.filter((option: any) =>
            option?.title?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [options, searchQuery]);

    const handleScroll = (e:any) => {
        const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
        if (bottom && filteredOptions?.length > visibleOptions?.length) {
            setVisibleOptions(filteredOptions?.slice(0, visibleOptions.length + 30)); // Load next 30 items
        }
    };

    const handleClearAll=useCallback(()=>{
        onChange?.([])
        setSearchQuery("")
    },[onChange])

    useMemo(() => {
        setVisibleOptions(filteredOptions?.slice(0, 30));
    }, [filteredOptions]);

    return (
        <div className={`search-checkbox-component ${disabled}`}>
            <div style={{ display: "inline-block", position: "relative", width: "100%", height: "100%" }}>
                <div
                    onClick={handleClick}
                    style={{
                        width: "100%",
                        cursor: "pointer",
                        border: "1px solid #E7E7E7",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "8px",
                        padding: "6.5px 12px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div style={{flexGrow: 1, position: 'relative'}} className="selected-values">
                        <span className={`${selectedOptions.length === 0 ? "placeholder-custom" : ""}`}>
                            {displaySelectedOptions()}
                        </span>
                    </div>
                    <ArrowDropDownIcon/>
                </div>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{minWidth: "360px", width: '100%', marginTop: "10px"}}
                    ref={menuRef}
                    PaperProps={{
                        onScroll: handleScroll,
                    }}
                >
                    <div style={{ padding: "8px",display:'flex',alignItems:'center',gap:'10px' }} className={'custom-search-field'}>
                        <TextField
                            fullWidth
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            variant="outlined"
                            size="small"
                            autoFocus
                            sx={{ minWidth: '280px', width: '100%' }}
                        />
                        <div
                            onClick={handleClearAll}
                            style={{
                                cursor:'pointer',
                                border:"1px solid #E7E7E7",
                                padding:"10px",
                                borderRadius:'8px'
                            }}>
                            <ImageConfig.ToastCloseIcon/>
                        </div>
                    </div>
                    <HorizontalLineComponent />
                    <div style={{ maxHeight: '360px' }}>
                        {visibleOptions?.map((item: any) => (
                            <MenuItem key={item.code} value={item} onClick={() => handleOptionChange(item)}
                                      sx={{
                                          backgroundColor: selectedOptions.some(
                                              (selectedItem: any) => selectedItem.code === item.code
                                          ) ? "#F0F9FC" : ""
                                      }}
                            >
                                <Checkbox
                                    style={{ color: "#202937" }}
                                    checked={selectedOptions.some(
                                        (selectedItem: any) => selectedItem.code === item.code
                                    )}
                                />
                                <ListItemText primary={item.title} />
                            </MenuItem>
                        ))}
                        {filteredOptions?.length === 0 && (
                            <MenuItem disabled sx={{ marginTop: '10px' }}>
                                {noDataText || "No results found"}
                            </MenuItem>
                        )}
                    </div>
                </Menu>
            </div>
        </div>
    );
};

export default SearchCheckboxComponent;