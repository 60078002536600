import "./HcpManagementComponent.scss";
import SearchComponent from "../../../shared/components/search/SearchComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {CommonService} from "../../../shared/services";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {APIConfig, ImageConfig, Misc} from "../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import {setCurrentNavParams} from "../../../store/actions/navigation.action";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import FormControl from "@mui/material/FormControl";
// import {Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {IAPIResponseType} from "../../../shared/models/api.model";
// import {MenuProps} from "../../../data";
import {useHCPListColumn} from "./TableColumnns";
import SearchCheckboxComponent from "../../../shared/components/search-checkbox/SearchCheckboxComponent";
import AntTableComponent from "../../../shared/components/antd-table-component/AntTableComponent";

interface HcpManagementListComponentProps {

}

const HcpManagementListComponent = (props: HcpManagementListComponentProps) => {

    const dispatch = useDispatch();
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<any>({
        search: '',
        is_internal_hcp: true,
        is_float: true
    });
    const {HcpTypesList} = useSelector((state: IRootReducerState) => state.meta);
    const [selectedHcpTypeData, setSelectedHcpTypeData] = React.useState<Array<string>>([]);

    const onHCPActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'} Internal Staff`,
                image: item?.is_active ? ImageConfig.UserDeactivateIcon : ImageConfig.UserActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'deactivate' : 'activate'}  the Internal Staff?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: item?.is_active,
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._hcp.ActivateDeactivateHCP(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setFilterData({...filterData});
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, [filterData]);
    const HCPListColumn = useHCPListColumn(onHCPActivateDeactivate);


    const handleHcpTypeSelection = useCallback((selectedOptions:any) => {
            setSelectedHcpTypeData(selectedOptions);
    }, []);


    useEffect(() => {
        dispatch(setCurrentNavParams('HCP Management') as any);
    }, [dispatch]);

    useEffect(() => {
        const hcpTypeIdsArray = selectedHcpTypeData.map((item: any) => item?.code);
        if (selectedHcpTypeData?.length > 0) {
            setFilterData({
                ...filterData,
                hcp_types: hcpTypeIdsArray,
            });
        } else {
            delete filterData?.hcp_types;
            setFilterData({
                ...filterData
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedHcpTypeData]);

    const handleDownloadHCPs = useCallback(() => {
        setIsDownloading(true);
        const payload: any = {};
        const {status} = filterData;
        payload.status = [status];
        let apiCall: any = {};
        apiCall = CommonService._hcp.downloadInternalHCPDataCSVAPICall(filterData);

        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsDownloading(false);
            }

        })
            .catch((error: any) => {
                CommonService._alert.showToast(error?.error || error?.message || 'Something is wrong!', "error");
                setIsDownloading(false);
            }).finally(() => {

        });
    }, [filterData]);
    return (
        <>
            <div className={'facility-list-component header-screen mrg-top-25 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.InternalHcpModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title">Internal Staff</div>
                        </div>
                    </div>
                    <div className="list-options">
                        <div className={"mrg-top-20"}>
                            <SearchComponent
                                size={'medium'}
                                placeholder="Search Staff Name"
                                value={filterData.search}
                                onSearchChange={(value) => {
                                    setFilterData({...filterData, search: value, page: 1});
                                }}
                            />
                        </div>
                        <div>
                            <FormControl sx={{m: 1, width: 245}}>
                               <SearchCheckboxComponent
                                   options={HcpTypesList}
                                   placeholder={"Select Staff Type"}
                                   selectedOptions={selectedHcpTypeData}
                                   onChange={handleHcpTypeSelection}
                                   noDataText={"No Staff Type"}
                               />
                            </FormControl>
                        </div>
                        <div>
                            <ButtonComponent color={'download-button'} variant={"contained"} isLoading={isDownloading}
                                             onClick={handleDownloadHCPs}>
                                Download CSV
                            </ButtonComponent>
                        </div>
                        <LinkComponent route={CommonService._routeConfig.AddHcpRoute()}>
                            <ButtonComponent color={'primary'} prefixIcon={<ImageConfig.AddOutlinedIcon/>}>
                                Add New Staff
                            </ButtonComponent>
                        </LinkComponent>
                    </div>
                </div>
            </div>
            <div className={'facility-list-component mrg-top-10 mrg-left-25 mrg-right-30'}>
                <div className={'hcp-table-component'}>
                    {/*<TableWrapperComponent url={APIConfig.HCP_MANAGEMENT_LIST.URL}*/}
                    {/*                       method={APIConfig.HCP_MANAGEMENT_LIST.METHOD}*/}
                    {/*                       isPaginated={true}*/}
                    {/*                       extraPayload={filterData}*/}
                    {/*                       noDataText={filterData.search ? 'Oops! It seems like there are no Staff available for the name you have searched.' :*/}
                    {/*                           filterData.hcp_types ? 'Oops! It seems like there are no Staff available for the selected staff.' :*/}
                    {/*                           'No Staff Data Found.'}*/}
                    {/*                       columns={HCPListColumn}*/}
                    {/*/>*/}
                    <AntTableComponent
                        url={APIConfig.HCP_MANAGEMENT_LIST.URL}
                        method={APIConfig.HCP_MANAGEMENT_LIST.METHOD}
                        isRowSelection={false}
                        extraPayload={filterData}
                        noDataText={filterData.search ? 'Oops! It seems like there are no Staff available for the name you have searched.' :
                            filterData.hcp_types ? 'Oops! It seems like there are no Staff available for the selected staff.' :
                                'No Staff Data Found.'}
                        columns={HCPListColumn}
                    />

                </div>
            </div>
        </>
    );
};

export default HcpManagementListComponent;
